import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Table, CardHeader, Button, CardBody, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaEye, FaEyeSlash } from 'react-icons/fa'; // Import the eye icons
import { GoDotFill } from "react-icons/go";
import { TbLogout } from "react-icons/tb";
import CustomizerContext from '../../_helper/Customizer';
import SweetAlert from 'sweetalert2';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import { Skeleton } from 'antd';
import { BackendAPI } from '../../api';
import { Pagination, Select } from 'antd';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';
const { Option } = Select;


const AppUser = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [showPassword, setShowPassword] = useState({});
    const [itemId, setItemId] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [modal, setModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false); // State to control the logout modal
    const [statusModal, setStatusModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null); // State to store the ID of the user to be deleted
    const [logoutUsername, setLogoutUsername] = useState(null); // State to store the username of the user to be logged out
    const { layoutURL } = useContext(CustomizerContext);
    const navigate = useNavigate();
    const { permissions } = useContext(PermissionsContext);

    useEffect(() => {
        fetchUsers();
    }, [currentPage, pageSize, searchText, permissions]);

    // Extract AppUserLoginPermissions component
    const AppUserLoginPermissions = permissions['App User Login'];

    const fetchUsers = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/login/getappuserlogin?page=${currentPage}&pageSize=${pageSize}&search=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            setData(response.data.facilities);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalItems);
            setLoading(false);

            // Initialize showPassword state for each item
            const initialShowPasswordState = response.data.facilities.reduce((acc, item) => {
                acc[item.id] = false;
                return acc;
            }, {});
            setShowPassword(initialShowPasswordState);
        } catch (error) {
            console.error('Error fetching users:', error);
            setLoading(false);
        }
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1); // Reset to first page when changing page size
        // Refetch data with the new page size
        fetchUsers();
    };

    const handleSearch = (value) => {
        setSearchText(value);
        setCurrentPage(1);
    };

    const formatLastLogin = (lastLogin) => {
        if (!lastLogin) return ''; // Handle case when last login is not provided
        const date = new Date(lastLogin);
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Use 24-hour format
        };
        return date.toLocaleString(undefined, options); // Format the date and time
    };

    const toggleShowPassword = (id) => {
        setShowPassword(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleDelete = async (id) => {
        try {
            // Make an API request to delete the item with the given ID
            const token = getToken();
            await axios.delete(`${BackendAPI}/login/RemoveAppUser/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            // Remove the deleted item from the data array
            setData(prevData => prevData.filter(item => item.id !== id));
            // Update total items count
            setTotalItems(prevTotalItems => prevTotalItems - 1);
            SweetAlert.fire({
                title: 'Success!',
                text: 'User login deleted successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            });
        } catch (error) {
            console.error('Error deleting user:', error.message);
        }
    };

    const handleLogout = async (username) => {
        try {
            const token = getToken();
            // Make an API request to logout the user with the given username
            await axios.delete(`${BackendAPI}/login/LogoutUser/${username}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            // Update the data state immediately to reflect the change in the UI
            const updatedLogins = data.map((login) =>
                login.cs_username === username ? { ...login, logged_in_device_id: null } : login
            );
            setData(updatedLogins);

            SweetAlert.fire({
                title: 'Success!',
                text: 'User logged out successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            });
        } catch (error) {
            console.error('Error logging out user:', error.message);
        }
    };

    const handleStatusUpdate = async (cs_id, currentStatus) => {
        // Calculate the new status based on the current status
        const newStatus = currentStatus === 0 ? 1 : 0;
        try {
            const token = getToken();
            // Update the status via API call
            await axios.put(`${BackendAPI}/login/UpdateStatus`, { id: cs_id, status: newStatus }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            // Update the data state immediately to reflect the change in the UI
            const updatedLogins = data.map((login) =>
                login.id === cs_id ? { ...login, cs_status: newStatus } : login
            );
            setData(updatedLogins);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const editLogin = (cs_id, cs_username) => {
        console.log(cs_username);
        const URL = '/edit-app-login/';
        // Pass data as state through route props
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { cs_id, cs_username } });
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setModal(true);
    };

    const confirmDelete = () => {
        if (deleteId !== null) {
            handleDelete(deleteId);
            setDeleteId(null);
            setModal(false);
        }
    };

    const openLogoutModal = (username) => {
        setLogoutUsername(username);
        setLogoutModal(true);
    };

    const confirmLogout = () => {
        if (logoutUsername !== null) {
            handleLogout(logoutUsername);
            setLogoutUsername(null);
            setLogoutModal(false);
        }
    };

    const confirmStatusUpdate = () => {
        // Call the function to update status
        // Pass necessary parameters like item id and current status
        handleStatusUpdate(itemId, currentStatus);
        // Close the status confirmation modal
        setStatusModal(false);
    };


    return (
        <Fragment>
            <Breadcrumbs mainTitle="App User Login" parent="Onsite App" title="App User Login" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-center">App User Logins</h5>
                                    <Input
                                        placeholder="Search all columns"
                                        onChange={(e) => handleSearch(e.target.value)}
                                        style={{ width: 200 }}
                                    />
                                </div>
                                {AppUserLoginPermissions?.add === 1 && (
                                    <div className="mt-2 mt-md-0">
                                        <Link to="/create-app-login/Consoft" className="btn btn-warning">Create App Login</Link>
                                    </div>
                                )}
                            </CardHeader>
                            <CardBody>
                                <div className='table-responsive'>
                                    {loading ? (
                                        <Skeleton active />
                                    ) : (
                                        <Table>
                                            {/* Table Header */}
                                            <thead>
                                                <tr className='border-bottom-primary'>
                                                    <th scope='col'>{'Sr No.'}</th>
                                                    <th scope='col'>{'Username'}</th>
                                                    <th scope='col'>{'Password'}</th>
                                                    {/* <th scope='col'>{'Email'}</th> */}
                                                    <th scope='col'>{'Role'}</th>
                                                    <th scope='col'>{'Device Id'}</th>
                                                    <th scope='col' className="text-center">{'Last Login'}</th>
                                                    <th scope='col'>{'Login Expiry'}</th>
                                                    <th scope='col'>{'Spot No.'}</th>
                                                    <th scope='col'>{'Login Status'}</th>
                                                    <th scope='col'>{'Status'}</th>
                                                    {(AppUserLoginPermissions?.edit === 1 || AppUserLoginPermissions?.delete === 1 || AppUserLoginPermissions?.validate === 1) && (
                                                        <th scope='col' className="text-center">{'Action'}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            {/* Table Body */}
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index} className="border-bottom-primary">
                                                        <td className='text-start'>{(currentPage - 1) * pageSize + index + 1}</td>
                                                        <td>{item.cs_username}</td>
                                                        <td>
                                                            {showPassword[item.id] ? item.cs_password : '********'}
                                                            <Button color="link" onClick={() => toggleShowPassword(item.id)}>
                                                                {showPassword[item.id] ? <FaEye /> : <FaEyeSlash />}
                                                            </Button>
                                                        </td>
                                                        {/* <td>{item.cs_email}</td> */}
                                                        <td>{item.cs_role_name}</td>
                                                        <td>{item.logged_in_device_id}</td>
                                                        <td className="text-center">{item.cs_login_time}</td>
                                                        <td>{item.cs_valid_upto}</td>
                                                        <td>{item.cs_regno_start}</td>
                                                        <td className="text-center">
                                                            {item.logged_in_device_id !== null && item.logged_in_device_id !== '' ? (
                                                                <Label className="text-success">Log In</Label>
                                                            ) : (
                                                                <Label className="text-danger">Log Out</Label>
                                                            )}
                                                        </td>
                                                        <td className='text-center'>
                                                            <Tooltip id="tooltip" globalEventOff="click" />

                                                            {item.cs_status == 0 ? (
                                                                <span style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        // Set itemId and currentStatus to be used in confirmStatusUpdate
                                                                        setItemId(item.id);
                                                                        setCurrentStatus(item.cs_status);
                                                                        // Open the status confirmation modal
                                                                        setStatusModal(true);
                                                                    }}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span style={{ color: 'green', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        // Set itemId and currentStatus to be used in confirmStatusUpdate
                                                                        setItemId(item.id);
                                                                        setCurrentStatus(item.cs_status);
                                                                        // Open the status confirmation modal
                                                                        setStatusModal(true);
                                                                    }}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                        </td>
                                                        {(AppUserLoginPermissions?.edit === 1 || AppUserLoginPermissions?.delete === 1 || AppUserLoginPermissions?.validate === 1) && (
                                                            <td>
                                                                {AppUserLoginPermissions?.delete === 1 && (
                                                                    <>
                                                                        <Button
                                                                            color="primary"
                                                                            size="sm"
                                                                            onClick={() => editLogin(item.id, item.cs_username)}
                                                                            data-tooltip-id="tooltip"
                                                                            data-tooltip-content="Edit User Login"
                                                                            data-tooltip-event="click focus"
                                                                        >
                                                                            <FaEdit /> {/* Key icon inside the button */}
                                                                        </Button>
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                            onClick={() => openDeleteModal(item.id)}
                                                                            data-tooltip-id="tooltip"
                                                                            data-tooltip-content="Delete User Login"
                                                                            data-tooltip-event="click focus"
                                                                        >
                                                                            <FaTrash />
                                                                        </Button>
                                                                    </>
                                                                )}
                                                                {item.logged_in_device_id !== null && item.logged_in_device_id !== '' && (
                                                                    AppUserLoginPermissions?.validate === 1 && (
                                                                        <Button
                                                                            color="warning"
                                                                            size="sm"
                                                                            onClick={() => openLogoutModal(item.cs_username)}
                                                                            data-tooltip-id="tooltip"
                                                                            data-tooltip-content="Force Logout"
                                                                            data-tooltip-event="click focus"
                                                                        >
                                                                            <TbLogout />
                                                                        </Button>
                                                                    )
                                                                )}
                                                            </td>
                                                        )}

                                                    </tr>

                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                                {totalItems > 10 && (
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <Pagination
                                            onChange={handlePageChange}
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            showSizeChanger={true}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)} centered size="md">
                <ModalHeader toggle={() => setModal(!modal)}>Confirm</ModalHeader>
                <ModalBody>Are you sure you want to delete this user?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmDelete}>Yes</Button>
                    <Button color="warning" onClick={() => setModal(!modal)}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Logout Confirmation Modal */}
            <Modal isOpen={logoutModal} toggle={() => setLogoutModal(!logoutModal)} centered size="md">
                <ModalHeader toggle={() => setLogoutModal(!logoutModal)}>Confirm</ModalHeader>
                <ModalBody>Are you sure you want to log out this user?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmLogout}>Yes</Button>
                    <Button color="warning" onClick={() => setLogoutModal(!logoutModal)}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Status Confirmation Modal */}
            <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)} centered size="md">
                <ModalHeader toggle={() => setStatusModal(!statusModal)}>Confirmation</ModalHeader>
                <ModalBody>Are you sure you want to change status ?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={confirmStatusUpdate}>Yes</Button>
                    <Button color="warning" onClick={() => setStatusModal(!statusModal)}>No</Button>
                </ModalFooter>
            </Modal>

        </Fragment>
    );
};

export default AppUser;
