import React, { useState, useRef, useEffect } from 'react';
import BadgeElement from '../badgelement/BadgeElement';
import ComponentSettings from '../componentsetting/ComponentSettings';
import './BadgeDesigner.css';
import BadgePDFExporter from '../BadgePDFExporter/BadgePDFExporter';
// import AddFieldForm from '../badgedesign/AddFieldForm/AddFieldForm';
import AddFieldForm from '../AddFieldForm/AddFieldForm';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
// import BadgePDFDownloadButton from '../../component/badgeDownlode/BadgePDFDownloadButton'; 
import { useLocation } from 'react-router-dom';
import { BackendAPI } from '../../../api';
import { BackendPath } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SweetAlert from 'sweetalert2';
import { Breadcrumb } from 'reactstrap';
// import { getToken } from '../../Auth/Auth';
// import useAuth from '../../Auth/protectedAuth';
import useAuth from '../../../Auth/protectedAuth';
import { getToken } from '../../../Auth/Auth';

const BadgeDesigner = () => {

    useAuth();
    const location = useLocation();
    // Destructure properties from location.state with default values or empty strings
    const { badgeName = '', designation = '', category = '' } = location.state || {};

    // Destructure badgeDatafromApi and badge with default values or an empty object
    const { badgeDatafromApi = {}, badge = {} } = location.state || {};

    // Determine the values of categoryName, designationName, and badgeName based on the source
    const categoryName = badge.category_name || category;
    const designationName = badge.designation_name || designation;
    const editedBadgeName = badge.badge_name || null;
    const finalBadgeName = editedBadgeName || badgeName;

    // Initialize storedData state using the appropriate values
    const [storedData, setStoredData] = useState({
        id: badge.category_id || '',
        badgeName: typeof finalBadgeName === 'string' ? finalBadgeName : '',
        designation: typeof designationName === 'string' ? designationName : '',
        categoryName: typeof categoryName === 'string' ? categoryName : ''
    });


    const navigate = useNavigate(); // Initialize navigate hook
    const [badgeSize, setBadgeSize] = useState({ width: 755.905511811, height: 377.9527559055 });
    const CM_TO_PX = 37.795276;
    const [orientation, setOrientation] = useState('landscape');
    const [zoom, setZoom] = useState(100);
    const [showGrid, setShowGrid] = useState(false);
    const [components, setComponents] = useState([]);

    const [showComponentSettings, setShowComponentSettings] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [componentCounter, setComponentCounter] = useState(1);
    const [enteredText, setEnteredText] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    // const [qrCodeData, setQRCodeData] = useState('');
    const PX_TO_CM = 0.0264583333;

    // const [isBadgeDesignSaved, setIsBadgeDesignSaved] = useState(false);



    // Inside your BadgeDesigner component
    useEffect(() => {
        if (badgeDatafromApi && badgeDatafromApi.badge_fields) {
            const { width, height, orientation, badge_fields } = badgeDatafromApi;


            // Convert pixel values to centimeters
            const widthInCm = width * PX_TO_CM;
            const heightInCm = height * PX_TO_CM;
            console.log("Width from badgeDatafromApi:", widthInCm);
            console.log("Height from badgeDatafromApi:", heightInCm);

            // Map through badge_fields array and create components
            const initialComponents = badge_fields.map((field) => {
                // Extract position and size from field data
                const position = {
                    top: parseFloat(field.cs_field_position_y),
                    left: parseFloat(field.cs_field_position_x)
                };
                const size = {
                    width: parseFloat(field.cs_field_width),
                    height: parseFloat(field.cs_field_height)
                };

                // Create component object based on field type
                let component = {};
                if (field.cs_field_type_id === 'text') {
                    component = {
                        id: field.cs_field_id,
                        type: field.cs_field_type_id,
                        content: field.cs_field_label,
                        position: position,
                        size: size,
                        textFontSize: parseFloat(field.cs_text_size) || 12
                    };

                } else if (field.cs_field_type_id === 'image') {
                    component = {
                        id: field.cs_field_id,
                        type: field.cs_field_type_id,
                        content: field.cs_field_content, // Assuming the content is the image URL
                        position: position,
                        size: size
                    };
                }
                else if (field.cs_field_type_id === 'backgroundimage') {
                    component = {
                        id: field.cs_field_id,
                        type: field.cs_field_type_id,
                        content: field.cs_field_content, // Assuming the content is the image URL
                        position: position,
                        size: size
                    };
                } else if (field.cs_field_type_id === 'qr') {
                    component = {
                        id: field.cs_field_id,
                        type: field.cs_field_type_id,
                        content: 'qr', // Placeholder content for QR code components
                        position: position,
                        size: size
                    };
                }
                else if (field.cs_field_type_id === 'customtext') {
                    component = {
                        id: field.cs_field_id,
                        type: field.cs_field_type_id,
                        content: field.cs_field_label,
                        position: position,
                        size: size,
                        textFontSize: parseFloat(field.cs_text_size) || 12
                    };

                }
                else if (field.cs_field_type_id === 'fullname') {
                    component = {
                        id: field.cs_field_id,
                        type: field.cs_field_type_id,
                        content: field.cs_field_label,
                        position: position,
                        size: size,
                        textFontSize: parseFloat(field.cs_text_size) || 12
                    };

                }
                return component;
            });

            // Set initial components state
            setComponents(initialComponents);

            // Set badge orientation
            setOrientation(orientation);

            // Set badge size
            setBadgeSize({ width: parseFloat(width), height: parseFloat(height) });


            // Check if the retrieved width and height match any predefined page size
            // Check if the retrieved width and height approximately match any predefined page size
            const matchingPageSize = Object.entries(pageSizes).find(([size, dimensions]) => {
                const precision = 0.0001; // Adjust precision as needed
                return Math.abs(dimensions.width - widthInCm) < precision && Math.abs(dimensions.height - heightInCm) < precision;
            });

            console.log('Matching page size:', matchingPageSize);

            if (matchingPageSize) {
                setSelectedPageSize(matchingPageSize[0]); // Set selected size to matched predefined size
            } else {
                setSelectedPageSize('custom'); // Set to "Custom" if size doesn't match predefined sizes
            }
        }
    }, [badgeDatafromApi]);


    //-- pages sizes 
    // Standard page sizes in cm
    const [selectedPageSize, setSelectedPageSize] = useState('custom');
    const [customPageSize, setCustomPageSize] = useState({ width: '', height: '' });
    const pageSizes = {
        A4: { width: 21, height: 29.7 },
        A5: { width: 14.8, height: 21 },
        A6: { width: 10.5, height: 14.8 },
    };
    // Handler for changing page size
    // const handlePageSizeChange = (e) => {
    //     const newSize = e.target.value;
    //     setSelectedPageSize(newSize);
    //     if (newSize === 'custom') {
    //         setCustomPageSize({ width: '', height: '' });
    //     } else {
    //         const pageSizeInCm = pageSizes[newSize];
    //         setBadgeSize({
    //             width: pageSizeInCm.width * CM_TO_PX,
    //             height: pageSizeInCm.height * CM_TO_PX
    //         });
    //     }
    // };


    const handlePageSizeChange = (e) => {
        const newSize = e.target.value;
        setSelectedPageSize(newSize);
        if (newSize === 'custom') {
            setCustomPageSize({ width: '', height: '' });
        } else {
            const pageSizeInCm = pageSizes[newSize];
            setBadgeSize({
                width: pageSizeInCm.width * CM_TO_PX,
                height: pageSizeInCm.height * CM_TO_PX
            });
        }
    };






    useEffect(() => {
        // Check if category has an id
        if (category && category.id) {
            localStorage.setItem('storedData', JSON.stringify({ id: category.id, badgeName, designation, categoryName: category.name }));
            setStoredData({ id: category.id, badgeName, designation, categoryName: category.name });
            // console.log('Category ID:', category.id); // Log the category id
        }
    }, [badgeName, designation, category]);


    const handleWidthChange = (e) => {
        const cmValue = parseFloat(e.target.value).toFixed(3);
        const pxValue = cmValue * CM_TO_PX;
        setBadgeSize({ ...badgeSize, width: pxValue });
    };

    const handleHeightChange = (e) => {
        const cmValue = parseFloat(e.target.value).toFixed(3);
        const pxValue = cmValue * CM_TO_PX;
        setBadgeSize({ ...badgeSize, height: pxValue });
    };



    const handleTextContentChange = (newText) => {
        setEnteredText(newText); // Update the entered text state
        // Update the content property of the selected component
        const updatedComponents = components.map((component) => {
            if (component.id === selectedComponent) {
                return { ...component, content: newText };
            }
            return component;
        });
        setComponents(updatedComponents);
    };



    const saveBadgeDesign = async () => {
        const badgeDesign = {
            badgeSize,
            orientation,
            components,
            storedData
        };
        console.log('Save badgeDesign:', badgeDesign);
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/badge/saveBadgeDesign`, badgeDesign, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            //  console.log('badgeDesign:', badgeDesign);
            if (response.status === 200 || response.status === 201) {
                // toast.success('Badge design saved successfully!');

                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Badge Template Saved Successfully !',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false
                }).then((result) => {
                    if (result.dismiss === SweetAlert.DismissReason.timer) {
                        navigate('/create-badges/Consoft');
                    }
                });

                // navigate('/create-badges/Consoft');

                setShowExport(true);
            } else {
                console.error('Failed to save badge design. Unexpected response:', response);
                toast.error('Failed to save badge design. Please try again.');
            }
        } catch (error) {
            console.error('Error saving badge design:', error);
            alert('Failed to save badge design. Please try again.');
        }
    };


    //------
    const addFullNameComponent = () => {
        const defaultComponentSize = { width: 113.3858267717, height: 30.7952755906 }; // You can adjust the size as needed
        const newPosition = {
            top: badgeSize.height / 2 - defaultComponentSize.height / 2,
            left: badgeSize.width / 2 - defaultComponentSize.width / 2
        };
        const newComponent = {
            id: uuidv4(),
            type: 'fullname', // Set the type to 'fullname'
            content: 'fullname', // Initialize content as empty
            position: newPosition,
            size: { ...defaultComponentSize },
            textFontSize: 12 // Set default font size
        };
        setComponents([...components, newComponent]);
        setSelectedComponent(newComponent.id);
    };
    //------


    const addCustomTextComponent = () => {
        const defaultComponentSize = { width: 113.3858267717, height: 37.7952755906 };
        const newPosition = {
            top: badgeSize.height / 2 - defaultComponentSize.height / 2,
            left: badgeSize.width / 2 - defaultComponentSize.width / 2
        };
        const newComponent = {
            id: uuidv4(),
            type: 'customtext', // Set type to customtext
            content: 'New Custom Text', // Default content for custom text component
            position: newPosition,
            size: { ...defaultComponentSize },
            textFontSize: 12
        };
        setComponents([...components, newComponent]);
        setSelectedComponent(newComponent.id);
    };


    const addFieldAsComponent = (field) => {
        // console.log('Field data:', field);
        const newComponent = {
            id: uuidv4(), // Generate a unique ID
            type: 'text', // You can define a type for field components
            content: field.cs_field_label, // Use the field name as content
            formfield_id: field.cs_field_id,
            formfield_name: field.cs_field_label,
            position: { top: 100, left: 100 }, // Set default position
            size: { width: 100, height: 25 }, // Set default size
            textFontSize: 12 // Set default font size
        };
        // console.log('New component:', newComponent);
        setComponents([...components, newComponent]);
    };




    //----

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (!imageFile) {
            alert('Please select an image file.');
            return;
        }
        console.log('Selected file:', imageFile.name, imageFile.size, imageFile.type);
        addComponentImage(imageFile); // Pass the image file to the addComponentImage function
        event.target.value = '';
    };


    //----------------

    const addComponentImage = async (imageFile) => {
        const formData = new FormData();
        formData.append('image', imageFile);
        // for (let key of formData.keys()) {
        //     console.log(key, formData.get(key));
        // }

        try {
            const token = getToken();
            // Upload the image file to your server
            const response = await axios({
                method: 'post',
                url: `${BackendAPI}/badge/uplaodebadgeimg`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });





            // Check if the upload was successful
            if (response.status === 200) {
                let relativeUrl = response.data.imageUrl;
                console.log("relativeUrl", relativeUrl)
                const imageUrl = `${BackendPath}${relativeUrl}`;
                // console.log('Uploaded image URL:', imageUrl);
                const defaultComponentSize = { width: 100, height: 100 };
                const newPosition = {
                    top: badgeSize.height / 2 - defaultComponentSize.height / 2,
                    left: badgeSize.width / 2 - defaultComponentSize.width / 2
                };

                // const imageUrl = data.imageUrl; // Assuming the API returns the URL of the uploaded image
                // Create a new component with the image URL
                const newComponent = {
                    id: uuidv4(),
                    type: 'image',
                    content: imageUrl, // Store the URL of the uploaded image
                    position: newPosition,
                    size: { ...defaultComponentSize },
                    textFontSize: 12
                };

                // Add the new component to the list of components
                setComponents([...components, newComponent]);
            } else {
                // Handle error response from the server
                // console.error('Error uploading image:', data.error);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };






    const handleBackgroundImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (!imageFile) {
            alert('Please select a background image file.');
            return;
        }
        console.log('Selected background image file:', imageFile.name, imageFile.size, imageFile.type);
        addBackgroundImageComponent(imageFile); // Pass the image file to the addBackgroundImageComponent function
        event.target.value = '';
    };

    const addBackgroundImageComponent = async (imageFile) => {
        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            // Upload the image file to your server
            const token = getToken();
            const response = await axios({
                method: 'post',
                url: `${BackendAPI}/badge/uplaodebadgeimg`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            // Check if the upload was successful
            if (response.status === 200) {
                let relativeUrl = response.data.imageUrl;

                console.log("relativeUrl", relativeUrl)


                const imageUrl = `${BackendPath}${relativeUrl}`;
                

                const defaultComponentSize = { width: badgeSize.width, height: badgeSize.height };

                // Create a new component for background image
                const newComponent = {
                    id: uuidv4(),
                    type: 'backgroundimage',
                    content: imageUrl,
                    position: { top: 0, left: 0 },
                    size: { ...defaultComponentSize },
                    textFontSize: 12
                };

                // Add the new component to the list of components
                setComponents([...components, newComponent]);
            } else {
                // Handle error response from the server
                // console.error('Error uploading image:', data.error);
            }
        } catch (error) {
            console.error('Error uploading background image:', error);
        }
    };







    const addComponentQRCode = () => {
        const newComponent = {
            id: componentCounter,
            type: 'qr',
            content: 'qr', // Set content to an empty string
            position: { top: badgeSize.height / 2, left: badgeSize.width / 2 },
            size: { width: 100, height: 100 },
            textFontSize: 12
        };
        setComponents([...components, newComponent]);
        setComponentCounter(componentCounter + 1);
    };


    const handleComponentClick = (id) => {
        setSelectedComponent(id);
    };

    const handleComponentSizeChange = (id, newSize) => {
        const updatedComponents = components.map((component) => {
            if (component.id === id) {
                return { ...component, size: newSize };
            }
            return component;
        });
        setComponents(updatedComponents);
    };


    const handleComponentPositionChange = (id, newPosition) => {
        const updatedComponents = components.map((component) => {
            if (component.id === id) {
                return { ...component, position: newPosition };
            }
            return component;
        });
        setComponents(updatedComponents);
    };

    const handleOrientationChange = (e) => {
        const newOrientation = e.target.value;
        setOrientation(newOrientation);

        // Adjust badge size based on orientation
        if (newOrientation === 'portrait') {
            setBadgeSize({ width: 377.9527559055, height: 755.905511811 });
        } else {
            setBadgeSize({ width: 755.905511811, height: 377.9527559055 });
        }
    };






    const handleZoomChange = (e) => {
        setZoom(parseInt(e.target.value));
    };

    const handleGridToggle = () => {
        setShowGrid(!showGrid);
    };


    const handleComponentContentChange = (id, newContent) => {
        const updatedComponents = components.map((component) => {
            if (component.id === id) {
                return { ...component, content: newContent };
            }
            return component;
        });
        setComponents(updatedComponents);
    };

    const onDelete = (id) => {
        // Filter out the component with the given ID
        const updatedComponents = components.filter((component) => component.id !== id);
        setComponents(updatedComponents);
    };


    const handleTextFontSizeChange = (id, newTextFontSize) => {
        const updatedComponents = components.map((component) => {
            if (component.id === id) {
                return { ...component, textFontSize: newTextFontSize };
            }
            return component;
        });
        setComponents(updatedComponents);
    };

    ///--
    const [showAddFieldForm, setShowAddFieldForm] = useState(false);

    const toggleAddFieldForm = () => {
        setShowAddFieldForm(!showAddFieldForm);
    };










    return (

        <div>

            <div>
                {/* Display storedData values as headings */}
                <h4>Badge Name: {storedData.badgeName}</h4>
                <h5>Category: {storedData.categoryName}</h5>
                {/* Rest of your component code... */}
            </div>
            <AddFieldForm addFieldAsComponent={addFieldAsComponent} />

            <div className="add-button-container">



                <button className="add-button" onClick={addCustomTextComponent}>Add Custom Text</button>
                <button className="add-button" onClick={addFullNameComponent}>Add Full Name</button>

                <label htmlFor="image-upload" className="add-button">Select Image</label>
                <input
                    id="image-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />

                <label htmlFor="background-image-upload" className="add-button">Select Background Image</label>
                <input
                    id="background-image-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleBackgroundImageChange}
                />

                <button className="add-button" onClick={addComponentQRCode}>Add QR Code</button>
                {/* <button className="save-button" onClick={saveBadgeDesign}>Save</button> */}
                <button className="save-button" onClick={saveBadgeDesign}>Save</button>
            </div>


            <div className="badge-designer-container">

                <div className="badge-settings">
                    <h3>Badge Settings</h3>
                    <div>
                        <label htmlFor="orientation">orientation:</label>
                        <select
                            id="orientation"
                            value={orientation}
                            onChange={handleOrientationChange}
                        >
                            <option value="landscape">Landscape</option>
                            <option value="portrait">Portrait</option>
                        </select>
                    </div>
                    <div>

                        <label htmlFor="pageSize">Select Page Size:</label>
                        <select id="pageSize" value={selectedPageSize} onChange={handlePageSizeChange}>
                            <option value="custom">Custom Size</option>
                            {Object.entries(pageSizes).map(([key, size]) => (
                                <option key={key} value={key}>{`${key} (${size.width} x ${size.height} cm)`}</option>
                            ))}

                        </select>
                    </div>
                    <div>
                        <label htmlFor="badgeWidth">Width (cm):</label>
                        <input
                            id="badgeWidth"
                            type="number"
                            value={(badgeSize.width / CM_TO_PX).toFixed(1)}
                            onChange={handleWidthChange} // Handle changes in centimeters
                        />
                    </div>
                    <div>
                        <label htmlFor="badgeHeight">Height (cm):</label>
                        <input
                            id="badgeHeight"
                            type="number"
                            value={(badgeSize.height / CM_TO_PX).toFixed(1)}
                            onChange={handleHeightChange} // Handle changes in centimeters
                        />
                    </div>
                </div>
                <div className="badge-container" style={{ width: badgeSize.width, height: badgeSize.height }}>
                    {/* <div className="badge-container" style={{ width: `${badgeSize.width}cm`, height: `${badgeSize.height}cm` }}> */}
                    {components.map((component) => (
                        <BadgeElement
                            key={component.id}
                            id={component.id}
                            content={component.content}
                            position={component.position}
                            size={component.size}
                            isSelected={component.id === selectedComponent}
                            onSelect={handleComponentClick}
                            onSizeChange={handleComponentSizeChange}
                            onPositionChange={handleComponentPositionChange}
                            onContentChange={handleComponentContentChange}
                            onDelete={onDelete}
                            badgeSize={badgeSize}
                            textFontSize={component.textFontSize}
                            type={component.type}
                        />
                    ))}
                </div>


                <div className="component-settings-container">
                    {selectedComponent ? (
                        // Check if the selected component exists
                        (() => {
                            const selectedComponentData = components.find(c => c.id === selectedComponent);
                            if (selectedComponentData) {
                                return (
                                    <ComponentSettings
                                        component={selectedComponentData}
                                        handleComponentSizeChange={handleComponentSizeChange}
                                        handleComponentPositionChange={handleComponentPositionChange}
                                        handleTextFontSizeChange={handleTextFontSizeChange}
                                        handleTextContentChange={handleTextContentChange}
                                    />
                                );
                            }
                            return null;
                        })()
                    ) : (
                        <div className="non-interactive-placeholder">
                            <h3>No Component Selected</h3>
                            <p>Select or add new a component to see its settings</p>
                        </div>
                    )}
                </div>


            </div>
            {/* {isBadgeDesignSaved && <BadgePDFDownloadButton badgeSize={badgeSize} components={components} />} */}





        </div>
    );
};


export default BadgeDesigner;