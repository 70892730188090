import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Input, Table, CardHeader, CardBody, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'; // Import Modal component
import axios from 'axios';
import { BackendAPI } from '../../api';
import { Link, useNavigate } from 'react-router-dom'; // Import useHistory for programmatic navigation
import { FaEdit, FaSave } from "react-icons/fa";
import { Breadcrumbs } from '../../AbstractElements';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import { toast } from 'react-toastify';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';

    const UserFacilityReset = () => {
        useAuth();
        const [data, setData] = useState([]);
        const [loading, setLoading] = useState(true);
        const [editableRows, setEditableRows] = useState([]); // State to manage editable rows
        const [currentlyEditedIndex, setCurrentlyEditedIndex] = useState(null); // State to track currently edited row
        const location = useLocation();
        const { catId, catName } = location.state;
        const { user} = location.state;
        const [modal, setModal] = useState(false);
        const [selectedDays, setSelectedDays] = useState(0); // State to store selected days in modal
        const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to store the index of the row being edited
        const { permissions } = useContext(PermissionsContext);

        useEffect(() => {
            fetchCategory();
            console.log("user",user);
        }, [permissions]);

        // Extract Category Access component
        const CategoryAccesspermissions = permissions['Category Access'];

        const fetchCategory = async () => {
            try {
                console.log("api call resetfacilityUser",user);
                const token = getToken(); 
                const response = await axios.post(`${BackendAPI}/category/resetfacilityUser`, { cs_regno: user.cs_regno }, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
                setData(response.data);
                console.log(response.data);
                setEditableRows(new Array(response.data.length).fill(false)); // Initialize editableRows array
                setLoading(false);
            } catch (error) {
                console.error('Error fetching pages:', error);
                setLoading(false);
            }
        };

        const toggleRowEditable = (index) => {
            setCurrentlyEditedIndex(index); // Set the index of the row being edited
            setSelectedRowIndex(index); // Set the index of the row for which modal is opened
            setSelectedDays(data[index].allow_count); // Set the initial value of selected days
            setModal(true);
        };

     

        // const handleModalSave = async () => {
        //     const newItem = { ...data[selectedRowIndex], allow_count: selectedDays };
        //     const newData = [...data];
        //     newData[selectedRowIndex] = newItem;
        //     setData(newData);
        
        //     try {
        //         const facilityName = data[selectedRowIndex].name;
        //         // Update the facility count using the API endpoint
        //         await axios.put(`${BackendAPI}/category/updateFacilityCount`, { cs_regno: user.cs_regno, cs_allow_count: selectedDays, facilityName: facilityName});
        
        //         console.log('Data updated successfully');
        //         setModal(false);
        //         setEditableRows(prevState => {
        //             const newState = [...prevState];
        //             newState[selectedRowIndex] = false; // Disable editing after saving
        //             return newState;
        //         });
        //     } catch (error) {
        //         console.error('Error updating data:', error);
        //     }
        // };


        const handleModalSave = async () => {
            const newItem = { ...data[selectedRowIndex], allow_count: selectedDays };
            const newData = [...data];
            newData[selectedRowIndex] = newItem;
            setData(newData);
        
            try {
                const facilityName = data[selectedRowIndex].name;
                // Update the facility count using the API endpoint
                const token = getToken(); 
                const response = await axios.put(`${BackendAPI}/category/updateFacilityCount`, { 
                    cs_regno: user.cs_regno, 
                    cs_allow_count: selectedDays, 
                    facilityName: facilityName 
                }, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
        
                // Show a success toast message with the API response message
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setModal(false);
                setEditableRows(prevState => {
                    const newState = [...prevState];
                    newState[selectedRowIndex] = false; // Disable editing after saving
                    return newState;
                });
            } catch (error) {
                console.error('Error updating data:', error);
                // Show an error toast message with the API error message
                toast.error(error.response?.data?.message || 'Error updating facility count', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        };
        

        const getFormattedFacilityName = (name) => {
            if (name && name.startsWith && name.includes('_')) {
                const [prefix, suffix] = name.split('_');
                const formattedSuffix = suffix.charAt(0).toUpperCase() + suffix.slice(1).replace(/\d+/g, day => ` day ${day}`);
                return `${formattedSuffix}`;
            } else {
                return name || '';
            }
        };

        return (
            <Fragment>
                <Breadcrumbs mainTitle="Facility Reset " parent="Manage User" title="Facility Reset    " />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                    <div className="mb-2 mb-md-0">
                                        <h5 className="mb-2 text-center">{catName}</h5>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div className='table-responsive'>
                                        {loading ? (
                                            <p>Loading...</p>
                                        ) : (
                                            <Table>
                                                <thead>
                                                    <tr className='border-bottom-primary'>
                                                        <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                        <th scope='col' className='text-center'>{'Facility Name'}</th>
                                                        <th scope='col' className='text-center'>{'Allow Count'}</th>
                                                        <th scope='col' className='text-center'>{'Taken Status'}</th>
                                                        <th scope='col' className='text-center'>{'Datetime Utilization'}</th>
                                                        {CategoryAccesspermissions?.edit === 1 && (
                                                            <th scope='col' className='text-center'>{'Action'}</th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((page, index) => (
                                                        <tr key={index} className="border-bottom-primary">
                                                            <td className='text-start'>{index + 1}</td> {/* Incremental serial number */}
                                                            <td className='text-center'>{getFormattedFacilityName(page.name)}</td>
                                                            <td className='text-center'>{page.allow_count}</td>
                                                            <td className='text-center'>{page.status}</td>
                                                            <td className='text-center'>{`${page.cs_date}: ${page.cs_time}`}</td>
                                                            {CategoryAccesspermissions?.edit === 1 && (
                                                                <td className='text-center'>
                                                                    <Button color="warning" size="sm" onClick={() => toggleRowEditable(index)} disabled={editableRows[index]}>
                                                                        <FaEdit />
                                                                    </Button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {/* Modal */}
                <Modal isOpen={modal} toggle={() => setModal(!modal)} centered size="sm">
                    <ModalHeader toggle={() => setModal(!modal)}>Select Allowed Count</ModalHeader>
                    <ModalBody>
                        Set allow counts reflecting on user badge data so make sure counts are correct
                        <Input
                            type="text"
                            value={selectedDays}
                            className='text-center'
                            onChange={(e) => setSelectedDays(e.target.value)}
                            min={0}
                        />
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={() => handleModalSave()}>Save</Button>
                        <Button color="secondary" onClick={() => setModal(!modal)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        );
    };

export default UserFacilityReset;
