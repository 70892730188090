
// export default ComponentSettings;
import React, { useState, useEffect } from 'react';
import './ComponentSettings.css';

const ComponentSettings = ({
    component,
    handleComponentSizeChange,
    handleComponentPositionChange,
    handleTextFontSizeChange,
    handleTextContentChange,
}) => {
    const [enteredText, setEnteredText] = useState(component.content);
    const [widthCM, setWidthCM] = useState('');
    const [heightCM, setHeightCM] = useState('');
    const [topCM, setTopCM] = useState('');
    const [leftCM, setLeftCM] = useState('');
    const [textFontSize, setTextFontSize] = useState(component.textFontSize || '');

    const CM_TO_PX = 37.795276;

    useEffect(() => {
        if (component.size) {
            setWidthCM((component.size.width / CM_TO_PX).toString());
            setHeightCM((component.size.height / CM_TO_PX).toString());
        }
        if (component.position) {
            setTopCM((component.position.top / CM_TO_PX).toString());
            setLeftCM((component.position.left / CM_TO_PX).toString());
        }
        setTextFontSize(component.textFontSize || '');
    }, [component]);

    const handleTextChange = (e) => {
        const newText = e.target.value;
        setEnteredText(newText);
        handleTextContentChange(newText);
    };

    const handleWidthChange = (e) => {
        const newWidthCM = e.target.value;
        setWidthCM(newWidthCM);
        const newWidthPX = parseFloat(newWidthCM) * CM_TO_PX;
        if (!isNaN(newWidthPX)) {
            handleComponentSizeChange(component.id, { ...component.size, width: newWidthPX });
        }
    };

    const handleHeightChange = (e) => {
        const newHeightCM = e.target.value;
        setHeightCM(newHeightCM);
        const newHeightPX = parseFloat(newHeightCM) * CM_TO_PX;
        if (!isNaN(newHeightPX)) {
            handleComponentSizeChange(component.id, { ...component.size, height: newHeightPX });
        }
    };

    const handleTopChange = (e) => {
        const newTopCM = e.target.value;
        setTopCM(newTopCM);
        const newTopPX = parseFloat(newTopCM) * CM_TO_PX;
        if (!isNaN(newTopPX)) {
            handleComponentPositionChange(component.id, { ...component.position, top: newTopPX });
        }
    };

    const handleLeftChange = (e) => {
        const newLeftCM = e.target.value;
        setLeftCM(newLeftCM);
        const newLeftPX = parseFloat(newLeftCM) * CM_TO_PX;
        if (!isNaN(newLeftPX)) {
            handleComponentPositionChange(component.id, { ...component.position, left: newLeftPX });
        }
    };

    const handleTextFontSizeInputChange = (e) => {
        const newTextFontSize = e.target.value;
        setTextFontSize(newTextFontSize);
        const newTextFontSizeInt = parseInt(newTextFontSize);
        if (!isNaN(newTextFontSizeInt)) {
            handleTextFontSizeChange(component.id, newTextFontSizeInt);
        }
    };

    return (
        <div className="component-settings">
            <h5>Selected Component Settings</h5>
            <div className="setting-row">
                <label htmlFor="componentWidth">Width (cm)</label>
                <input
                    id="componentWidth"
                    type="number"
                    step="0.01"
                    value={widthCM}
                    onChange={handleWidthChange}
                />
            </div>
            <div className="setting-row">
                <label htmlFor="componentHeight">Height (cm):</label>
                <input
                    id="componentHeight"
                    type="number"
                    step="0.01"
                    value={heightCM}
                    onChange={handleHeightChange}
                />
            </div>
            <div className="setting-row">
                <label htmlFor="componentTop">Top (cm):</label>
                <input
                    id="componentTop"
                    type="number"
                    step="0.01"
                    value={topCM}
                    onChange={handleTopChange}
                />
            </div>
            <div className="setting-row">
                <label htmlFor="componentLeft">Left (cm):</label>
                <input
                    id="componentLeft"
                    type="number"
                    step="0.01"
                    value={leftCM}
                    onChange={handleLeftChange}
                />
            </div>
            <div className="setting-row">
                <label htmlFor="textFontSize">Text Font Size:</label>
                <input
                    id="textFontSize"
                    type="number"
                    value={textFontSize}
                    onChange={handleTextFontSizeInputChange}
                />
            </div>
            {component.type === 'customtext' && (
                <div className="setting-row">
                    <label htmlFor="textContent">Enter Text:</label>
                    <input
                        id="textContent"
                        type="text"
                        value={enteredText}
                        onChange={handleTextChange}
                    />
                </div>
            )}
        </div>
    );
};

export default ComponentSettings;

