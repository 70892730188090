import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Input, Table, CardHeader, CardBody, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'; // Import Modal component
import axios from 'axios';
import { BackendAPI } from '../../api';
import { Link, useNavigate } from 'react-router-dom'; // Import useHistory for programmatic navigation
import { FaEdit, FaSave } from "react-icons/fa";
import { Breadcrumbs } from '../../AbstractElements';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';
const CategoryAccess = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editableRows, setEditableRows] = useState([]); // State to manage editable rows
    const [currentlyEditedIndex, setCurrentlyEditedIndex] = useState(null); // State to track currently edited row
    const location = useLocation();
    const { catId, catName } = location.state;
    const [modal, setModal] = useState(false);
    const [selectedDays, setSelectedDays] = useState(0); // State to store selected days in modal
    const [selectedRowIndex, setSelectedRowIndex] = useState(null); // State to store the index of the row being edited
    const { permissions } = useContext(PermissionsContext);

    useEffect(() => {
        fetchCategory();
    }, [permissions]);

    // Extract Category Access component
    const CategoryAccesspermissions = permissions['Category Access'];

    const fetchCategory = async () => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/category/getCategoryAccess`, { catId }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            setData(response.data);
            console.log(response.data);
            setEditableRows(new Array(response.data.length).fill(false)); // Initialize editableRows array
            setLoading(false);
        } catch (error) {
            console.error('Error fetching pages:', error);
            setLoading(false);
        }
    };

    const toggleRowEditable = (index) => {
        setCurrentlyEditedIndex(index); // Set the index of the row being edited
        setSelectedRowIndex(index); // Set the index of the row for which modal is opened
        setSelectedDays(data[index].cs_allow_count); // Set the initial value of selected days
        setModal(true);
    };

    const handleCountChange = async (event, itemId, index) => {
        const { value } = event.target;
        const newData = data.map((item, i) => {
            if (i === index) {
                return { ...item, cs_allow_count: value };
            }
            return item;
        });
        setData(newData);
    };

    const handleModalSave = async () => {
        const newItem = { ...data[selectedRowIndex], cs_allow_count: selectedDays };
        const newData = [...data];
        newData[selectedRowIndex] = newItem;
        setData(newData);

        const itemId = data[selectedRowIndex].cs_facility_detail_id;
        try {
            const token = getToken();
            await axios.put(`${BackendAPI}/category/updateCount/${itemId}`, { cs_allow_count: selectedDays, catId }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            console.log('Data updated successfully');
            setModal(false);
            setEditableRows(prevState => {
                const newState = [...prevState];
                newState[selectedRowIndex] = false; // Disable editing after saving
                return newState;
            });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const getFormattedFacilityName = (name) => {
        if (name && name.startsWith && name.includes('_')) {
            const [prefix, suffix] = name.split('_');
            const formattedSuffix = suffix.charAt(0).toUpperCase() + suffix.slice(1).replace(/\d+/g, day => ` day ${day}`);
            return `${formattedSuffix}`;
        } else {
            return name || '';
        }
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Edit Category Access" parent="Category" title="Edit Category Acess" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-center">{catName}</h5>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='table-responsive'>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr className='border-bottom-primary'>
                                                    <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                    <th scope='col' className='text-center'>{'Facility Name'}</th>
                                                    <th scope='col' className='text-center'>{'Allow Count'}</th>
                                                    {CategoryAccesspermissions?.edit === 1 && (
                                                        <th scope='col' className='text-center'>{'Action'}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((page, index) => (
                                                    <tr key={index} className="border-bottom-primary">
                                                        <td className='text-start'>{index + 1}</td> {/* Incremental serial number */}
                                                        <td className='text-center'>{getFormattedFacilityName(page.cs_facility_name)}</td>
                                                        <td className='text-center'>
                                                            <Input
                                                                type="text"
                                                                value={page.cs_allow_count}
                                                                className="border-0 text-center"
                                                                min={0}
                                                            />


                                                        </td>
                                                        {CategoryAccesspermissions?.edit === 1 && (
                                                            <td className='text-center'>
                                                                <Button color="warning" size="sm" onClick={() => toggleRowEditable(index)} disabled={editableRows[index]}>
                                                                    <FaEdit />
                                                                </Button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)} centered size="sm">
                <ModalHeader toggle={() => setModal(!modal)}>Enter Allowed Count</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Set allow counts reflecting on user badge data so make sure counts are correct
                        </p>
                    </div>

                    <Input
                        type="number"
                        value={selectedDays}
                        className='text-center'
                        onChange={(e) => setSelectedDays(Math.max(0, parseInt(e.target.value, 10)))}
                        inputMode="numeric"
                        min={0}
                    />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleModalSave}>Save</Button>
                    <Button color="warning" onClick={() => setModal(!modal)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default CategoryAccess;
