import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader, Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Breadcrumbs, P } from '../../../src/AbstractElements';
import axios from 'axios';
import { GoDotFill } from "react-icons/go";
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import Select, { components } from 'react-select';
import { Pagination } from 'antd';
import { BackendAPI } from '../../api';
import { useLocation } from 'react-router-dom';
// import generatePDFFromBadgeData from '../Badge-Design/badgeDownlode/BadgePDFDownloadButton';
import { IoMdPrint } from "react-icons/io";
import { GrPowerCycle } from "react-icons/gr";
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from "react-toastify";
import generatePDFFromBadgeListforList from '../Badge-Design/badgeDownlode/UserListbadgeprint';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import CustomizerContext from '../../_helper/Customizer';
import { Link, useNavigate } from 'react-router-dom';

// import ProtectedRoute, { useAuthentication } from '../../Auth/protectedroutes';
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';



const { MultiValueRemove } = components;

const UserListing = () => {
   
    useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    // const { catID } = useParams(); // Extract catID from URL params
    const [users, setUsers] = useState([]);
    const { catID } = location.state || {};
    const { Title } = location.state || {};
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [response, setResponse] = useState(null);
    const [allColumns, setAllColumns] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { permissions } = useContext(PermissionsContext);

    const { layoutURL } = useContext(CustomizerContext);




    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = getToken(); 
                const { catID } = location.state || {}; // Provide a default value for catID
                let url = `${BackendAPI}/manageuser/getUser?page=${currentPage}&pageSize=${pageSize}&search=${searchText}`;
                if (catID) {
                    url += `&catID=${catID}`;
                }
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
                setResponse(response.data);
                setUsers(response.data.Users);
                setTotalItems(response.data.totalItems);
                setLoading(false);
                const availableColumns = response.data.allColumn.map(column => ({
                    value: column.cs_field_name,
                    label: column.cs_field_label
                }));
                setAllColumns(availableColumns);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchUsers();
    }, [currentPage, pageSize, searchText, catID, permissions]);

    // Extract User Listing component
    const UserListingpermissions = permissions['User Listing'];
    // console.log('categoryPermissions', categoryPermissions);


    // Set initial selectedColumns to include the first four columns
    useEffect(() => {
        if (allColumns.length > 0) {
            setSelectedColumns(allColumns.slice(0, 4).map(column => column.value));
        }
    }, [allColumns]);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleSearch = (value) => {
        setSearchText(value);
        setCurrentPage(1);
    };

    const handleEditUser = async (user) => {
        const catId = user.cs_regno;
        console.log('user' , user);
        const URL = '/edit-user/';

        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { catId } });
    };



    const handleColumnChange = (selectedOptions) => {
        setSelectedColumns(selectedOptions.map(option => option.value));
    };

    const MultiValueRemoveWithCondition = ({ children, ...props }) => {
        // Check if the option should be removable
        const shouldRemove = selectedColumns.length > 4 && selectedColumns.indexOf(props.data.value) >= 4;

        // Render the remove button only if the condition is met
        return shouldRemove ? <MultiValueRemove {...props}>{children}</MultiValueRemove> : null;
    };



    // const handlePrintBadge = async (user) => {

    //     try {
    //         const category = user.cs_reg_category;

    //         console.log("Printing badge for user:", JSON.stringify(user, null, 2));

    //         const response = await axios.post(`${BackendAPI}/badge/getbadgefileds`, { category });

    //         const apibadgeDataResponse = response.data.badgedata;

    //         console.log("apibadgeDataResponse form getbadgefiledsapi ",apibadgeDataResponse);

    //         apibadgeDataResponse.badge_fields.forEach(field => {
    //             // Check if the user object contains a property with the same name as cs_field_name
    //             if (
    //                 user.hasOwnProperty(field.cs_field_name) &&
    //                 user[field.cs_field_name] !== null && // Check for null values
    //                 user[field.cs_field_name] !== undefined // Check for undefined values
    //             ) {
    //                 // If the value is a string, update cs_field_name with the corresponding value from the user object
    //                 if (typeof user[field.cs_field_name] === 'string') {
    //                     field.cs_field_name = user[field.cs_field_name].trim(); // Trim any leading/trailing spaces
    //                 } else { // If the value is not a string, convert it to a string
    //                     field.cs_field_name = String(user[field.cs_field_name]);
    //                 }
    //             }
    //         });

    //         // Now badgeDataResponse contains the updated values from the user object
    //         console.log("Final badge data before print :", apibadgeDataResponse);
    //         const badgeList = [apibadgeDataResponse];
    //         await generatePDFFromBadgeListforList(badgeList);


    //         // await generatePDFFromBadgeListforList(apibadgeDataResponse); 
    //     } catch (error) {
    //         console.error('Error fetching badge data:', error);
    //         // Check if the error is from the API response
    //         if (error.response && error.response.data && error.response.data.error) {
    //             // Show the error message from the API response using toast
    //             toast.error(error.response.data.error);
    //         } else {
    //             // Show a generic error message for other types of errors
    //             toast.error('An unexpected error occurred while fetching badge data. Please try again later.');
    //         }
    //     }
    // };

    //-
    const handlePrintBadge = async (user) => {
        try {
            const category = user.cs_reg_category;

            console.log("Printing badge for user:", JSON.stringify(user, null, 2));
            const token = getToken(); 
            const response = await axios.post(`${BackendAPI}/badge/getbadgefileds`, { category }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            const apibadgeDataResponse = response.data.badgedata;

            console.log("apibadgeDataResponse from getbadgefiledsapi ", apibadgeDataResponse);

            apibadgeDataResponse.badge_fields.forEach(field => {
                // Check if the user object contains a property with the same name as cs_field_name
                if (
                    user.hasOwnProperty(field.cs_field_name) &&
                    user[field.cs_field_name] !== null && // Check for null values
                    user[field.cs_field_name] !== undefined // Check for undefined values
                ) {
                    // If the value is a string, update cs_field_name with the corresponding value from the user object
                    if (typeof user[field.cs_field_name] === 'string') {
                        field.cs_field_name = user[field.cs_field_name].trim(); // Trim any leading/trailing spaces
                    } else { // If the value is not a string, convert it to a string
                        field.cs_field_name = String(user[field.cs_field_name]);
                    }
                }

                // Check if cs_field_name is "fullname" and update it accordingly
                if (field.cs_field_name === "fullname") {
                    field.cs_field_name = `${user.cs_first_name} ${user.cs_last_name}`;
                }
            });

            // Now badgeDataResponse contains the updated values from the user object
            console.log("Final badge data before print :", apibadgeDataResponse);
            const badgeList = [apibadgeDataResponse];
            await generatePDFFromBadgeListforList(badgeList);

        } catch (error) {
            console.error('Error fetching badge data:', error);
            // Check if the error is from the API response
            if (error.response && error.response.data && error.response.data.error) {
                // Show the error message from the API response using toast
                toast.error(error.response.data.error);
            } else {
                // Show a generic error message for other types of errors
                toast.error('An unexpected error occurred while fetching badge data. Please try again later.');
            }
        }
    };


    const  handlefacilityReset = (user) => {
        const URL = '/facility-reset/';
        // Pass data as state through route props
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { user } });
    };
   

    return (
        <Fragment>
            <Breadcrumbs mainTitle="User Listing" parent="Manage User" title="User Listing" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-start">{Title} User List</h5>
                                    <Input
                                        placeholder="Search across all columns"
                                        onChange={e => handleSearch(e.target.value)}
                                        style={{ width: 200 }}
                                    />
                                </div>
                                <div className="mt-2 mt-md-0">
                                    <Button color="primary" onClick={toggleModal}>Select Columns</Button>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className='table-responsive'>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <Table className="table">
                                            <thead>
                                                <tr className="border-bottom-primary">
                                                    <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                    {selectedColumns.map((col) => (
                                                        <th className='text-center' key={col}>{allColumns.find(column => column.value === col)?.label}</th>
                                                    ))}
                                                    <th scope='col' className='text-end'>{'Status'}</th>
                                                    {UserListingpermissions?.edit === 1 || UserListingpermissions?.delete === 1 || UserListingpermissions?.print === 1 ? (
                                                        <th scope='col' className='text-center'>{'Action'}</th>
                                                    ) : null}
                                                </tr>
                                            </thead>

                                            <tbody className='text-center'>
                                                {users.map((user, index) => (
                                                    <tr key={index}>
                                                        <td className='text-start'>{(currentPage - 1) * pageSize + index + 1}</td>
                                                        {selectedColumns.map((col) => (
                                                            <td key={col}>{user[col]}</td>
                                                        ))}
                                                        <td className='text-center'>
                                                            {users.cs_status === 0 ? (
                                                                <span style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus"
                                                                >
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <GoDotFill
                                                                    style={{ color: "green", fontSize: '20px', cursor: "pointer" }}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus"

                                                                />
                                                            )}
                                                        </td>
                                                        {UserListingpermissions?.edit === 1 || UserListingpermissions?.delete === 1 || UserListingpermissions?.print === 1 ? (
                                                            <td className='text-center'>
                                                                <Tooltip id="tooltip" globalEventOff="click" />
                                                                {UserListingpermissions?.edit === 1 && (
                                                                    <Button color="primary" size="sm"
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Edit User"
                                                                        data-tooltip-event="click focus" onClick={() => handleEditUser(user)} >
                                                                        <FaEdit />
                                                                    </Button>
                                                                )}
                                                                {UserListingpermissions?.delete === 1 && (
                                                                    <Button color="danger" size="sm"
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Remove User"
                                                                        data-tooltip-event="click focus"  >
                                                                        <MdDelete />
                                                                    </Button>
                                                                )}
                                                                {UserListingpermissions?.print === 1 && (
                                                                    <Button color="success" size="sm" onClick={() => handlePrintBadge(user)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Print Badge"
                                                                        data-tooltip-event="click focus">
                                                                        <IoMdPrint />
                                                                    </Button>
                                                                )}

                                                                {UserListingpermissions?.edit === 1 && (
                                                                    <Button color="warning" size="sm" onClick={() => handlefacilityReset(user)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Reset Facility"
                                                                        data-tooltip-event="click focus">
                                                                        <GrPowerCycle />
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        ) : null}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                                {totalItems > 10 && (
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <Pagination
                                            onChange={handlePageChange}
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            showSizeChanger={true}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Select Columns</ModalHeader>
                <ModalBody>
                    {response && (
                        <Select
                            options={allColumns.map(column => ({
                                value: column.value,
                                label: column.label,
                                isDisabled: column.isFixed // Disable fixed columns
                            }))}
                            isMulti
                            value={selectedColumns.map(col => ({
                                value: col,
                                label: allColumns.find(column => column.value === col)?.label
                            }))}
                            onChange={handleColumnChange}
                            components={{
                                MultiValueRemove: MultiValueRemoveWithCondition // Use custom MultiValueRemove component
                            }}
                        />


                    )}
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={handleModalSave}>Save</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button> */}
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default UserListing;
