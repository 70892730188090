


import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Label, Button, CardBody, Input } from 'reactstrap';
import axios from 'axios';
import { BackendAPI } from '../../api';
import { Field, Form } from 'react-final-form';
import { required, Name } from '../Utils/validationUtils';
import CustomizerContext from '../../_helper/Customizer';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../AbstractElements';
import SweetAlert from 'sweetalert2';
import { FaEdit } from 'react-icons/fa'; // Import the key icon
import { MdDelete } from "react-icons/md";
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';
import debounce from 'lodash.debounce';


const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const EditCategory = () => {
    useAuth();
    const [category, setCategory] = useState([]); // State to store category data
    const { layoutURL } = useContext(CustomizerContext);
    const location = useLocation();
    const { catId, catName } = location.state;
    const navigate = useNavigate();
    const [designationName, setDesignationName] = useState('');
    const [newdesignations, setdesignations] = useState([]);
    const [deleteDesignations, setDeleteDesignations] = useState([]);
    const [name, setName] = useState('');
    const [nameValidatioMessage, setNameValidatioMessage] = useState('');
    const [nameTouched, setNameTouched] = useState(false);


    console.log(deleteDesignations);


    useEffect(() => {
        fetchCategory(); // Fetch category data when component mounts
    }, []);

    const fetchCategory = async () => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/category/editcategory`, { catId }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            console.log('Data from API:', response.data);
            setCategory(response.data); // Set category data to the response array
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    };

    const handleCancel = () => {
        const URL = '/categories/';
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`);
    };

    const onSubmit = async (values) => {
        try {
            // Convert boolean values to integers
            const adduserValue = values.adduser ? 1 : 0;
            const spotValue = values.spot ? 1 : 0;

            // const newdesignations = setdesignations;

            console.log("newdesignations in submit", newdesignations);


            // Construct the designations array dynamically based on the available designation IDs and names
            const designations = [];
            for (let i = 0; i < Object.keys(values).length; i++) {
                // Check if the key starts with 'designationId' and extract the index
                const index = Object.keys(values).findIndex(key => key === `designationId${i}`);
                if (index !== -1) {
                    // If designationId exists and has been changed, add the corresponding designationId and designationName to the array
                    const designationId = values[`designationId${i}`];
                    const designationName = values[`designationName${i}`];
                    const originalDesignationId = category[i].cs_designation_id; // Assuming category is available and contains original data
                    const originalDesignationName = category[i].cs_designation_name; // Assuming category is available and contains original data
                    if (designationId !== originalDesignationId || designationName !== originalDesignationName) {
                        designations.push({
                            id: designationId,
                            name: designationName
                        });
                    }
                }
            }

            // Now you can send adduserValue, spotValue, and designations to the backend
            const token = getToken();
            // Send request to update category
            const response = await axios.post(`${BackendAPI}/category/updatecategory`, {
                catId,
                categoryName: values.categoryName,
                // adduser: adduserValue,
                spot: spotValue,
                designations: designations,
                newdesignations: newdesignations,
                delDesignations: deleteDesignations
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            if (response.status === 200) {
                console.log('Category updated successfully');
            } else {
                console.error('Error updating category:', response.data.message);
            }

            SweetAlert.fire({
                title: 'Success!',
                text: 'Changes Updated successfully !',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate(`${process.env.PUBLIC_URL}/Categories/${layoutURL}`);
                }
            });
        } catch (error) {
            console.error('Error updating settings:', error.message);
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.value;
        setDesignationName(newValue);
        console.log("designationName:", newValue);
    };

    const handleAddCategory = () => {
        if (designationName.trim() !== '') {
            setdesignations([...newdesignations, designationName]);
            setDesignationName('');
        }
    };

    const handleEditCategory = (index, newName) => {
        const updateddesignations = [...newdesignations];
        updateddesignations[index] = newName;
        setdesignations(updateddesignations);
    };

    const handleRemoveCategory = (index) => {
        const updateddesignations = [...newdesignations];
        updateddesignations.splice(index, 1);
        setdesignations(updateddesignations);
    };

    const handleDeleteDesignation = (cs_designation_id) => {
        console.log("Deleting designation with ID:", cs_designation_id);
        setDeleteDesignations(prevState => [...prevState, cs_designation_id]);
    };



    const validatename = debounce(async (value) => {
        try {

            if (value === catName) {
                // Skip validation if the name is the same as the current name
                setNameValidatioMessage('');
                return;
            }

            // Call the API to check name availability
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/category/check-category`, { catName: value }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            console.log('Server response:', response);
            if (!response.data.available) {
                setNameValidatioMessage('Category already exists');
            } else {
                // Only set the validation message if the name is valid
                setNameValidatioMessage('');
            }
        } catch (error) {
            console.error('Error checking name availability:', error);
            setNameValidatioMessage('Error checking name availability');
        }
    }, 500); // Debounce time: 500ms



    // Effect to trigger validation when name changes
    useEffect(() => {
        if (nameTouched) { // Only validate name if it has been touched
            validatename(name);
        }
        return () => {
            validatename.cancel();
        };
    }, [name, nameTouched]);








    return (
        <Fragment>
            <Breadcrumbs mainTitle="Edit Category" parent="Category" title="Edit Category" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form onSubmit={onSubmit}>
                                    {({ handleSubmit }) => (
                                        <form className='needs-validation' noValidate='' onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Field
                                                        name="categoryName"
                                                        initialValue={category[0]?.cs_reg_category || ''}
                                                        validate={composeValidators(required, Name)}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="categoryName"><strong>Category Name *</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="categoryName"
                                                                    type="text"
                                                                    onChange={(e) => {
                                                                        input.onChange(e); // Trigger onChange of the Field component
                                                                        setName(e.target.value);
                                                                        setNameTouched(true);
                                                                    }}
                                                                />
                                                                {nameValidatioMessage && <div className="text-danger">{nameValidatioMessage}</div>}

                                                                {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Label className="form-label" htmlFor="designationName"><strong>Designation Name</strong></Label>
                                                    <div className="d-flex">
                                                        <input
                                                            className="form-control"
                                                            id="designationName"
                                                            type="text"
                                                            value={designationName}
                                                            onChange={handleChange}
                                                        />
                                                        <Button color="primary" onClick={handleAddCategory} className="ml-2">Add</Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {category.some(item => item.cs_designation_name) ? (
                                                <Row>
                                                    {category.map((item, index) => (
                                                        <Fragment key={index}>
                                                            {/* Hidden input field for cs_designation_id */}
                                                            <Field name={`designationId${index}`} initialValue={item.cs_designation_id || ''}>
                                                                {({ input }) => (
                                                                    <input {...input} type="hidden" />
                                                                )}
                                                            </Field>
                                                            {/* Visible input field for cs_designation_name */}
                                                            <Col md="4" className="mb-3">
                                                                <Field
                                                                    name={`designationName${index}`}
                                                                    initialValue={item.cs_designation_name || ''}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className="form-label" htmlFor={`designationName${index}`}><strong>Designation Name</strong></Label>
                                                                            <input
                                                                                {...input}
                                                                                className="form-control"
                                                                                id={`designationName${index}`}
                                                                                type="text"
                                                                            />
                                                                            <Button color="danger" size="sm" onClick={() => handleDeleteDesignation(item.cs_designation_id)} className="ml-2"><MdDelete /></Button>

                                                                        </div>
                                                                    )}
                                                                </Field>

                                                            </Col>


                                                        </Fragment>
                                                    ))}
                                                </Row>
                                            ) : null}


                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <ul className="list-group">
                                                        {newdesignations.map((category, index) => (
                                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                                <Input
                                                                    type="text"
                                                                    value={category}
                                                                    onChange={(e) => handleEditCategory(index, e.target.value)}
                                                                />
                                                                <div>
                                                                    {/* <Button color="primary" size="sm"><FaEdit /></Button> */}
                                                                    <Button color="danger" size="sm" onClick={() => handleRemoveCategory(index)}><MdDelete /></Button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col >
                                                    <Field
                                                        name="spot"
                                                        initialValue={category[0]?.cs_visible_onspot === 1 ? true : false} // Convert 1 to true and 0 to false
                                                        type="checkbox"
                                                    >
                                                        {({ input, meta }) => (
                                                            <div >
                                                                <input
                                                                    {...input}
                                                                    id="spot" // Correct ID for the input
                                                                />
                                                                <Label className='form-check-label' style={{ marginLeft: '10px' }} htmlFor="spot"><strong>Visible in spot registration form</strong></Label>
                                                                {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                </Col>
                                            </Row> */}

                                            <div>
                                                <Button color='primary' type='submit' className="me-3 mt-3">Save</Button>
                                                <Button color='warning' onClick={handleCancel} className="mt-3">Cancel</Button>
                                            </div>
                                        </form>
                                    )}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default EditCategory;
