import React, { Fragment, useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import debounce from 'lodash.debounce';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { required, email, password, file } from '../Utils/validationUtils';
import axios from 'axios';



//Utility function used to combine multiple validation functions into a single validation function
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const MyForm = () => {
    const [checkingEmail, setCheckingEmail] = useState(false);
    const [emailAvailable, setEmailAvailable] = useState(true); // State to track email availability
    const [email, setEmail] = useState('');
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);

    const onSubmit = async (formData) => {
        // Handle form submission
        console.log(formData); // For demonstration, you can replace this with your submission logic
    };

    const validateEmail = debounce(async (value) => {
        try {
            // Check email format
            const isValidEmailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            if (!isValidEmailFormat) {
                setEmailValidationMessage('Invalid email format');
                return;
            }

            // Call the API to check email availability
            const response = await axios.post('${BackendAPI}/user/check-email', { email: value });


            console.log('Server response:', response);
            if (!response.data.available) {
                setEmailValidationMessage('Email already exists');
            } else {
                setEmailValidationMessage('Email is valid');
            }
        } catch (error) {
            console.error('Error checking email availability:', error);
            setEmailValidationMessage('Error checking email availability');
        }
    }, 500); // Debounce time: 500ms

    // Effect to trigger validation when email changes
    useEffect(() => {
        if (emailTouched) { // Only validate email if it has been touched
            validateEmail(email);
        }
        return () => {
            validateEmail.cancel();
        };
    }, [email, emailTouched]);


    return (
        <Fragment>
            <Breadcrumbs mainTitle="Create App User" parent="App User Login" title="Create App User" />
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form
                                    onSubmit={onSubmit}
                                    render={({ handleSubmit, submitting, pristine, values, valid }) => (
                                        <form onSubmit={handleSubmit}>

                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Field
                                                        name="email"
                                                        validate={composeValidators(required, email)}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Email</label>
                                                                <input
                                                                    {...input}
                                                                    type="text"
                                                                    placeholder="Enter Email"
                                                                    // onChange={(event) => {
                                                                    //     input.onChange(event.target.value); // Update the input value
                                                                    //     checkEmailAvailability(event.target.value); // Trigger email availability check
                                                                    // }}
                                                                    value={email}
                                                                    onChange={(e) => {
                                                                        setEmail(e.target.value);
                                                                        setEmailTouched(true);
                                                                    }}
                                                                />
                                                                {/* {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                                                {checkingEmail && <span className="loading-spinner">Checking email availability...</span>}
                                                                {!emailAvailable && !checkingEmail && <span className="error">Email already exists</span>} */}
                                                                {emailValidationMessage && <div className="text-danger">{emailValidationMessage}</div>}

                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>

                                                <Col md="4" className="mb-3">
                                                    <Field
                                                        name="password"
                                                        validate={composeValidators(required, email)}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Password</label>
                                                                <input {...input} type="password" placeholder="Enter Password" />
                                                                {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Field
                                                        name="selection"
                                                        validate={required}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Selection</label>
                                                                <select {...input}>
                                                                    <option value="">Select an option</option>
                                                                    <option value="option1">Option 1</option>
                                                                    <option value="option2">Option 2</option>
                                                                    <option value="option3">Option 3</option>
                                                                </select>
                                                                {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>

                                                <Col md="4" className="mb-3">
                                                    <Field
                                                        name="file"
                                                        validate={file}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>File</label>
                                                                <input
                                                                    type="file"
                                                                    onChange={(event) => input.onChange(event.target.files[0])}
                                                                />
                                                                {meta.error && meta.touched && (
                                                                    <div>
                                                                        {meta.error.map((error, index) => (
                                                                            <span key={index} className="error">{error}</span>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>

                                            <Button type="submit" color="primary" disabled={submitting || !valid}>
                                                Submit
                                            </Button>
                                        </form>
                                    )}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default MyForm;
