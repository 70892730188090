// validationUtils.jsx
export const requiredMessage = 'This field is required';
export const invalidEmailMessage = 'Invalid email address';




export const required = value => (value ? undefined : 'This field is required');

export const expiryDate = value => (value ? undefined : 'Please provide valid input to proceed');

export const option = value => (value ? undefined : 'Please provide valid input to proceed');






export const email = value =>
  value && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(value)
    ? undefined
    : 'Invalid email address';

export const name = value =>
  value && /^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(value)
    ? undefined
    : 'Only alphabetic characters are allowed.';


export const username = (value) => {
  if (!value || value.length < 6 || value.length > 32) {
    return 'Name must be between 6 and 32 characters long.';
  }

  // Regular expression to check if the name contains invalid characters
  if (!/^[a-zA-Z0-9-_]+$/.test(value)) {
    return 'Name can only contain alphanumeric characters, hyphen, and underscore.';
  }

  return undefined; // Validation passed
};



export const Name = value =>
  value && /^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(value)
    ? undefined
    : 'Only alphabetic characters are allowed.';


export const Wname = value =>
  value && /^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(value)
    ? undefined
    : 'Only alphabetic characters are allowed';




export const password = value => {
  if (!value) {
    return 'Password is required';
  }

  const lengthCriteria = /.{8,}/;
  const uppercaseCriteria = /[A-Z]/;
  const lowercaseCriteria = /[a-z]/;
  const digitCriteria = /[0-9]/;
  const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>]/;

  if (!lengthCriteria.test(value)) {
    return 'Password must be at least 8 characters long';
  }
  if (!uppercaseCriteria.test(value)) {
    return 'Password must include at least one uppercase letter';
  }
  if (!lowercaseCriteria.test(value)) {
    return 'Password must include at least one lowercase letter';
  }
  if (!digitCriteria.test(value)) {
    return 'Password must include at least one digit';
  }
  if (!specialCharacterCriteria.test(value)) {
    return 'Password must include at least one special character';
  }

  return undefined;
};


export const file = value => {
  console.log('Value:', value);
  if (!value || !value.type) {
    return 'Please upload an image file';
  }

  // Define the maximum allowed size (in bytes)
  const maxSize = 200 * 1024; // 200 KB

  console.log('File type:', value.type);

  if (value.size > maxSize) {
    return 'Image size exceeds the maximum allowed size (200 KB)';
  }

  return undefined;
};






export const selection = value =>
  value ? undefined : 'Please make a selection';

export const number = value =>
  value && !isNaN(value) ? undefined : 'Please enter a valid number';


export const Img = (file) => {
  if (!file) return 'This field is required';

  const allowedImageTypes = ['image/png'];
  const maxSizeBytes = 200 * 1024; // 200 KB converted to bytes

  if (!allowedImageTypes.includes(file.type)) {
    return 'Please select a PNG image.';
  }

  if (file.size > maxSizeBytes) {
    return 'Image file size exceeds 200 KB limit.';
  }

  return '';
};

export const BanImg = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('Please select an image file.');
      return;
    }

    const allowedImageTypes = ['image/jpeg', 'image/png'];
    const maxSizeBytes = 300 * 1024; // 300 KB converted to bytes
    const maxWidth = 2000; // Maximum width in pixels
    const maxHeight = 600; // Maximum height in pixels

    if (!allowedImageTypes.includes(file.type)) {
      reject('Please select a JPEG or PNG image file.');
      return;
    }

    if (file.size > maxSizeBytes) {
      reject('The selected image file size exceeds the 300 KB limit.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.onload = function () {
        if (this.width > maxWidth) {
          reject(`The width of the selected image exceeds the ${maxWidth} pixels limit.`);
        } else if (this.height > maxHeight) {
          reject(`The height of the selected image exceeds the ${maxHeight} pixels limit.`);
        } else {
          resolve('');
        }
      };
      img.onerror = function () {
        reject('Failed to load the selected image.');
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
};







export const PDF = (file) => {
  if (!file) return 'Please select a file.';

  const allowedDocTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  const maxSizeMB = 1;

  if (!allowedDocTypes.includes(file.type)) {
    return 'Please select a PDF, DOC, or DOCX document.';
  }

  if (file.size > maxSizeMB * 1024 * 1024) {
    return 'Document file size exceeds 1 MB limit.';
  }

  return '';
};


