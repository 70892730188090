import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Table, CardHeader, CardBody, Input, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import axios from 'axios';
import { BackendAPI } from '../../api';
import { Link, useNavigate } from 'react-router-dom'; // Import useHistory for programmatic navigation
import { GoDotFill } from "react-icons/go";
import { FaKey } from 'react-icons/fa'; // Import the key icon
import { Pagination } from 'antd';
import CustomizerContext from '../../_helper/Customizer';
import { classes } from '../../Data/Layouts';
import { Tooltip } from 'react-tooltip';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';


const Category = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [catIds, setCatIds] = useState([]);
    const { layoutURL } = useContext(CustomizerContext);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate(); // Initialize useHistory
    const { permissions } = useContext(PermissionsContext);


    useEffect(() => {
        fetchCategory();
    }, [currentPage, pageSize, searchText, permissions]);

    // Extract Category Access component
    const CategoryAccesspermissions = permissions['Category Access'];

    const fetchCategory = async () => {
        try {
            const token = getToken(); 
            const Response = await axios.get(`${BackendAPI}/category/getCategory?page=${currentPage}&pageSize=${pageSize}&search=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            console.log(Response.data); // Log the response data
            setData(Response.data.categories); // Assuming categories property contains the array
            setTotalItems(Response.data.totalItems);
            const catIds = Response.data.categories.map(category => category.cs_reg_cat_id);
            setCatIds(catIds); // Update the catIds state
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };




    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };


    const handleManagePermission = (catId, catName) => {
        const URL = '/Category-access/';
        // Pass data as state through route props
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { catId, catName } });
    };

    const resetBadgeData = async (catIds) => {
        try {
            const token = getToken(); 
            const response = await axios.post(`${BackendAPI}/category/resetBadgeData`, { catIds }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            // Assuming the response contains updated data in a property called 'categories'
            setModal(false);

            setLoading(false);
        } catch (error) {
            console.error('Error resetting badge data:', error);
            setLoading(false);
        }
    };





    return (
        <Fragment>
            <Breadcrumbs mainTitle="Manage Category Access" parent="Onsite App" title="Manage Category Access" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-center">Category Access</h5>
                                </div>
                                <div className="mt-2 mt-md-0">
                                    <Input
                                        placeholder="Search Category"
                                        onChange={e => handleSearch(e.target.value)}
                                        style={{ width: 200 }}
                                    />
                                </div>
                            </CardHeader>

                            <CardBody>
                                <div className='table-responsive'>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr className='border-bottom-primary'>
                                                    <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                    <th scope='col' className='text-start'>{'Category Name'}</th>
                                                    {/* <th scope='col' className='text-center'>{'Status'}</th> */}
                                                    <th scope='col' className='text-end'>{'Action'}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index} className="border-bottom-primary">
                                                        <td className='text-start'>{index + 1}</td> {/* Incremental serial number */}
                                                        <td className='text-start'>{item.cs_reg_category}</td>
                                                        {/* <td className='text-center'>
                                                            {item.cs_status === '0' ? (
                                                                <span style={{ color: 'red', fontSize: '20px' }}>
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span style={{ color: 'green', fontSize: '20px' }}>
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                        </td> */}

                                                        <td className='text-end'>
                                                            <Tooltip id="tooltip" globalEventOff="click" />

                                                            <Button color="primary" size="sm" onClick={() => handleManagePermission(item.cs_reg_cat_id, item.cs_reg_category)}
                                                                data-tooltip-id="tooltip"
                                                                data-tooltip-content="Category Access"
                                                                data-tooltip-event="click focus" >
                                                                <FaKey /> {/* Key icon inside the button */}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                    
                                    {CategoryAccesspermissions?.validate === 1 && (
                                    <div className='text-end'>
                                        <Button color='warning' type='submit' className="mr-2 mt-3" onClick={() => setModal(true)}>
                                            Reset All Badge Data
                                        </Button>
                                    </div>
                                    )}
                                </div>

                                {/* Pagination */}
                                {totalItems > 10 && (
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <Pagination
                                            onChange={handlePageChange}
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            showSizeChanger={true}
                                        />
                                    </div>
                                )}
                                {/* End Pagination */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)} centered size="md">
                <ModalHeader toggle={() => setModal(!modal)}>Warning: Reseting Badge Data</ModalHeader>
                <ModalBody>
                    <div style={{ marginBottom: '20px' }}>
                        <p><strong>Alert:  Reset Badge Data</strong></p>
                        <p>
                            Resetting badge data will clear all scan and taken data for all users. This action will not affect the assigned permissions for users.
                            Before proceeding, please ensure that you want to reset the scan and usage counts for all users, as this action cannot be undone.
                        </p>
                        <p>Are you sure you want to continue?</p>
                    </div>                
                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => resetBadgeData(catIds)}>Sure</Button>
                    <Button color="secondary" onClick={() => setModal(!modal)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default Category;
