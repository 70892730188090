import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Table, CardHeader, CardBody, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import axios from 'axios';
import { BackendAPI } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import { Pagination } from 'antd';
import CustomizerContext from '../../_helper/Customizer';
import SweetAlert from 'sweetalert2';
import { Field, Form } from 'react-final-form';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import { Tooltip } from 'react-tooltip';
import { getToken } from '../../Auth/Auth';
import Select from 'react-select';

import useAuth from '../../Auth/protectedAuth';

// Define the required validator
const requiredValidator = value => (value ? undefined : 'This field is required');

// Utility function to combine multiple validation functions
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const Categories = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [statusModal, setStatusModal] = useState(false);
    const [activestatusModal, setActiveStatusModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [selectedCatname, setSelectedCatName] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [catData, setCatData] = useState([]);
    const [selectedCat, setSelectedCat] = useState(null); // Added state to store selected category
    const [modal, setModal] = useState(false);
    const [deleteCategoryId, setDeleteCategoryId] = useState(null);
    const { layoutURL } = useContext(CustomizerContext);
    const navigate = useNavigate();
    const { permissions } = useContext(PermissionsContext);




    useEffect(() => {
        fetchCategory();
        fetchCat();
    }, [currentPage, pageSize, searchText, permissions]);

    // Extract Category component
    const categoryPermissions = permissions['Category'];

    const fetchCategory = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/category/getCategories?page=${currentPage}&pageSize=${pageSize}&search=${searchText}`,
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}` // Include the token in the Authorization header
                    }
                });

            setData(response.data.categories);
            setTotalItems(response.data.totalItems);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchCat = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/category/getCat`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const catData = response.data.Types.map(item => ({
                id: item.cs_reg_cat_id,
                Cat: item.cs_reg_category
            }));
            setCatData(catData);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleSearch = (value) => {
        setSearchText(value);
        setCurrentPage(1);
    };

    const handleEdit = (catId, catName) => {
        const URL = '/Edit-Category/';
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { catId, catName } });
    };

    const handleDelete = async (catId) => {
        try {
            const token = getToken();
            await axios.delete(`${BackendAPI}/category/deletecategory/${catId}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            SweetAlert.fire({
                title: 'Success!',
                text: 'Category removed successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate(`${process.env.PUBLIC_URL}/Categories/${layoutURL}`);
                }
            });
        } catch (error) {
            console.error('Error deleting workshop:', error);
        }
    };

    const toggleStatusModal = (catId, currentStatus, catName) => {
        setSelectedCategoryId(catId);
        setSelectedStatus(currentStatus);
        setSelectedCatName(catName);
        setStatusModal(!statusModal);
    };

    const closetoggleStatusModal = () => {
        setSelectedCategoryId(null);
        setSelectedStatus(null);
        setStatusModal(false);
    };

    const toggleActiveStatusModal = (catId, currentStatus, catName) => {
        setSelectedCategoryId(catId);
        setSelectedStatus(currentStatus);
        setSelectedCatName(catName);
        setActiveStatusModal(!activestatusModal);
    };

    const closetoggleActiveStatusModal = () => {
        setSelectedCategoryId(null);
        setSelectedStatus(null);
        setSelectedCatName(null);
        setActiveStatusModal(false);
    };

    const confirmStatusUpdate = async (newCat) => {
        const newStatus = selectedStatus == 0 ? 1 : 0;
        try {
            const token = getToken();
            await axios.put(`${BackendAPI}/category/UpdateStatus`, { Id: selectedCategoryId, newCatid: newCat, status: newStatus }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            const updatedcat = data.map((workshop) =>
                workshop.cs_reg_cat_id === selectedCategoryId ? { ...workshop, cs_status: workshop.cs_status === 0 ? 1 : 0 } : workshop
            );
            setData(updatedcat);
            setStatusModal(false);
            setActiveStatusModal(false);
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedCat(selectedOption ? selectedOption.value : null);
    };

    const openDeleteModal = (catId) => {
        setDeleteCategoryId(catId);
        setModal(true);
    };

    const closeDeleteModal = () => {
        setDeleteCategoryId(null);
        setModal(false);
    };

    const handleNavigation = () => {
        navigate(`${process.env.PUBLIC_URL}/Add-Category/Consoft`);
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Manage Category" parent="Onsite App" title="Manage Category" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-start">Categories</h5>
                                    <Input
                                        placeholder="Search Category"
                                        onChange={e => handleSearch(e.target.value)}
                                        style={{ width: 200 }}
                                    />
                                </div>
                                {categoryPermissions?.add === 1 && (
                                     <button onClick={handleNavigation} className="btn btn-warning">
                                     Create Category
                                   </button>
                                )}
                            </CardHeader>

                            <CardBody>
                                <div className='table-responsive'>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr className='border-bottom-primary'>
                                                    <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                    <th scope='col' className='text-start'>{'Category Name'}</th>
                                                    <th scope='col' className='text-center'>{'Number of Attendee'}</th>
                                                    <th scope='col' className='text-center'>{'Number of Designation'}</th>
                                                    <th scope='col' className='text-center'>{'Status'}</th>
                                                    {categoryPermissions?.edit === 1 || categoryPermissions?.delete === 1 ? (
                                                        <th scope='col' className='text-end'>Action</th>
                                                    ) : null}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index} className="border-bottom-primary">
                                                        <td className='text-start'>{(currentPage - 1) * pageSize + index + 1}</td>
                                                        <td className='text-start'>{item.cs_reg_category}</td>
                                                        <td className='text-center'>{item.userCount}</td>
                                                        <td className='text-center'>{item.designationCount}</td>
                                                        <td className='text-center'>
                                                            {item.cs_status == 0 ? (
                                                                <span style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => toggleActiveStatusModal(item.cs_reg_cat_id, item.cs_status, item.cs_reg_category)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span style={{ color: 'green', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => toggleStatusModal(item.cs_reg_cat_id, item.cs_status, item.cs_reg_category)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                        </td>
                                                        {categoryPermissions?.edit === 1 || categoryPermissions?.delete === 1 ? (

                                                            <td className='text-end'>
                                                                {/* Check edit permission */}
                                                                {categoryPermissions?.edit === 1 && (
                                                                    <Button color="primary" size="sm" onClick={() => handleEdit(item.cs_reg_cat_id, item.cs_reg_category)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Edit Category"
                                                                        data-tooltip-event="click focus">
                                                                        <FaEdit />
                                                                    </Button>
                                                                )}
                                                                {/* Check delete permission */}
                                                                {categoryPermissions?.delete === 1 && (
                                                                    <Button color="danger" size="sm" onClick={() => openDeleteModal(item.cs_reg_cat_id)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Delete Category"
                                                                        data-tooltip-event="click focus" >
                                                                        <MdDelete />
                                                                    </Button>
                                                                )}
                                                                <Tooltip id="tooltip" globalEventOff="click" />
                                                            </td>

                                                        ) : null}

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>

                                {totalItems > 10 && (
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <Pagination
                                            onChange={handlePageChange}
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            showSizeChanger={true}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Inactive Status Confirmation Modal */}
            <Modal isOpen={statusModal} toggle={closetoggleStatusModal} centered size="md">
                <ModalHeader toggle={closetoggleStatusModal}>Confirmation</ModalHeader>
                <ModalBody>
                    <div>
                        <p><strong>Alert: Changing Status</strong></p>
                        <p>
                            This action will inactive the {selectedCatname} and all associated users will be allocated to the replacement category & inactive the category.
                        </p>
                        <p>Are you sure you want to continue?</p>
                    </div>
                    <FormGroup>
                        <Label for="categorySelect">Select replacement Category:</Label>
                        <Select
                            id="categorySelect"
                            value={catData.find(option => option.value === selectedCat)}
                            onChange={handleSelectChange}
                            options={catData
                                .filter(option => option.id !== selectedCategoryId)
                                .map(pref => ({ value: pref.id, label: pref.Cat }))}
                            placeholder="Select replacement Category"
                            isSearchable={true}
                            className="react-select"
                        />

                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => confirmStatusUpdate(selectedCat)}>Yes</Button>
                    <Button color="primary" onClick={closetoggleStatusModal}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Active Status Confirmation Modal */}
            <Modal isOpen={activestatusModal} toggle={closetoggleActiveStatusModal} centered size="md">
                <ModalHeader toggle={closetoggleActiveStatusModal}>Confirmation: Changing Status</ModalHeader>
                <ModalBody>
                    <div>
                        <p><strong>Alert: Changing Status</strong></p>
                        <p>
                            The previous {selectedCatname} access will be restored & you can create new users under {selectedCatname} Category
                        </p>
                        <p>Are you sure you want to continue?</p>
                    </div>
                    {/* <FormGroup>
                        <Label for="categorySelect">Select replacement Category:</Label>
                        <Select
                            id="categorySelect"
                            value={catData.find(option => option.value === selectedCat)}
                            onChange={handleSelectChange}
                            options={catData
                                .filter(option => option.id !== selectedCategoryId)
                                .map(pref => ({ value: pref.id, label: pref.Cat }))}
                            placeholder="Select replacement Category"
                            isSearchable={true}
                            className="react-select"
                        />

                    </FormGroup> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => confirmStatusUpdate(0)}>Yes</Button>
                    <Button color="primary" onClick={closetoggleActiveStatusModal}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={modal} toggle={closeDeleteModal} centered size="md">
                <ModalHeader toggle={closeDeleteModal}>Confirm</ModalHeader>
                <ModalBody>Are you sure you want to delete this Category?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleDelete(deleteCategoryId)}>Yes</Button>
                    <Button color="warning" onClick={closeDeleteModal}>No</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default Categories;