import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button, Table, Input, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Breadcrumbs } from '../../AbstractElements';
import Papa from 'papaparse';
import axios from 'axios';
import { BackendAPI } from '../../api';
import SweetAlert from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';

const ImportUserWithoutReg = () => {
    useAuth();
    const [importedData, setImportedData] = useState([]);
    const [duplicateEntries, setDuplicateEntries] = useState([]);
    const [wrongEntries, setWrongEntries] = useState([]); // State for wrong entries
    const [activeTab, setActiveTab] = useState('1');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [maxNumber, setMaxNumber] = useState(0); // State for maximum registration number
    const [catid, setCatid] = useState([]);
    const [fileData, setfileData] = useState([]);

    console.log("File data:", fileData);
    console.log("Correct Entries:", importedData);
    console.log("Duplicate Entries:", duplicateEntries);
    console.log("Wrong Entries:", wrongEntries);


    const navigate = useNavigate(); // Initialize useHistory

    useEffect(() => {
        // Fetch the maximum registration number when the component mounts
        fetchMaxNumber();
    }, []);

    const fetchMaxNumber = async () => {
        try {
            const token = getToken(); 
            const response = await axios.get(`${BackendAPI}/manageuser/getMaxNo`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const maxNo = response.data; // Response directly contains the maximum registration number
            setMaxNumber(maxNo);
        } catch (error) {
            console.error('Error fetching maximum registration number:', error);
        }
    };

    useEffect(() => {
        const fetchCatId = async () => {
            setLoading(true);
            try {
                const token = getToken(); 
                const response = await axios.get(`${BackendAPI}/field/getCatId`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
                setCatid(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching users:", error);
                setLoading(false);
            }
        };

        fetchCatId();
    }, []);


    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type !== 'text/csv') {
                SweetAlert.fire({
                    title: 'Warning!',
                    text: 'Select correct format',
                    icon: 'warning',
                    timer: 3000,
                    showConfirmButton: false,
                });
                return;
            }

            Papa.parse(selectedFile, {
                complete: (result) => {
                    setImportedData(result.data);
                    findDuplicates(result.data);
                    // findWrongEntries(result.data); // Call the function to find wrong entries
                    setfileData(result.data);
                    const fileData = result.data;

                    const fileCatids = fileData
                        .filter((item) => item['Registration Category ID'] !== undefined) // Filter out undefined values
                        .map((item) => String(item['Registration Category ID']));

                    const activeCatid = catid.map((Cat) => String(Cat.cs_reg_cat_id));

                    const wrongs = fileCatids.filter(
                        (catid) => !activeCatid.includes(catid)
                    );

                    // Filter out duplicates and wrong entries from fileData
                    const correctEntries = fileData.filter(
                        (item) =>
                            !wrongs.includes(item['Registration Category ID'])
                    );

                    setWrongEntries(wrongs);
                    setImportedData(correctEntries);


                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'File Uploaded successfully!',
                        icon: 'success',
                        timer: 3000,
                        showConfirmButton: false,
                    });
                },
                header: true
            });
        }
    };


    // useEffect(() => {
    //     fetchMandatory(); // Corrected function name
    // }, []);



    // const fetchMandatory = async () => {
    //     try {
    //         const response = await axios.get(`${BackendAPI}/manageuser/getMand`);
    //         setData(response.data);
    //         const data = response.data; // Extract data from response
    //         setData(data);
    //         setLoading(false);
    //         console.log('Mandatory Field:', data); // Log the data received from the API

    //         // Iterate over each object in the data array
    //         data.forEach(item => {
    //             const required = item.cs_is_required;
    //             const name = item.cs_field_label;

    //             console.log('Required:', required);
    //             console.log('Name:', name);
    //         });
    //     } catch (error) {
    //         console.error('Error fetching Mandatory Field:', error);
    //         setLoading(false);
    //     }
    // };


    // const findWrongEntries = (data) => {
    //     const correctEntries = [];
    //     const wrongEntries = [];

    //     data.forEach((entry) => {
    //         const registrationCategoryID = entry["Registration Category ID"];

    //         // Check if the registrationCategoryID exists in the catid array
    //         const matchFound = catid.some((cat) => cat === registrationCategoryID);

    //         if (matchFound && registrationCategoryID !== "" && !isNaN(parseInt(registrationCategoryID, 10))) {
    //             correctEntries.push(entry);
    //         } else {
    //             wrongEntries.push(entry);
    //         }
    //     });

    //     setWrongEntries(wrongEntries);
    //     setImportedData(correctEntries);
    // };

    // const findWrongEntries = (data) => {
    //     const wrongEntries = [];

    //     data.forEach((entry, index) => {
    //         let hasEmptyField = false;



    //         // // Check for empty fields and non-integer values only for specified columns
    //         // if (entry["Registration Number"] === "" || isNaN(parseInt(entry["Registration Number"], 10))) {
    //         //     hasEmptyField = true;
    //         // }

    //         if (entry["Registration Category ID"] === "" || isNaN(parseInt(entry["Registration Category ID"], 10))) {
    //             hasEmptyField = true;
    //         }

    //         if (hasEmptyField) {
    //             wrongEntries.push(entry);
    //         }
    //     });

    //     setWrongEntries(wrongEntries);
    // };






    const findDuplicates = (data) => {
        const duplicates = [];
        const headers = Object.keys(data[0]); // Assuming headers are in the first row

        // Find the index of the "Registration Number" column
        const registrationNumberIndex = headers.findIndex(header => header === "Registration Number");

        if (registrationNumberIndex !== -1) {
            const seen = {};
            data.forEach((entry, index) => {
                const value = entry[headers[registrationNumberIndex]];
                if (seen[value]) {
                    duplicates.push(entry);
                } else {
                    seen[value] = true;
                }
            });
        } else {
            console.error("Registration Number column not found.");
        }
        setDuplicateEntries(duplicates);
    };


    const handleImportCorrectedData = async () => {
        try {
            // Filter out wrong entries and duplicate entries
            const uniqueCorrectedData = importedData.filter(entry => {
                return entry && entry['First Name'] && entry['First Name'].trim() !== '';
            });

            console.log("Unique Corrected Data:", uniqueCorrectedData);
            const token = getToken(); 
            await axios.post(`${BackendAPI}/manageuser/addBulkUser`, uniqueCorrectedData, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            SweetAlert.fire({
                title: 'Success!',
                text: 'User correct data imported successfully !',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate("/User-listing/Consoft");
                }
            });
        } catch (error) {
            console.error('Error Importing :', error.message);
        }
    };



    return (
        <Container fluid>
            <Breadcrumbs mainTitle="Import Users" parent="Manage User" title="Import Users" />
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                            <div className="mb-2 mb-md-0">
                                <h5 className="mb-2 text-start">Import without Registration Number</h5>
                                <small>Your registration number will start from: {maxNumber}</small>
                            </div>
                            <div className=" mt-md-0">
                                <Button color='warning' type='button' className="mr-2 mb-2" onClick={handleImportCorrectedData}>
                                    Import Data
                                </Button>
                            </div>
                            <div className="mt-2 mt-md-0">
                                <Input
                                    type="file"
                                    accept=".csv,.xlsx"
                                    onChange={handleFileSelect}
                                    style={{ display: 'none' }}
                                    id="fileInput"
                                />
                                <label htmlFor="fileInput" className="btn btn-primary">Select File</label>
                            </div>
                            <div className="mb-2 mt-md-0">
                                <a href="/Sample.csv" className="btn btn-success " download="sample.csv">
                                    Sample File
                                </a>
                            </div>

                        </CardHeader>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Correct Entries
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Duplicate Entries
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Wrong Entries
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className='mt-3'>
                                    {wrongEntries.length > 0 ? (
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    {importedData.length > 0 &&
                                                        Object.keys(importedData[0]).map((header, index) => (
                                                            <th key={index}>{header}</th>
                                                        ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {importedData
                                                    // Filter out wrong entries and duplicate entries
                                                    .filter(row => !wrongEntries.includes(row) && !duplicateEntries.includes(row))
                                                    .map((row, index) => (
                                                        <tr key={index}>
                                                            {Object.values(row).map((cell, index) => (
                                                                <td key={index}>{cell}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                            <p>No Correct entries found.</p>
                                        )}
                                        
                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="mt-3">
                                        {duplicateEntries.length > 0 ? (
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        {Object.keys(duplicateEntries[0]).map((header, index) => (
                                                            <th key={index}>{header}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {duplicateEntries.map((row, index) => (
                                                        <tr key={index}>
                                                            {Object.values(row).map((cell, index) => (
                                                                <td key={index}>{cell}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <p>No duplicate entries found.</p>
                                        )}
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                        <div className="mt-3">
                                            {fileData &&
                                                fileData.length > 0 && ( // Check if fileData is not empty
                                                    <Table className="table">
                                                        <thead>
                                                            <tr>
                                                                {Object.keys(fileData[0]).map(
                                                                    (key, index) => (
                                                                        <th key={index}>{key}</th> // Generate table headers dynamically
                                                                    )
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {fileData
                                                                .filter((row) =>
                                                                    wrongEntries.includes(row['Registration Category ID'])
                                                                ) // Filter rows based on wrong entries
                                                                .map((rowData, rowIndex) => (
                                                                    <tr key={rowIndex}>
                                                                        {Object.values(rowData).map(
                                                                            (value, colIndex) => (
                                                                                <td key={colIndex}>{value}</td> // Generate table data cells
                                                                            )
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                                )
                                            }

                                            {!fileData ||
                                                fileData.length === 0 && ( // Check if fileData is empty
                                                    <p>No wrong entries found.</p>
                                                )
                                            }

                                        </div>
                                </TabPane>
                            </TabContent>

                            {/* <div className='text-end'>
                                <Button color='warning' type='button' className="mr-2 mt-3" onClick={handleImportCorrectedData}>
                                    Import Data
                                </Button>
                            </div> */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ImportUserWithoutReg;