import React, { ChangeEvent, useState } from 'react';
import { Container, Row, Col, Input, Label, Button, Card, CardBody, Modal, ModalHeader, FormFeedback, ModalBody, ModalFooter, Media } from 'reactstrap';
import { Img, PDF } from '../Utils/validationUtils';

const LoginForm = () => {
  const [name, setName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // Remove type annotation for null
  const [selectedPdf, setSelectedPdf] = useState(null); // Remove type annotation for null
  const [imageError, setImageError] = useState(''); // Remove type annotation for string
  const [pdfError, setPdfError] = useState(''); // Remove type annotation for string


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files && event.target.files[0];
    const errorMessage = Img(file);
    if (errorMessage) {
      setSelectedImage(null);
      setImageError(errorMessage);
    } else {
      setSelectedImage(file);
      setImageError('');
    }
  };

  const handlePdfChange = (event) => {
    const file = event.target.files && event.target.files[0];
    const errorMessage = PDF(file);
    if (errorMessage) {
      setSelectedPdf(null);
      setPdfError(errorMessage);
    } else {
      setSelectedPdf(file);
      setPdfError('');
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Access selected file names
    const imageName = selectedImage ? selectedImage.name : 'No image selected';
    const pdfName = selectedPdf ? selectedPdf.name : 'No PDF selected';

    // Log or use the selected file names as needed
    console.log('Selected image:', imageName);
    console.log('Selected PDF:', pdfName);

    // Alternatively, you can include them in your form submission payload
    // const formData = new FormData();
    // formData.append('name', name);
    // formData.append('image', selectedImage);
    // formData.append('pdf', selectedPdf);
    // Then, you can send formData to your backend or handle it accordingly.
  };


  return (
    <div>
      <h1>Upload File</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <Input type="text" value={name} onChange={handleNameChange} />
        </div>
        <div>
          <label>Image:</label>
          <input type="file" onChange={handleImageChange} />
          {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
          {selectedImage && <p>Selected image: {selectedImage.name}</p>}
        </div>

        <div>
          <label>Pdf:</label>
          <Input type="file" onChange={handlePdfChange} />
          {pdfError && <FormFeedback style={{ color: 'red' }}>{pdfError}</FormFeedback>}
          {selectedPdf && <p>Selected PDF: {selectedPdf.name}</p>}
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default LoginForm;