import React, { useState, useEffect } from 'react';
import { Input, Space, Checkbox, Divider, Table, Button, Dropdown, Menu, Tag, Modal, Form, message } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import Card from '../../Card/Card';
import { GoDotFill } from "react-icons/go";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BackendAPI } from '../../../api'; // Import Axios
import { Pagination } from 'antd';
import { SearchOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const UserList = () => {
  const [columns, setColumns] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [exportFormat, setExportFormat] = useState(); // Default export format
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BackendAPI}/user/getuser`);
      const fetchedColumns = [
        {
          title: 'Reg No.',
          dataIndex: 'cs_regno',
          key: 'cs_regno',
          sorter: true,
          sortOrder: 'ascend',
          hidden: false, // Initially all columns are visible
        },
        {
          title: 'First Name',
          dataIndex: 'cs_first_name',
          key: 'cs_first_name',
          sorter: false,
          hidden: false,
        },
        {
          title: 'Last Name',
          dataIndex: 'cs_last_name',
          key: 'cs_last_name',
          sorter: false,
          hidden: false,
        },
        {
          title: 'Registration Category',
          dataIndex: 'cs_reg_category',
          key: 'cs_reg_category',
          sorter: true,
          hidden: false,
        },
        {
          title: 'Email',
          dataIndex: 'cs_email',
          key: 'cs_email',
          sorter: true,
          hidden: false,
        },
        {
          title: 'Contact',
          dataIndex: 'cs_phone',
          key: 'cs_phone',
          sorter: true,
          hidden: false,
        },
        {
          title: 'City',
          dataIndex: 'cs_city',
          key: 'cs_city',
          sorter: true,
          hidden: false,
        },
        {
          title: 'Company',
          dataIndex: 'cs_company_name',
          key: 'cs_company_name',
          sorter: true,
          hidden: false,
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status, record) => (
            <>
              <Tag
                color={status === 0 ? 'green' : 'volcano'}
                onClick={() => handleStatus(record)}
                style={{ cursor: 'pointer', marginRight: '8px' }} // Added margin for spacing
              >
                {status === 0 ? <GoDotFill style={{ color: 'green' }} /> : <GoDotFill style={{ color: 'volcano' }} />}
              </Tag>
            </>
          ),
          sorter: false,
          filters: [
            { text: 'Active', value: 'Active' },
            { text: 'Inactive', value: 'Inactive' },
          ],
          onFilter: (value, record) => {
            if (value === 'Active') {
              return record.status === 0;
            } else if (value === 'Inactive') {
              return record.status !== 0;
            }
            return false;
          },
          hidden: false,
        },
        
        
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          render: (_, record) => (
            <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}>
              Edit
            </Button>
          ),
        },
      ];
      setColumns(fetchedColumns);
      setData(response.data);
      setCheckedList(fetchedColumns.filter(col => !col.hidden && col.key !== 'action').map(col => col.key)); // Exclude 'action' column
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleStatus = async (record) => {
    try {
    const newStatus = record.status === 0 ? 1 : 0; // Toggle status between 0 and 1
    const updatedData = data.map((user) => {
      if (user.id === record.id) {
        return { ...user, status: newStatus };
      }
      return user;
    });
    setData(updatedData); // Update local state
  
   
      await updateStatusInDatabase(record.id, newStatus); // Update status in the database
      message.success("Status updated successfully");
    } catch (error) {
      console.error("Error updating status:", error);
      message.error("Failed to update status");
    }
  };
  
  const updateStatusInDatabase = async (userId, newStatus) => {
    try {
      // Make an API call to update status in the database
      await axios.put(`${BackendAPI}/user/updateStatus/${userId}`, { status: newStatus });
      console.log('Status updated in the database');
    } catch (error) {
      console.error('Error updating status in the database:', error);
      // You may handle errors here as needed
      throw error; // Propagate error for error handling in handleStatus function
    }
  };
  


  const handleEdit = (record) => {
    console.log("Editing user:", record); // Log the record to inspect its contents
    setEditingUser(record);
    form.setFieldsValue(record);
    setEditModalVisible(true);
  };
  
  const handleSaveEdit = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = data.map((user) => {
        if (user.id === editingUser.id) { // Change user.key to user.id
          return { ...user, ...values };
        }
        return user;
      });
      setData(updatedData);
      setEditModalVisible(false);
      message.success("User data saved successfully");
      await updateUserInDatabase(editingUser.id, values); // Pass editingUser.id as userId
    } catch (error) {
      console.error("Error saving user data:", error);
      message.error("Failed to save user data");
    }
  };
  
  

  const updateUserInDatabase = async (userId, updatedUserData) => {
    try {
      // Make an API call to update user data in the database
      await axios.put(`${BackendAPI}/user/update/${userId}`, updatedUserData);
          console.log(userId);
      console.log('User data updated in the database');
    } catch (error) {
      console.error('Error updating user data in the database:', error);
      // You may handle errors here as needed
    }
  };
  
  const onChange = (pagination, filters, sorter) => {
    console.log('params', pagination, filters, sorter);
    const { columnKey, order } = sorter;

    const updatedColumns = columns.map((col) => ({
      ...col,
      sortOrder: col.key === columnKey ? order : null,
    }));

    setColumns(updatedColumns);

    let sortedData = searchText ? [...filteredData] : [...data]; // Use filteredData if search is active, otherwise use data

    if (order === 'ascend') {
      sortedData = sortedData.sort((a, b) => (a[columnKey] > b[columnKey] ? 1 : -1));
    } else if (order === 'descend') {
      sortedData = sortedData.sort((a, b) => (a[columnKey] < b[columnKey] ? 1 : -1));
    }

    if (!searchText) {
      setData(sortedData); // Update data state only if search is not active
    } else {
      setFilteredData(sortedData); // Update filteredData state if search is active
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter((user) =>
        Object.values(user).some(
            (field) =>
                (typeof field === 'string' && field.toLowerCase().includes(value.toLowerCase())) ||
                (field === 0 && 'Active'.toLowerCase().includes(value.toLowerCase())) ||
                (field === 1 && 'Inactive'.toLowerCase().includes(value.toLowerCase()))
        )
    );
    setFilteredData(filtered);
    setCurrentPage(1);
};





  const handleExport = (format) => {
    setExportFormat(format); // Set the export format first
    if (format === 'csv') {
      handleExportCSV();
    } else if (format === 'xlsx') {
      handleExportXLSX();
    }
  };

  const handleExportCSV = () => {
    // Determine which dataset to use based on whether search is active
    const exportData = searchText ? filteredData : data;

    // Modify status values
    const modifiedExportData = exportData.map(user => ({
        ...user,
        status: user.status === 0 ? 'Active' : 'Inactive'
    }));

    // Rename key from status to status
    const modifiedExportDataWithRenamedKeys = modifiedExportData.map(user => {
        const { status, ...rest } = user;
        return { status: status, ...rest };
    });

    // Filter userData based on checkedList (selected columns)
    const filteredUserData = modifiedExportDataWithRenamedKeys.map(user => {
        const filteredUser = {};
        checkedList.forEach(column => {
            filteredUser[column] = user[column];
        });
        return filteredUser;
    });

    // Extract column names from checkedList
    const columnNames = checkedList.map(key => {
        const column = columns.find(col => col.key === key);
        return column ? column.title : key; // Use column title if available, otherwise use key
    });

    // Create CSV content with column names as the first row
    const csvContent =
        "data:text/csv;charset=utf-8," +
        [columnNames.join(","), ...filteredUserData.map(user => checkedList.map(key => user[key]).join(","))].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "UserList.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
};

const handleExportXLSX = () => {
    // Prepare data for Excel export
    const exportData = searchText ? filteredData : data;

    // Modify status values
    const modifiedExportData = exportData.map(item => ({
        ...item,
        status: item.status === 0 ? 'Active' : 'Inactive'
    }));

    // Rename key from status to status
    const modifiedExportDataWithRenamedKeys = modifiedExportData.map(user => {
        const { status, ...rest } = user;
        return { status: status, ...rest };
    });

    // Prepare columns for export
    const exportColumns = columns.filter(col => checkedList.includes(col.key));

    // Convert data to worksheet
    const worksheetData = modifiedExportDataWithRenamedKeys.map(item => {
        const newItem = {};
        exportColumns.forEach(col => {
            newItem[col.key] = item[col.key];
        });
        return newItem;
    });
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    // Set column headers
    const header = exportColumns.map(col => col.title);
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });

    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'UserList');

    // Save the workbook
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(excelBlob, 'UserList.xlsx');
};

  

  const onCheckAllChange = (e) => {
    const checked = e.target.checked;
    setCheckedList(checked ? columns.map(col => col.key) : []);
    setIndeterminate(false);
    setCheckAll(checked);
    updateColumnsVisibility(checked ? columns.map(col => col.key) : []);
  };

  const updateColumnsVisibility = (checkedList) => {
    const updatedColumns = columns.map((col) => ({
      ...col,
      hidden: !checkedList.includes(col.key),
    }));
    setColumns(updatedColumns);
  };

  const onCheckboxChange = (checkedList) => {
    setCheckedList(checkedList);
    setIndeterminate(!!checkedList.length && checkedList.length < columns.length);
    setCheckAll(checkedList.length === columns.length);
    updateColumnsVisibility(checkedList);
  };

  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const menu = (
    <Menu>
      <Menu.ItemGroup title="Columns">
        {columns.map(col => (
          <Menu.Item key={col.key}>
            <Checkbox checked={!col.hidden} onChange={() => onMenuCheckboxChange(col.key)}>
              {col.title}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );

  const onMenuCheckboxChange = (key) => {
    const updatedColumns = columns.map((col) => ({
      ...col,
      hidden: col.key === key ? !col.hidden : col.hidden,
    }));
    setColumns(updatedColumns);
    setCheckedList(updatedColumns.filter(col => !col.hidden).map(col => col.key));
  };

  return (
    <div>
      <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Listing Page</h4>
              </div>
              <Space style={{ marginBottom: 16 }}>
                <Input
                  placeholder="Search entire table"
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <Dropdown overlay={menu}>
                  <Button>
                    Select Columns
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={(
                    <Menu>
                      <Menu.Item onClick={() => handleExport('csv')} key="csv">CSV</Menu.Item>
                      <Menu.Item onClick={() => handleExport('xlsx')} key="xlsx">XLSX</Menu.Item>
                    </Menu>
                  )}
                  trigger={['click']}
                >
                  <Button>
                    Export
                  </Button>
                </Dropdown>

              </Space>
            </Card.Header>
            <Card.Body className="px-0">
              <div className="table-responsive">
                <Table
                  columns={columns.filter(col => !col.hidden)}
                  dataSource={searchText ? filteredData : data}
                  onChange={onChange}
                  pagination={{
                    total: searchText ? filteredData.length : data.length,
                    showTotal: (total) => `Total ${total} items`,
                    // pageSize: usersPerPage,
                    // current: currentPage,
                    // onChange: handleItemsPerPageChange,
                    // showSizeChanger: true,
                    // onShowSizeChange: handleItemsPerPageChange,
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
      title="Edit User"
      visible={editModalVisible}
      onOk={handleSaveEdit}
      onCancel={() => setEditModalVisible(false)}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={editingUser}
      >
        <Form.Item
          name="cs_first_name"
          label="First Name"
          rules={[{ required: true, message: 'Please enter first name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cs_last_name"
          label="Last Name"
          rules={[{ required: true, message: 'Please enter last name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cs_phone"
          label="Contact"
          rules={[{ required: true, message: 'Please enter contact no.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cs_city"
          label="City"
          rules={[{ required: true, message: 'Please select city' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
  name="cs_company_name"
  label="Company"
  rules={[{ required: false }]} // Make cs_company_name optional
>
  <Input />
</Form.Item>

      </Form>
    </Modal>
    </div>
  );
};

export default UserList;
