import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Table, CardHeader, CardBody, Input, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import axios from 'axios';
import { BackendAPI } from '../../api';
import SweetAlert from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import { Pagination } from 'antd';
import CustomizerContext from '../../_helper/Customizer';
import { Tooltip } from 'react-tooltip';
import { classes } from '../../Data/Layouts';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';

const Workshop = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [catIds, setCatIds] = useState([]);
    const { layoutURL } = useContext(CustomizerContext);
    const [modal, setModal] = useState(false);
    const [statusModalOpen, setStatusModalOpen] = useState(false); // New state for modal visibility
    const [workshopIdToUpdate, setWorkshopIdToUpdate] = useState(null); // Workshop ID to update status
    const [deleteWorkshopDetails, setDeleteWorkshopDetails] = useState({ workshopId: null, facilityId: null }); // New state for delete workshop details
    const navigate = useNavigate();
    const { permissions } = useContext(PermissionsContext);

    useEffect(() => {
        fetchWorkshop();
    }, [currentPage, pageSize, searchText, permissions]);

    // Extract Workshops component
    const WorkshopsPermissions = permissions['Workshops'];

    const fetchWorkshop = async () => {
        try {
            const token = getToken(); 
            const Response = await axios.get(`${BackendAPI}/workshop/getWorkshop?page=${currentPage}&pageSize=${pageSize}&search=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            console.log(Response.data);
            setData(Response.data.categories);
            setTotalItems(Response.data.totalItems);
            setCatIds(catIds);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleEdit = (workshopId, workshopname) => {
        const URL = '/Edit-Workshop/';
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { workshopId, workshopname } });
    };

    const handleDelete = async () => {
        try {
            const { workshopId, facilityId } = deleteWorkshopDetails;
            const token = getToken(); 
            await axios.delete(`${BackendAPI}/workshop/deleteworkshop`, { data: { workshopId, facilityId } }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            SweetAlert.fire({
                title: 'Success!',
                text: 'Workshop removed successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate("/Workshop/Consoft");
                }
            });
            fetchWorkshop(); // Fetch updated workshop list
        } catch (error) {
            console.error('Error deleting workshop:', error);
        }
        setModal(false); // Close modal after deletion
    };

    const openStatusModal = (workshopId) => {
        setWorkshopIdToUpdate(workshopId);
        setStatusModalOpen(true);
    };

    const closeStatusModal = () => {
        setStatusModalOpen(false);
    };

    const handleStatusUpdate = async () => {
        const workshopId = workshopIdToUpdate;
        const currentWorkshop = data.find(workshop => workshop.cs_workshop_id === workshopId);
        if (!currentWorkshop) return;
        const currentStatus = currentWorkshop.cs_status;
        const newStatus = currentStatus === 0 ? 1 : 0;
        try {
            const token = getToken(); 
            await axios.put(`${BackendAPI}/workshop/UpdateStatus`, { workshopId, status: newStatus, Name: currentWorkshop.cs_workshop_name }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const updatedWorkshops = data.map((workshop) =>
                workshop.cs_workshop_id === workshopId ? { ...workshop, cs_status: newStatus } : workshop
            );
            setData(updatedWorkshops);
        } catch (error) {
            console.error('Error updating status:', error);
        }
        closeStatusModal(); // Close modal after status update
    };

    const openDeleteModal = (workshopId, facilityId) => {
        setDeleteWorkshopDetails({ workshopId, facilityId });
        setModal(true);
    };

    const closeDeleteModal = () => {
        setModal(false);
    };

    const handleNavigation = () => {
        navigate(`${process.env.PUBLIC_URL}/Add-Workshop/Consoft`);
      };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Manage Workshop" parent="Onsite App" title="Manage Workshop" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-start">Workshops</h5>
                                    <Input
                                        placeholder="Search Workshop"
                                        onChange={e => handleSearch(e.target.value)}
                                        style={{ width: 200 }}
                                    />
                                </div>
                                {WorkshopsPermissions?.add === 1 && (
                                    <button onClick={handleNavigation} className="btn btn-warning">
                                    Create Workshop
                                  </button>
                                )}
                            </CardHeader>
                            <CardBody>
                                <div className='table-responsive'>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr className='border-bottom-primary'>
                                                    <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                    <th scope='col' className='text-start'>{'Workshop Name'}</th>
                                                    <th scope='col' className='text-center'>{'Status'}</th>
                                                    {WorkshopsPermissions?.edit === 1 || WorkshopsPermissions?.delete === 1 ? (
                                                        <th scope='col' className='text-end'>Action</th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index} className="border-bottom-primary">
                                                        <td className='text-start'>{index + 1}</td>
                                                        <td className='text-start'>{item.cs_workshop_name}</td>
                                                        <td className='text-center'>
                                                            {item.cs_status === 0 ? (
                                                                <span style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_workshop_id)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{ color: 'green', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_workshop_id)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                            <Tooltip id="tooltip" globalEventOff="click" />
                                                        </td>
                                                        {WorkshopsPermissions?.edit === 1 || WorkshopsPermissions?.delete === 1 ? (
                                                            <td className='text-end'>
                                                                <Tooltip id="tooltip" globalEventOff="click" />
                                                                {WorkshopsPermissions?.edit === 1 && (
                                                                    <Button color="primary" size="sm" onClick={() => handleEdit(item.cs_workshop_id, item.cs_workshop_name)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Edit Details"
                                                                        data-tooltip-event="click focus" >
                                                                        <FaEdit />
                                                                    </Button>
                                                                )}
                                                                {WorkshopsPermissions?.delete === 1 && (
                                                                    <Button color="danger" size="sm" onClick={() => openDeleteModal(item.cs_workshop_id, item.cs_facility_id)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Delete Workshop"
                                                                        data-tooltip-event="click focus" >
                                                                        <MdDelete />
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        ) : null}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                                {totalItems > 10 && (
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <Pagination
                                            onChange={handlePageChange}
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            showSizeChanger={true}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={statusModalOpen} toggle={closeStatusModal}  size="md">
                <ModalHeader toggle={closeStatusModal}>Confirmation</ModalHeader>
                <ModalBody>
                    Are you sure you want to change the status ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleStatusUpdate}>Yes</Button>
                    <Button color="warning" onClick={closeStatusModal}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={modal} toggle={closeDeleteModal}  size="md">
                <ModalHeader toggle={closeDeleteModal}>Confirm</ModalHeader>
                <ModalBody>Are you sure you want to delete this Workshop?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleDelete}>Yes</Button>
                    <Button color="warning" onClick={closeDeleteModal}>No</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default Workshop;
