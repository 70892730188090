import React, { useState ,useEffect} from 'react';
import axios from 'axios';
import './AddFieldForm.css';
import { BackendAPI } from '../../../api';
import { getToken } from '../../../Auth/Auth';

// const AddFieldForm = ({ addFieldAsComponent, toggleForm }) => {
//   const [formFields, setFormFields] = useState([]);

//   useEffect(() => {
//       fetchFormFields();
//   }, []);

//   const fetchFormFields = async () => {
//       try {
//           const response = await axios.get(`${BackendAPI}/badge/formfields`);
//           setFormFields(response.data.data);
//       } catch (error) {
//           console.error('Error fetching form fields:', error);
//       }
//   };

//   const handleAddFieldClick = (field) => {
//       addFieldAsComponent(field);
//       toggleForm(); // Close the popup/modal after adding the field
//   };

//   return (
//       <div className="addFieldFormContainer">
//           <h1>Add Fields</h1>
//           <div className="fieldList">
//               {formFields.map((field) => (
//                   <div key={field.cs_field_id} className="fieldItem">
//                       <span>{field.cs_field_label}</span>
//                       <button className="addFieldButton" onClick={() => handleAddFieldClick(field)}>Add</button>
//                   </div>
//               ))}
//           </div>
//       </div>
//   );
// };
const AddFieldForm = ({ addFieldAsComponent }) => {
    const [formFields, setFormFields] = useState([]);

    useEffect(() => {
        fetchFormFields();
    }, []);

    const fetchFormFields = async () => {
        try {
            const token = getToken(); 
            const response = await axios.get(`${BackendAPI}/badge/formfields`,{
                headers: {
                                Authorization: `Bearer ${token}` // Include the token in the Authorization header
                            }
                        });
            setFormFields(response.data.data);
        } catch (error) {
            console.error('Error fetching form fields:', error);
        }
    };

    const handleAddFieldClick = (field) => {
        addFieldAsComponent(field);
    };

    return (
        <div className="addFieldFormContainer">
            <h4>Add Form Fields</h4>
            <div className="fieldList">
                {formFields.map((field) => (
                    <div key={field.cs_field_id} className="fieldItem">
                        <span>{field.cs_field_label}</span>
                        <button className="addFieldButton" onClick={() => handleAddFieldClick(field)}>Add</button>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default AddFieldForm;


