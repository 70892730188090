import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, Table, CardHeader, Button, CardBody, Media, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { GoDotFill } from "react-icons/go";
import { Link, useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import CustomizerContext from '../../_helper/Customizer';
import axios from 'axios';
import { BackendAPI } from '../../api';
import { BackendPath } from '../../api';
import { Pagination } from 'antd';
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from 'react-tooltip';
import SweetAlert from 'sweetalert2';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';
import { PermissionsContext } from '../../contexts/PermissionsContext';

const Facility = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [catid, setCatid] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);
    const [selectedDaywiseStatus, setSelectedDaywiseStatus] = useState('');
    const [modal, setModal] = useState(false);
    const [deleteFacName, setDeleteFacName] = useState('');
    const [deleteFacId, setDeleteFacId] = useState(null);
    const navigate = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    const { permissions } = useContext(PermissionsContext);
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);


    console.log("Before", data);



    useEffect(() => {
        fetchRoles();
    }, [currentPage, pageSize, searchText, permissions]);

    // Extract Facility component
    const FacilityPermissions = permissions['Facility'];

    const fetchRoles = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/user/getfacility?page=${currentPage}&pageSize=${pageSize}&search=${searchText}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            setData(response.data.facilities);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalItems);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching roles:', error);
            setLoading(false);
        }
    };

    const handlePageChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
        fetchRoles();
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    useEffect(() => {
        const fetchCatId = async () => {
            setLoading(true);
            try {
                const token = getToken();
                const response = await axios.get(`${BackendAPI}/field/getCatId`, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
                setCatid(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching users:", error);
                setLoading(false);
            }
        };

        fetchCatId();
    }, []);

    const toggleModal = (facilityId, daywiseStatus) => {
        setSelectedFacilityId(facilityId);
        setSelectedDaywiseStatus(daywiseStatus);
        setModalOpen(!modalOpen);
    };

    const handleDaywiseToggle = async (facilityId, newDaywiseStatus) => {
        try {
            const token = getToken();
            await axios.put(`${BackendAPI}/user/UpdateDaywise`, { id: facilityId, daywise: newDaywiseStatus }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const updatedFacilities = data.map((facility) =>
                facility.cs_facility_id === facilityId ? { ...facility, cs_daywise: newDaywiseStatus } : facility
            );
            setData(updatedFacilities);
        } catch (error) {
            console.error('Error updating daywise status:', error);
        }
    };

    const editFacility = (facilityId) => {
        const URL = '/Edit-facility/';
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`, { state: { facilityId } });
    };


    const openStatusModal = (FacId, currentStatus, facilityName) => {
        setDeleteFacId(FacId);
        setCurrentStatus(currentStatus);
        setDeleteFacName(facilityName);
        setStatusModalOpen(true);
    };

    const closeStatusModal = () => {
        setDeleteFacId(null);
        setCurrentStatus(null);
        setDeleteFacName(null);
        setStatusModalOpen(false);
    };

    const handleStatusUpdate = async (cs_facility_id, currentStatus, cs_display_name) => {
        // Calculate the new status based on the current status
        const newStatus = currentStatus === 0 ? 1 : 0;
        try {
            const token = getToken();
            // Update the status via API call
            const response = await axios.put(`${BackendAPI}/user/UpdateStatus`, { id: cs_facility_id, status: newStatus, Name: cs_display_name }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
    
            // Check if the response status is 200 before updating the state
            if (response.status === 200) {
                // Use a functional update to ensure we're working with the latest state
                setData(prevData => {
                    const updatedFacilities = prevData.map((facility) =>
                        facility.cs_facility_id === cs_facility_id ? { ...facility, cs_status: newStatus } : facility
                    );
                    return updatedFacilities;
                });
            } else {
                console.error('Failed to update status: response status not 200');
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    useEffect(() => {
        console.log("After update:", data);
    }, [data]);
    
    


    const handleDelete = async (facilityId) => {
        try {
            const token = getToken();
            await axios.delete(`${BackendAPI}/user/deleteFacility/${facilityId}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            SweetAlert.fire({
                title: 'Success!',
                text: 'Facility removed successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate("/manage-facility/Consoft");
                }
            });
        } catch (error) {
            console.error('Error deleting facility:', error);
        }
    };

    const openDeleteModal = (facilityId, facilityName) => {
        setDeleteFacId(facilityId);
        setDeleteFacName(facilityName); // Set the facility name when opening the modal
        setModal(true);
    };

    const closeDeleteModal = () => {
        setDeleteFacId(null);
        setDeleteFacName('');  // Reset the facility name
        setModal(false);
    };

    const handleCreateFacilityClick = () => {
        if (catid.length === 0) {
            SweetAlert.fire({
                title: 'Warning!',
                text: 'Create category first',
                icon: 'question',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate(`${process.env.PUBLIC_URL}/Categories/Consoft`);
                }
            });
        } else {
            navigate(`${process.env.PUBLIC_URL}/create-facility/Consoft`);
        }
    };




    return (
        <Fragment>
            <Breadcrumbs mainTitle="Manage Facility" parent="Onsite App" title="Manage Facility" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2">Facilities</h5>
                                    <Input
                                        placeholder="Search all columns"
                                        onChange={e => handleSearch(e.target.value)}
                                        style={{ width: 200 }}
                                    />

                                </div>
                                {FacilityPermissions?.add === 1 && (
                                    <div className="mt-2 mt-md-0">
                                        <Button color="warning" onClick={handleCreateFacilityClick}>Create Facility</Button>
                                    </div>
                                )}

                            </CardHeader>




                            <CardBody>

                                <div className='table-responsive'>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr className='border-bottom-primary'>
                                                    <th scope='col' className='text-start'>{'Sr No.'}</th>
                                                    <th scope='col'>{'Display Name'}</th>
                                                    <th scope='col'>{'Type'}</th>
                                                    <th scope='col'>{'Icon'}</th>
                                                    <th scope='col'>{'Daywise'}</th>
                                                    <th scope='col' className='text-center'>{'Status'}</th>
                                                    {FacilityPermissions?.edit === 1 || FacilityPermissions?.delete === 1 ? (
                                                        <th scope='col' className='text-end'>{'Action'}</th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index} className="border-bottom-primary">
                                                        <td className='text-start'>{(currentPage - 1) * pageSize + index + 1}</td> {/* Calculate the serial number based on the current page and page size */}
                                                        <td>{item.cs_display_name}</td>
                                                        <td>{item.cs_type}</td>
                                                        <td >
                                                            <img
                                                                src={`${BackendPath}${item.cs_logo_image_url}`}
                                                                alt="Logo"
                                                                className="img-fluid mr-2 "
                                                                style={{ maxWidth: '30px' }} // Set the maximum width dynamically
                                                            />
                                                            <img
                                                                src={`${BackendPath}${item.cs_logo_darkmode_image_url}`}
                                                                alt="Logo"
                                                                className="img-fluid mr-2 bg-dark"
                                                                style={{ maxWidth: '30px' }} // Set the maximum width dynamically
                                                            />
                                                            {/* <img src={item.logo_darkmode_image_url} alt="Logo" className="img-fluid" style={{ maxWidth: '30px', backgroundColor: 'black' }} /> */}

                                                        </td>

                                                        <td>
                                                            <Media body className="icon-state switch-sm">
                                                                <Label className="switch">
                                                                    <Input
                                                                        type="checkbox"
                                                                        checked={item.cs_daywise === 'Yes'}
                                                                        onChange={() => {
                                                                            if (item.cs_type !== 'workshop') {
                                                                                toggleModal(item.cs_facility_id, item.cs_daywise);
                                                                            }
                                                                        }}
                                                                        // disabled={item.cs_type === 'workshop'}
                                                                        onClick={() => {
                                                                            if (item.cs_type === 'workshop') {
                                                                                toast('Daywise toggle is disabled for Workshop');
                                                                            }
                                                                        }}
                                                                    />



                                                                    <span className={"switch-state " + (item.cs_daywise === 'Yes' ? "bg-success" : "bg-danger")}></span>
                                                                </Label>
                                                            </Media>
                                                        </td>

                                                        <td className='text-center'>
                                                            {item.cs_status === 0 ? (
                                                                <span style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_facility_id, item.cs_status, item.cs_display_name)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{ color: 'green', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_facility_id, item.cs_status, item.cs_display_name)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                        </td>


                                                        {/* <td className='text-center'>
                                                            {item.cs_status === 0 ? (
                                                                <span style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_facility_id, item.cs_status, item.cs_display_name)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span style={{ color: 'green', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_facility_id, item.cs_status, item.cs_display_name)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus">
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                        </td> */}

                                                        {/* <td className='text-center'>
                                                            {item.cs_status === 0 ? (
                                                                <span
                                                                    style={{ color: 'red', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_facility_id, item.cs_status, item.cs_display_name)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Inactive status"
                                                                    data-tooltip-event="click focus"
                                                                >
                                                                    <GoDotFill />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{ color: 'green', fontSize: '20px', cursor: "pointer" }}
                                                                    onClick={() => openStatusModal(item.cs_facility_id, item.cs_status, item.cs_display_name)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Active status"
                                                                    data-tooltip-event="click focus"
                                                                >
                                                                    <GoDotFill />
                                                                </span>
                                                            )}
                                                            <Tooltip id="tooltip" globalEventOff="click" />
                                                        </td> */}


                                                        {FacilityPermissions?.edit === 1 || FacilityPermissions?.delete === 1 ? (
                                                            <td className='text-end'>
                                                                {FacilityPermissions?.edit === 1 && (
                                                                    <Button color="primary" size="sm" onClick={() => editFacility(item.cs_facility_id)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Edit Facility"
                                                                        data-tooltip-event="click focus"
                                                                    >
                                                                        <FaEdit /> {/* Key icon inside the button */}
                                                                    </Button>
                                                                )}
                                                                {FacilityPermissions?.delete === 1 && (
                                                                    <Button color="danger" size="sm" onClick={() => openDeleteModal(item.cs_facility_id, item.cs_display_name)}
                                                                        data-tooltip-id="tooltip"
                                                                        data-tooltip-content="Delete Facility"
                                                                        data-tooltip-event="click focus" >
                                                                        <MdDelete /> {/* Delete icon inside the button */}
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        ) : null}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    )}
                                </div>

                                {totalItems > 10 && (
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                        <Pagination
                                            onChange={handlePageChange}
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={totalItems}
                                            showSizeChanger={true}
                                        />
                                    </div>
                                )}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />


            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Confirmation: Daywise Status Change</ModalHeader>
                <ModalBody>
                    <div>
                        <p>Are you sure you want to change the daywise status?</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        handleDaywiseToggle(selectedFacilityId, selectedDaywiseStatus === 'Yes' ? 'No' : 'Yes');
                        setModalOpen(false);
                    }}>Yes</Button>
                    <Button color="warning" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>


            {/* Delete Confirmation Modal */}
            <Modal isOpen={modal} toggle={closeDeleteModal} size="md">
                <ModalHeader toggle={closeDeleteModal}>Confirmation: Delete Facility</ModalHeader>
                <ModalBody>
                    <div>
                        <p>Are you sure you want to delete {deleteFacName} facility ?</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleDelete(deleteFacId)}>Yes</Button>
                    <Button color="warning" onClick={closeDeleteModal}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Status Change Confirmation Modal */}
            <Modal isOpen={statusModalOpen} toggle={closeStatusModal}>
                <ModalHeader toggle={closeStatusModal}>Confirmation: Status Change</ModalHeader>
                <ModalBody>
                    <div>
                        <p>Are you sure you want to change the status for {deleteFacName} facility ? </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        handleStatusUpdate(deleteFacId, currentStatus, deleteFacName);
                        closeStatusModal();
                    }}>
                        Yes
                    </Button>
                    <Button color="warning" onClick={closeStatusModal}>No</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default Facility;
