import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';

const PX_TO_MM = 25.4 / 96;

const generatePDFFromBadgeListforList = async (badgeList) => {
    console.log('Badge List on generatePDFFromBadgeListforList:', badgeList);
    try {
        // Assuming the first badge's dimensions represent the page size
        const firstBadgeData = badgeList[0];
        console.log('Width:', firstBadgeData.width);
        console.log('Height:', firstBadgeData.height);
        const { width, height, orientation, badge_fields } = firstBadgeData;
        const numericWidth = parseFloat(width) * PX_TO_MM;
        const numericHeight = parseFloat(height) * PX_TO_MM;


        // console.log(`Page width: ${numericWidth} mm, Page height: ${numericHeight} mm`);
        // const lowercaseOrientation = orientation;

        const pdf = new jsPDF({
            orientation: orientation, // or 'portrait' for portrait orientation
            unit: 'mm', // using millimeters as the unit
            format: [numericWidth, numericHeight] // set the page size (width, height) in millimeters
        });

        pdf.deletePage(1);
        for (let i = 0; i < badgeList.length; i++) {
            const badgeData = badgeList[i];
            const { badge_fields, orientation } = badgeData;

            // pdf.addPage([numericWidth, numericHeight]);
            pdf.addPage({
                // format: [numericWidth, numericHeight],
                // orientation: lowercaseOrientation
            });

            const backgroundImageField = badge_fields.find(field => field.cs_field_type_id === 'backgroundimage');
            if (backgroundImageField) {
                const backgroundImageUrl = backgroundImageField.cs_field_content;
                console.log('Adding backgroundImageUrl:', backgroundImageUrl);
                const backgroundImageX = parseFloat(backgroundImageField.cs_field_position_x / 3.78);
                const backgroundImageY = parseFloat(backgroundImageField.cs_field_position_y / 3.78);
                const pageWidth = parseFloat(backgroundImageField.cs_field_width / 3.78);
                const pageHeight = parseFloat(backgroundImageField.cs_field_height / 3.78);
                const imageData = await loadImageAsBase64(backgroundImageUrl);
                pdf.addImage(imageData, 'JPEG', backgroundImageX, backgroundImageY, pageWidth, pageHeight);
            }
            // Render elements (text, images, QR codes, etc.) for the current badge
            await Promise.all(badge_fields.map(async (field) => {

                switch (field.cs_field_type_id) {

                    // case 'text':
                    // case 'number':
                    //     let fontSize = parseFloat(field.cs_text_size);
                    //     const containerX = parseFloat(field.cs_field_position_x) / 3.78;
                    //     const containerY = parseFloat(field.cs_field_position_y) / 3.78;
                    //     const containerWidth = parseFloat(field.cs_field_width) / 3.78;
                    //     const containerHeight = parseFloat(field.cs_field_height) / 3.78;

                    //     // Set the font size
                    //     pdf.setFontSize(fontSize);

                    //     // Calculate the text position based on the top-left corner of the container
                    //     const textX = containerX;
                    //     const textY = containerY + fontSize; // Adjusted for baseline

                    //     // Add the text to the PDF at the calculated position
                    //     pdf.text(textX, textY, field.cs_field_name);

                    //     break;
                    case 'text':
                    case 'number':
                        let fontSize = parseFloat(field.cs_text_size);
                        const containerX = parseFloat(field.cs_field_position_x) / 3.78;
                        const containerY = parseFloat(field.cs_field_position_y) / 3.78;
                        const containerWidth = parseFloat(field.cs_field_width) / 3.78;
                        const containerHeight = parseFloat(field.cs_field_height) / 3.78;

                        // Set the font size
                        pdf.setFontSize(fontSize);

                        // Calculate the center of the container
                        const centerX = containerX + containerWidth / 2;
                        const centerY = containerY + containerHeight / 2;

                        // Get the width of the text
                        const textWidth = pdf.getStringUnitWidth(field.cs_field_name) * fontSize / pdf.internal.scaleFactor;

                        // Calculate the starting point of the text to position it at the center
                        const textX = centerX - (textWidth / 2); // Adjusting for text width
                        const textY = centerY + (fontSize / 4); // Adjusting for font size and baseline

                        // Add the text to the PDF at the calculated position
                        pdf.text(textX, textY, field.cs_field_name);
                        break;


                    // case 'customtext': // Add support for customtext
                    //     let customFontSize = parseFloat(field.cs_text_size);
                    //     const customContainerX = parseFloat(field.cs_field_position_x) / 3.78;
                    //     const customContainerY = parseFloat(field.cs_field_position_y) / 3.78;
                    //     const customcontainerWidth = parseFloat(field.cs_field_width) / 3.78;
                    //     const customcontainerHeight = parseFloat(field.cs_field_height) / 3.78;

                    //     pdf.setFontSize(customFontSize);

                    //     const customTextX = customContainerX;
                    //     const customTextY = customContainerY + customFontSize;

                    //     pdf.text(customTextX, customTextY, field.cs_field_label);
                    //     break;
                    case 'customtext': // Add support for customtext
                        let customFontSize = parseFloat(field.cs_text_size);
                        const customContainerX = parseFloat(field.cs_field_position_x) / 3.78;
                        const customContainerY = parseFloat(field.cs_field_position_y) / 3.78;
                        const customcontainerWidth = parseFloat(field.cs_field_width) / 3.78;
                        const customcontainerHeight = parseFloat(field.cs_field_height) / 3.78;

                        // Set the font size
                        pdf.setFontSize(customFontSize);

                        // Calculate the center of the container
                        const customCenterX = customContainerX + customcontainerWidth / 2;
                        const customCenterY = customContainerY + customcontainerHeight / 2;

                        // Get the width of the text
                        const customTextWidth = pdf.getStringUnitWidth(field.cs_field_label) * customFontSize / pdf.internal.scaleFactor;

                        // Calculate the starting point of the text to position it at the center
                        const customTextX = customCenterX - (customTextWidth / 2); // Adjusting for text width
                        const customTextY = customCenterY + (customFontSize / 4); // Adjusting for font size and baseline

                        // Add the text to the PDF at the calculated position
                        pdf.text(customTextX, customTextY, field.cs_field_label);
                        break;

                    // case 'fullname':

                    //     let fullNameFontSize = parseFloat(field.cs_text_size);
                    //     const fullNameContainerX = parseFloat(field.cs_field_position_x) / 3.78;
                    //     const fullNameContainerY = parseFloat(field.cs_field_position_y) / 3.78;
                    //     const fullNamecontainerWidth = parseFloat(field.cs_field_width) / 3.78;
                    //     const fullNamecontainerHeight = parseFloat(field.cs_field_height) / 3.78;

                    //     pdf.setFontSize(fullNameFontSize);

                    //     const fullNameTextX = fullNameContainerX;
                    //     const fullNameTextY = fullNameContainerY + fullNameFontSize;

                    //     pdf.text(fullNameTextX, fullNameTextY, field.cs_field_name);
                    //     break;
                    case 'fullname':
                        let fullNameFontSize = parseFloat(field.cs_text_size);
                        const fullNameContainerX = parseFloat(field.cs_field_position_x) / 3.78;
                        const fullNameContainerY = parseFloat(field.cs_field_position_y) / 3.78;
                        const fullNamecontainerWidth = parseFloat(field.cs_field_width) / 3.78;
                        const fullNamecontainerHeight = parseFloat(field.cs_field_height) / 3.78;

                        // Set the font size
                        pdf.setFontSize(fullNameFontSize);

                        // Calculate the center of the container
                        const fullNameCenterX = fullNameContainerX + fullNamecontainerWidth / 2;
                        const fullNameCenterY = fullNameContainerY + fullNamecontainerHeight / 2;

                        // Get the width of the text
                        const fullNameTextWidth = pdf.getStringUnitWidth(field.cs_field_name) * fullNameFontSize / pdf.internal.scaleFactor;

                        // Calculate the starting point of the text to position it at the center
                        const fullNameTextX = fullNameCenterX - (fullNameTextWidth / 2); // Adjusting for text width
                        const fullNameTextY = fullNameCenterY + (fullNameFontSize / 4); // Adjusting for font size and baseline

                        // Add the text to the PDF at the calculated position
                        pdf.text(fullNameTextX, fullNameTextY, field.cs_field_name);
                        break;


                    case 'image':
                        const imageX = parseFloat(field.cs_field_position_x / 3.78);
                        const imageY = parseFloat(field.cs_field_position_y / 3.78);
                        const imageWidth = parseFloat(field.cs_field_width / 3.78);
                        const imageHeight = parseFloat(field.cs_field_height / 3.78);
                        const imageUrl = field.cs_field_content;
                        console.log('Adding image:', imageUrl);
                        const imageData = await loadImageAsBase64(imageUrl);
                        // console.log('Image data:', imageData);
                        pdf.addImage(imageData, 'JPEG', imageX, imageY, imageWidth, imageHeight);
                        break;
                    case 'qr':
                        const qrData = field.cs_field_name;
                        const qrX = field.cs_field_position_x / 3.78;
                        const qrY = field.cs_field_position_y / 3.78;
                        const qrSize = 50;
                        const qrImage = await QRCode.toDataURL(qrData, { width: qrSize, height: qrSize });
                        pdf.addImage(qrImage, 'PNG', qrX, qrY, qrSize, qrSize);
                        break;
                    default:
                        break;
                }
            }));
        }

        // Save the PDF document
        pdf.save('badges.pdf');
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};

const loadImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl);
    if (!response.ok) {
        throw new Error('Failed to load image');
    }
    const contentType = response.headers.get('content-type');
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        if (contentType.startsWith('image/svg')) {
            // For SVG images, convert to base64 directly
            reader.readAsDataURL(blob);
        } else {
            // For other image types (PNG, JPEG), convert to base64 after creating an Image object
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const base64ImageData = canvas.toDataURL(contentType);
                resolve(base64ImageData);
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(blob);
        }
    });
};
// Function to load image as base64 remains the same

export default generatePDFFromBadgeListforList;
