import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Input, Label, Button, Card, CardBody, Modal, ModalHeader, FormFeedback, ModalBody, ModalFooter, Media } from 'reactstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { BackendAPI } from '../../api';
import SweetAlert from 'sweetalert2';
// import { Input } from 'antd';
import { Breadcrumbs } from '../../AbstractElements';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Field, Form } from 'react-final-form'; // Import Field and Form from react-final-form
import { required, email, Name, Img, PDF } from '../Utils/validationUtils';
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';
import debounce from 'lodash.debounce';




//Utility function used to combine multiple validation functions into a single validation function
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const EditFacility = () => {
    useAuth();
    const [FacilityName, setFacilityName] = useState('');
    const [newRoleDescription, setNewRoleDescription] = useState('');
    const [isChecked, setIsChecked] = useState([]); // Initialize isChecked state
    const [files, setFiles] = useState({ brightModeIcon: '', darkModeIcon: '' });
    const [data, setData] = useState([]);
    const navigate = useNavigate(); // Initialize useHistory
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const initialValue = '';
    const [selectedFacilityType, setSelectedFacilityType] = useState(initialValue);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [imageError, setImageError] = useState('');
    const [pdfError, setPdfError] = useState('');
    const location = useLocation();
    const { facilityId } = location.state;
    const [currentName, setCurrentName] = useState(''); // Add currentName state
    const [name, setName] = useState('');
    const [nameValidationMessage, setNameValidationMessage] = useState('');
    const [nameTouched, setNameTouched] = useState(false);



    useEffect(() => {
        fetchfacility(facilityId); // Pass facilityId to fetchfacility
    }, [facilityId]); // Add facilityId as a dependency

    const fetchfacility = async (facilityId) => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/user/editfacility/${facilityId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setData(response.data);
            setCurrentName(response.data[0].cs_display_name); // Set the current name
            setLoading(false);
        } catch (error) {
            console.error('Error fetching facility:', error);
            setLoading(false);
        }
    };



    // Filter out "foodviewallaccess" type
    const uniqueTypes = [...new Set(data.map(item => item.cs_type))].filter(type => type !== 'foodviewallaccess', '');

    // Update onFilesChange function to store file objects
    const onFilesChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            setFiles(prevFiles => ({ ...prevFiles, [type]: file }));
        }
    };

    const onFilesError = (error, file) => {
        console.error('error', error);
    };

    const handleCancel = () => {
        setModal(true);
    };

    const onSubmit = async (formData, event) => {
        try {

            // formData.facilityType = selectedFacilityType.value;
            const id = facilityId;

            // Create a new FormData object to hold form data and files
            const formDataToSend = new FormData();

            // Append form data fields to FormData
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });

            // Append files to FormData
            formDataToSend.append('brightModeIcon', files.brightModeIcon);
            formDataToSend.append('darkModeIcon', files.darkModeIcon);
            formDataToSend.append('Facility_id', id);


            // Now you can send formDataToSend to the server

            // Log the data being sent
            console.log("Data to be sent:", id);

            // console.log(formDataToSend);
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/user/updateFacility`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            SweetAlert.fire({
                title: 'Success!',
                text: 'Facility added successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate("/manage-facility/Consoft");
                }
            });

        } catch (error) {
            console.error('Error creating facility:', error.message);
        }
    };



    const handleImageChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            setFiles(prevFiles => ({ ...prevFiles, [type]: file }));
        }
        const errorMessage = Img(file); // Implement validateImageFile function
        if (errorMessage) {
            setSelectedImage(null);
            setImageError(errorMessage);
        } else {
            setSelectedImage(file);
            setImageError('');
        }
    };

    const handlePdfChange = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            setFiles(prevFiles => ({ ...prevFiles, [type]: file }));
        }
        const errorMessage = Img(file); // Implement validateDocumentFile function
        if (errorMessage) {
            setSelectedPdf(null);
            setPdfError(errorMessage);
        } else {
            setSelectedPdf(file);
            setPdfError('');
        }
    };

    const validatename = debounce(async (value) => {
        if (value === currentName) {
            // Skip validation if the name is the same as the current name
            setNameValidationMessage('');
            return;
        }

        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/login/check-facility-name`, { dName: value }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.data.available) {
                setNameValidationMessage('Facility name already exists');
            } else {
                setNameValidationMessage('');
            }
        } catch (error) {
            console.error('Error checking username availability:', error);
            setNameValidationMessage('Error checking name availability');
        }
    }, 500);


    useEffect(() => {
        if (nameTouched) {
            validatename(name);
        }
        return () => {
            validatename.cancel();
        };
    }, [name, nameTouched]);



    const initialValues = {
        dName: data.length > 0 ? data[0].cs_display_name : '',
        facilityType: data.length > 0 ? data[0].cs_type : ''
        // brightmode: data.length > 0 ? data[0].cs_logo_image_url : '',
        // darkmode: data.length > 0 ? data[0].cs_logo_darkmode_image_url : ''
    };




    return (
        <Fragment>
            <Breadcrumbs mainTitle="Edit Facility" parent="Facility" title="Create Edit" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form onSubmit={onSubmit} initialValues={initialValues}>
                                    {({ handleSubmit }) => (
                                        <form className='needs-validation' noValidate='' onSubmit={handleSubmit}>
                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Field
                                                        name="dName"
                                                        validate={composeValidators(required, Name)}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="displayname"><strong>Facility Name *</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="displayname"
                                                                    type="text"
                                                                    placeholder="Enter facility name"
                                                                    onChange={(e) => {
                                                                        input.onChange(e);
                                                                        setName(e.target.value);
                                                                        setNameTouched(true);
                                                                    }}
                                                                />
                                                                {nameValidationMessage && <div className="text-danger">{nameValidationMessage}</div>}
                                                                {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>



                                                {/* 
                                                    <Col md='4 mb-3'>
                                                        <Field
                                                            name="uName"
                                                            validate={composeValidators(required, name)}
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>

                                                                    <Label className='form-label' for="displayname"><strong>User Name *</strong></Label>
                                                                    <input
                                                                        {...input}
                                                                        className="form-control"
                                                                        id="displayname"
                                                                        type="text"
                                                                        placeholder="Enter User name"
                                                                    />
                                                                    {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </Col> */}



                                                {/* <Col md='4 mb-3'>
                                                <Label className='form-label' for='validationCustom01'>
                                                    First name
                                                </Label>
                                                <input className='form-control' id='validationCustom01' type='text' placeholder='Mark' name='fName' {...register('fName', { required: true })} />
                                                <span className='d-block'>{errors.fName && 'First name is required'}</span>
                                                <div className='valid-feedback'>Looks good!</div>
                                            </Col> */}



                                                {/* <Col md="4 mb-3">
                                                        <Label for="type"><strong>Facility Type *</strong></Label>
                                                        <Select
                                                            options={uniqueTypes.map(type => ({ value: type, label: type }))}
                                                            className="js-example-basic-single col-sm-12"
                                                            value={selectedFacilityType}
                                                            onChange={(selectedOption) => setSelectedFacilityType(selectedOption)}
                                                            name="facilityType" // Add the name property if required
                                                        />
                                                        {selectedFacilityType === null && <span className='d-block text-danger'>Facility type is required</span>}
                                                    </Col> */}


                                                <Col md="4" className="mb-3">
                                                    <Label className='form-label' for="type"><strong>Facility Type *</strong></Label>
                                                    <Field
                                                        name="facilityType"
                                                        validate={required}
                                                    >
                                                        {({ input, meta }) => {
                                                            const showWorkshopOption = data.length > 0 && data[0].cs_type === "workshop";

                                                            return (
                                                                <div>
                                                                    <select
                                                                        {...input}
                                                                        className="form-control"
                                                                        id="selectmethod"
                                                                    // value={data.length > 0 ? data[0].cs_type : ''}
                                                                    >
                                                                        {showWorkshopOption ? (
                                                                            <>
                                                                                <option value="workshop">Workshop</option>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <option value="">Select type</option>
                                                                                {/* <option value="registration">Registration</option> */}
                                                                                <option value="food">Food</option>
                                                                                <option value="certificate">Certificate</option>
                                                                                <option value="gift">Gift</option>
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                    {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                    <div className='valid-feedback'>Looks good!</div>
                                                                </div>
                                                            );
                                                        }}
                                                    </Field>

                                                </Col>




                                            </Row>

                                            <Row>
                                                <Col md="4 mb-3">
                                                    <div>
                                                        <Label for="brightModeIcon"><strong>Bright Mode Icon *</strong></Label>
                                                        <Input type="file" name="brightmode" onChange={(event) => handleImageChange(event, 'brightModeIcon')} />
                                                        {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
                                                        {selectedImage && <p>Selected image: {selectedImage.name}</p>}
                                                        {data.length > 0 && <p>{data[0].cs_logo_image_url}</p>}


                                                    </div>
                                                </Col>






                                                <Col md="4 mb-3">
                                                    <div>
                                                        <Label for="darkModeIcon"><strong>Dark Mode Icon *</strong></Label>
                                                        <Input type="file" name="darkmode" onChange={(event) => handlePdfChange(event, 'darkModeIcon')} />
                                                        {pdfError && <p style={{ color: 'red' }}>{pdfError}</p>}
                                                        {selectedPdf && <p>Selected image: {selectedPdf.name}</p>}
                                                        {data.length > 0 && <p>{data[0].cs_logo_darkmode_image_url}</p>}



                                                    </div>
                                                </Col>

                                                {/* <Col md="4 mb-3">
                                                        <Label for="brightModeIcon"><strong>Bright Mode Icon *</strong></Label>
                                                        <Input
                                                            type="file"
                                                            name="brightmode"
                                                            onChange={(event) => onFilesChange(event, 'brightModeIcon')}
                                                            accept="image/*"
                                                        />
                                                        <Field name="brightmode" validate={required}>
                                                            {({ meta }) => (
                                                                <Fragment>
                                                                    {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                                    <div className='valid-feedback'>Looks good!</div>
                                                                </Fragment>
                                                            )}
                                                        </Field>
                                                    </Col>
                                                    <Col md="4 mb-3">
                                                        <Label for="darkModeIcon"><strong>Dark Mode Icon</strong></Label>
                                                        <Input
                                                            type="file"
                                                            name="darkmode"
                                                            {...register('darkmode', { required: true })}
                                                            onChange={(event) => onFilesChange(event, 'darkModeIcon')}
                                                            accept="image/*"
                                                        />
                                                        {errors.darkmode && <FormFeedback className='d-block text-danger'>Dark mode icon is required</FormFeedback>}
                                                        <div className='valid-feedback'>Looks good!</div>
                                                    </Col> */}

                                            </Row>

                                            {/* <Row>
                                                <Col md="4 mb-3">
                                                    <Label for="roleDescription"><strong>Daywise</strong></Label>
                                                    <Media body className="icon-state switch-sm">
                                                        <Label className="switch">
                                                            <Input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                                            <span className={"switch-state " + (isChecked ? "bg-success" : "bg-danger")}></span>
                                                        </Label>
                                                    </Media>
                                                    <Media>
                                                        {isChecked ? (
                                                            <Label className="text-success ml-3">Yes</Label>
                                                        ) : (
                                                            <Label className="text-danger ml-3">No</Label>
                                                        )}
                                                    </Media>
                                                </Col>
                                            </Row> */}
                                            <div>
                                                <Button color='primary' type='submit' className="me-2 mt-3">Edit Facility</Button>
                                                <Button color='warning' onClick={handleCancel} className="mt-3">Cancel</Button>
                                            </div>
                                        </form>
                                    )}

                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)} centered>
                <ModalHeader toggle={() => setModal(!modal)}>Confirm Cancel</ModalHeader>
                <ModalBody>
                    Your changes will be discarded. Are you sure you want to cancel?
                </ModalBody>
                <ModalFooter>
                    <Link to="/manage-facility/Consoft" className="btn btn-warning">Yes</Link>
                    <Button color="primary" onClick={() => setModal(!modal)}>No</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default EditFacility;
