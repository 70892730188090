import React, { useState, useEffect, Fragment, useContext } from 'react';
import axios from 'axios';
import { Breadcrumbs } from '../../AbstractElements';
import { BackendAPI } from '../../api';
import {
    Row, Col, Card, Input, Label, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { FaAngleDoubleLeft, FaEdit, FaAngleDoubleRight, FaLock, FaUnlock, FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Draggable from 'react-draggable';
import SweetAlert from 'sweetalert2';
import { Tooltip } from 'react-tooltip';
import { ToastContainer, toast } from "react-toastify";
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import debounce from 'lodash.debounce';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import { required, email, Name, Img, PDF } from '../Utils/validationUtils';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';
// Define the required validator
const requiredValidator = value => (value ? undefined : 'This field is required');

// Utility function to combine multiple validation functions
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const Fields = () => {
    useAuth();
    const [data, setData] = useState([]);
    const navigate = useNavigate(); // Initialize useHistory
    const [basicFields, setBasicFields] = useState([]);
    const [formFields, setFormFields] = useState([]);
    const [typeData, setTypeData] = useState([]);
    const [originalOrder, setOriginalOrder] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [removeModalOpen, setremoveModalOpen] = useState(false);
    const [alertModalOpen, setalertModalOpen] = useState(false);
    const [editedLabel, setEditedLabel] = useState('');
    const [addUserVisible, setAddUserVisible] = useState(true);
    const [required, setRequired] = useState(true);
    const [addSpotVisible, setAddSpotVisible] = useState(true);
    const [activeTab, setActiveTab] = useState('basic_field');
    const [createCustomModalOpen, setCreateCustomModalOpen] = useState(false);
    const [isLocked, setIsLocked] = useState(() => {
        // Initialize the locked state from localStorage or default to false
        const storedLockState = localStorage.getItem('isLocked');
        return storedLockState ? JSON.parse(storedLockState) : false;
    });
    const [optionsName, setoptionsName] = useState('');
    const [options, setoptions] = useState([]);
    const [selectedFieldType, setSelectedFieldType] = useState(null);
    const { permissions } = useContext(PermissionsContext);
    const [name, setName] = useState('');
    const [nameValidationMessage, setNameValidationMessage] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    // console.log(basicFields);
    console.log(formFields);



    const handleChange = (e) => {
        const newValue = e.target.value;
        setoptionsName(newValue);
        console.log("optionsName:", newValue);
    };

    const handleAddOptions = () => {
        if (optionsName.trim() !== '') {
            setoptions([...options, optionsName]);
            setoptionsName('');
        }
    };

    const handleEditOptions = (index, newName) => {
        const updatedoptions = [...options];
        updatedoptions[index] = newName;
        setoptions(updatedoptions);
    };

    const handleRemoveOptions = (index) => {
        const updatedoptions = [...options];
        updatedoptions.splice(index, 1);
        setoptions(updatedoptions);
    };

    useEffect(() => {
        // Update localStorage when isLocked state changes
        localStorage.setItem('isLocked', JSON.stringify(isLocked));
    }, [isLocked, permissions]);


    // Extract Fields component
    const Fieldspermissions = permissions['Fields'];
    // console.log('categoryPermissions', categoryPermissions);


    useEffect(() => {
        fetchFields();
        fetchFormFields();
        fetchTypes();
    }, []);

    const fetchFields = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/field/getFields`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            setData(response.data.Fields);

            const fieldData = response.data.Fields.map(item => ({
                id: item.cs_field_id,
                label: item.cs_field_label
            }));

            const fieldLabels = fieldData.map(item => item.label);
            setBasicFields(fieldData);
            setOriginalOrder(fieldLabels.slice());
        } catch (error) {
            console.error('Error fetching fields:', error);
        }
    };

    const fetchFormFields = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/field/getFormfield`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const fieldData = response.data.Fields.map(item => ({
                id: item.cs_field_id,
                label: item.cs_field_label,
                required: item.cs_is_required,
                onspot: item.cs_visible_onspot
            }));
            setFormFields(fieldData);
        } catch (error) {
            console.error('Error fetching form fields:', error);
        }
    };

    const fetchTypes = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/field/getTypes`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const typeData = response.data.Types.map(item => ({
                id: item.cs_field_type,
                type: item.field_type_name
            }));
            setTypeData(typeData);
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };



    const handleMoveItem = async (item, value) => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/updateFlag`, { item: item.label, val: value }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            fetchFields();
            fetchFormFields();
        } catch (error) {
            console.error('Error updating flag:', error);
        }
    };

    const handleRemoveItem = async (item, value) => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/updateFlag`, { item: item.label, val: value }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            fetchFields();
            fetchFormFields();
        } catch (error) {
            console.error('Error updating flag:', error);
        }
    };

    const handleUpItem = async (item) => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/moveup`, { item: item.id }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            // Check the response status and show appropriate toast message
            if (response.status === 200) {
                toast('Field position reordered successfully!');
            } else if (response.status === 202 && response.data.error) {
                toast('Already at the top position !'); // Show the error message from the server
            } else {
                toast('Unexpected response from the server.');
            }

            // Fetch updated fields after moving up
            fetchFormFields();
        } catch (error) {
            // Show a generic error message in case of failure
            toast('Already at the top position !');
        }
    };


    const handleDownItem = async (item) => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/movedown`, { item: item.id }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            // Check the response status and show appropriate toast message
            if (response.status === 200) {
                toast('Field position reordered successfully!');
            } else if (response.status === 202 && response.data.error) {
                toast('Already at the bottom position !'); // Show the error message from the server
            } else {
                toast('Unexpected response from the server.');
            }

            // Fetch updated fields after moving up
            fetchFormFields();
        } catch (error) {
            console.error('Error updating flag:', error);
        }
    };

    const handleEditLabel = (item) => {
        // console.log(item);
        setSelectedItem(item);
        setEditedLabel(item.label);
        // setRequired(item.required);
        // setAddSpotVisible(item.onspot);
        setModalOpen(true);
    };

    const handleDeleteCustomField = (item) => {
        setSelectedItem(item);
        setremoveModalOpen(true);
    };

    const closeRemoveModal = () => {
        setremoveModalOpen(!removeModalOpen);
    };



    const removeField = async () => {
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/deletefield`, {
                id: selectedItem.id,
                custom_label: selectedItem.label
            }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            if (response.status === 200) {
                setCreateCustomModalOpen(false);

                // Show SweetAlert upon successful response
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Cutom Field removed successfully!',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false
                }).then(() => {
                    fetchFields();
                    fetchFormFields();
                    // After the alert is closed, navigate to the desired location
                    // navigate("/Fields/Consoft");
                });
            } else {
                console.error('Error:', response.status); // Log the error status if not 200
            }

        } catch (error) {
            console.error('Error updating field:', error);
        }
        setremoveModalOpen(false);
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const updateLabel = async () => {
        try {
            const token = getToken();
            const response = await axios.post(
                `${BackendAPI}/field/editField`,
                {
                    id: selectedItem.id,
                    label: editedLabel,
                    required: required ? "1" : "0",
                    spotUser: addSpotVisible ? "1" : "0"
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                }
            );
            fetchFields();
            fetchFormFields();
        } catch (error) {
            console.error('Error updating field:', error);
        }
        setModalOpen(false);
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleCreateCustomModal = () => {
        setCreateCustomModalOpen(!createCustomModalOpen);

    };

    const Lock_Unlock = () => {
        setalertModalOpen(!alertModalOpen);
    };


    const closealertModal = () => {
        setalertModalOpen(!alertModalOpen);
        setoptions(null);
        setoptionsName(null);
    };



    const toggleLock = () => {
        setIsLocked(!isLocked);
        setalertModalOpen(false);

    };

    const handleSubmit = async (formValues) => {
        // Extract the value of FieldType from formValues.fieldType
        const FieldType = formValues.fieldType.value;

        // Append FieldType to formValues
        const updatedFormValues = {
            ...formValues,
            FieldType: FieldType,
            options: options
        };

        console.log('Form values:', updatedFormValues); // Log updated form values

        // Add your Axios request to send form data to the backend
        try {
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/CreateCustomField`, updatedFormValues,  {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            // Check if the response status is 200 (OK)
            if (response.status === 200) {
                setCreateCustomModalOpen(false);

                // Show SweetAlert upon successful response
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Cutom Field Created successfully!',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false
                }).then(() => {
                    fetchFields();
                    fetchFormFields();
                    // After the alert is closed, navigate to the desired location
                    // navigate("/Fields/Consoft");
                });
            } else {
                console.error('Error:', response.status); // Log the error status if not 200
            }
        } catch (error) {
            console.error('Error sending data to backend:', error);
        }
    };

    const isItemInFormFields = (item) => {
        return formFields.some((formItem) => formItem.id === item.id);
    };

    const validatename = debounce(async (value) => {
        try {

            // if (value === roleName) {
            //     // Skip validation if the name is the same as the current name
            //     setNameValidationMessage('');
            //     return;
            // }

            // Call the API to check name availability
            const token = getToken();
            const response = await axios.post(`${BackendAPI}/field/check-field-name`, { customLabel: value }, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            console.log('Server response:', response);
            if (!response.data.available) {
                setNameValidationMessage('Field already exists');
            } else {
                // Only set the validation message if the name is valid
                setNameValidationMessage('');
            }
        } catch (error) {
            console.error('Error checking username availability:', error);
            setNameValidationMessage('Error checking name availability');
        }
    }, 500); // Debounce time: 500ms

    useEffect(() => {
        if (nameTouched) { // Only validate name if it has been touched
            validatename(name);
        }
        return () => {
            validatename.cancel();
        };
    }, [name, nameTouched]);



    return (
        <Fragment>
            <Breadcrumbs mainTitle="Manage Fields" parent="Onsite App" title="Manage Fields" />
            <Row>
                <Col md={7}>
                    <Card>
                        <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                            <h2>Form View</h2>
                            {Fieldspermissions?.validate === 1 && (
                                <Button color={isLocked ? "danger" : "success"} onClick={Lock_Unlock}>
                                    {isLocked ? <><FaLock /> Lock</> : <><FaUnlock /> Unlock</>}
                                </Button>
                            )}
                        </CardHeader>
                        <CardBody>
                            <div className={`drop-box ${isLocked ? 'locked' : ''}`}>
                                {formFields.map((item, index) => (
                                    <Card className="field-card" color="primary" outline style={{ padding: '15px' }}>
                                        <CardBody style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ flex: 1 }}>{item.label}</div>
                                            {Fieldspermissions?.edit === 1 && (
                                                <div className="button-group">
                                                    {item.label !== "First Name" && item.label !== "Last Name" && item.label !== "Registration Category" && (
                                                        <>
                                                            <Button
                                                                color="primary"
                                                                size="sm"
                                                                onClick={() => handleEditLabel(item)}
                                                                disabled={isLocked}
                                                                data-tooltip-id="tooltip"
                                                                data-tooltip-content="Edit Field"
                                                                data-tooltip-event="click focus"
                                                            >
                                                                <FaEdit />
                                                            </Button>
                                                            <Button
                                                                color="warning"
                                                                size="sm"
                                                                onClick={() => handleRemoveItem(item, 0)}
                                                                disabled={isLocked}
                                                                data-tooltip-id="tooltip"
                                                                data-tooltip-content="Click to remove"
                                                                data-tooltip-event="click focus"
                                                            >
                                                                <FaAngleDoubleRight />
                                                            </Button>
                                                        </>
                                                    )}

                                                    <Button
                                                        color="warning"
                                                        size="sm"
                                                        onClick={() => handleUpItem(item)}
                                                        disabled={isLocked}
                                                        data-tooltip-id="tooltip"
                                                        data-tooltip-content="Move Up"
                                                        data-tooltip-event="click focus"
                                                    >
                                                        <FaAngleDoubleUp />
                                                    </Button>
                                                    <Button
                                                        color="warning"
                                                        size="sm"
                                                        onClick={() => handleDownItem(item)}
                                                        disabled={isLocked}
                                                        data-tooltip-id="tooltip"
                                                        data-tooltip-content="Move Down"
                                                        data-tooltip-event="click focus"
                                                    >
                                                        <FaAngleDoubleDown />
                                                    </Button>
                                                </div>
                                            )}
                                        </CardBody>
                                    </Card>
                                ))}
                            </div>
                        </CardBody>
                    </Card>

                </Col>

                {Fieldspermissions?.add === 1 && (
                    <Col md={5}>
                        <Card>
                            <CardHeader>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink className={activeTab === 'basic_field' ? 'active' : ''} onClick={() => toggleTab('basic_field')} disabled={isLocked}>
                                            Basic Fields
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTab === 'custom_field' ? 'active' : ''} onClick={() => toggleTab('custom_field')} disabled={isLocked}>
                                            Custom Fields
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="basic_field">
                                        <div className="fetched-data-list">
                                            {basicFields.slice(0, 14).map((item, index) => (
                                                <Card key={index} className={`field-card ${isLocked ? 'locked' : ''}`}>
                                                    <CardBody style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ flex: 1 }}>{item.label}</div>
                                                        {item.label !== "First Name" && item.label !== "Last Name" && item.label !== "Registration Category" && (
                                                            <div className="button-group">
                                                                <Tooltip id="tooltip" globalEventOff="click" />
                                                                <Button
                                                                    color="warning"
                                                                    size="sm"
                                                                    onClick={() => handleMoveItem(item, 1)}
                                                                    disabled={isLocked || isItemInFormFields(item)}
                                                                    data-tooltip-id="tooltip"
                                                                    data-tooltip-content="Click to form"
                                                                    data-tooltip-event="click focus"
                                                                >
                                                                    <FaAngleDoubleLeft />
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="custom_field">
                                        <div className="mt-2 md-2">
                                            <Button color="warning" onClick={toggleCreateCustomModal} disabled={isLocked}>Create Custom Fields</Button>
                                        </div>
                                        <div className="fetched-data-list">
                                            {basicFields.slice(14).map((item, index) => (
                                                <Card key={index} className={`field-card ${isLocked ? 'locked' : ''}`}>
                                                    <CardBody style={{ padding: '15px', display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ flex: 1 }}>{item.label}</div>
                                                        <div className="button-group">
                                                            <Tooltip id="tooltip" globalEventOff="click" />
                                                            <Button
                                                                color="warning" size="sm"
                                                                onClick={() => handleMoveItem(item, 1)}
                                                                disabled={isLocked}
                                                                data-tooltip-id="tooltip"
                                                                data-tooltip-content="Move in form"
                                                                data-tooltip-event="click focus"
                                                            >
                                                                <FaAngleDoubleLeft />
                                                            </Button>
                                                            <Button
                                                                color="danger" size="sm"
                                                                onClick={() => handleDeleteCustomField(item, 0)}
                                                                disabled={isLocked}
                                                                data-tooltip-id="tooltip"
                                                                data-tooltip-content="Delete Field"
                                                                data-tooltip-event="click focus"
                                                            >
                                                                <MdDelete />
                                                            </Button>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
            {/* Modal for Editing fields */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Edit Field</ModalHeader>
                <ModalBody>
                    <div style={{ marginBottom: '20px' }}>
                        <label htmlFor="label">Field Label</label>
                        <input id="label" type="text" value={editedLabel} onChange={(e) => setEditedLabel(e.target.value)} className="form-control" />
                    </div>
                    <div className="form">
                        <input id="adduser" type="checkbox"  onChange={(e) => setRequired(e.target.checked)} checked={required}  />
                        <label className='form-check-label' style={{ marginLeft: '10px' }} htmlFor="adduser"><strong>Field is required</strong></label>
                    </div>
                    <div className="form">
                        <input id="spot" type="checkbox"  onChange={(e) => setAddSpotVisible(e.target.checked)} checked={addSpotVisible}  />
                        <label className='form-check-label' style={{ marginLeft: '10px' }} htmlFor="spot"><strong>Visible in spot registration form</strong></label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={updateLabel}>Update</Button>
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* Modal for creating custom fields */}
            <Modal isOpen={createCustomModalOpen} toggle={toggleCreateCustomModal}>
                <ModalHeader toggle={toggleCreateCustomModal}>Create Custom Fields</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Label className='form-label' htmlFor="fieldType"><strong>Select Field Type:</strong></Label>
                                <Field name="fieldType" validate={requiredValidator} style={{ marginBottom: '20px' }}>
                                    {({ input, meta }) => (
                                        <div>
                                            <Select
                                                {...input}
                                                options={typeData.map(pref => ({ value: pref.id, label: pref.type }))}
                                                placeholder={`Select Field type`}
                                                isSearchable={true}
                                                onChange={(value) => {
                                                    input.onChange(value);
                                                    setSelectedFieldType(value);
                                                }}
                                                onBlur={input.onBlur}
                                                className="react-select"
                                            />
                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                            <div className='valid-feedback'>Looks good!</div>
                                        </div>
                                    )}
                                </Field>

                                <div style={{ marginBottom: '20px' }}>
                                    <Label className='form-label' htmlFor="customLabel"><strong>Field Name</strong></Label>
                                    {/* <Field name="customLabel" component="input" className="form-control" validate={requiredValidator} /> */}
                                    <Field
                                        name="customLabel"
                                        validate={composeValidators(Name)}
                                    >
                                        {({ input, meta }) => (
                                            <>
                                                <input
                                                    {...input}
                                                    className="form-control"
                                                    type="text" 
                                                    id="customLabel"
                                                    placeholder="Enter field name"
                                                    onChange={(e) => {
                                                        input.onChange(e); // Trigger onChange of the Field component
                                                        setName(e.target.value); // Update userName state
                                                        setNameTouched(true);
                                                    }}
                                                />
                                                {nameValidationMessage && <div className="text-danger">{nameValidationMessage}</div>}

                                                {meta.error && meta.touched && <span className='text-danger'>{meta.error}</span>}
                                            </>
                                        )}
                                    </Field>
                                </div>


                                {selectedFieldType && selectedFieldType.label === 'Dropdown' && (
                                    <div>
                                        <Col md="4" className="mb-3">
                                            <Label className='form-label' for="optionsName"><strong>Create Options *</strong></Label>
                                            <div className="d-flex">
                                                <input
                                                    className="form-control"
                                                    id="optionsName"
                                                    type="text"
                                                    value={optionsName}
                                                    onChange={handleChange}
                                                />
                                                <Button color='primary' onClick={handleAddOptions} className='ml-2'>Add</Button>
                                            </div>
                                        </Col>
                                        <Col md="4" className="mb-3">
                                            <ul className="list-group">
                                                {options.map((option, index) => (
                                                    <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                                                        <Input
                                                            type="text"
                                                            value={option}
                                                            onChange={(e) => handleEditOptions(index, e.target.value)}
                                                        />
                                                        <div>
                                                            <Button color="danger" size="sm" onClick={() => handleRemoveOptions(index)}><MdDelete /></Button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Col>
                                    </div>
                                )}


                                <ModalFooter>
                                    <Button color="primary" type="submit">Create</Button>
                                    <Button color="warning" onClick={toggleCreateCustomModal}>Cancel</Button>
                                </ModalFooter>
                            </form>
                        )}
                    </Form>
                </ModalBody>
            </Modal>
            {/* Modal for REMOVING custom fields */}
            <Modal isOpen={removeModalOpen} toggle={closeRemoveModal} centered size="md">
                <ModalHeader toggle={closeRemoveModal}>Confirmation</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete field?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={removeField}>Yes</Button>
                    <Button color="secondary" onClick={closeRemoveModal}>No</Button>
                </ModalFooter>
            </Modal>

            {/* Modal for Warning Lock and Unlock */}
            <Modal isOpen={alertModalOpen} toggle={closealertModal} centered size="md">
                <ModalHeader toggle={closealertModal}>Confirmation</ModalHeader>
                <ModalBody>
                    <div style={{ marginBottom: '20px' }}>
                        <p><strong>Alert: Impact on Mobile Application & Forms</strong></p>
                        <p>
                            Making changes to this form will affect all registration forms and the mobile application's functionality.
                            Any offline data in the app kindly upload to the server before proceeding. After changes are made, the
                            mobile app will require uninstallation and reinstallation.
                        </p>
                        <p>Are you sure you want to continue?</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleLock}>Yes</Button>
                    <Button color="secondary" onClick={closealertModal}>No</Button>
                </ModalFooter>
            </Modal>

        </Fragment>
    );
};

export default Fields;
