import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Label, Button, Card, CardBody, CardHeader, Modal, ModalHeader, FormFeedback, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { BackendAPI } from '../../api';
import SweetAlert from 'sweetalert2';
import { Breadcrumbs } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import TimezoneSelect from 'react-timezone-select';
import CustomizerContext from '../../_helper/Customizer';
import { Form, Field } from 'react-final-form';
import { required, email as validateEmail, name, expiryDate } from '../Utils/validationUtils';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import AppSetting from './App-setting';
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';
const AdminSetting = () => {
    useAuth();
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    const { permissions } = useContext(PermissionsContext);

    const [initialValues, setInitialValues] = useState({
        eventName: '',
        eventDays: '',
        timezone: '',
        email: '',
        mobile: '',
        from: '',
        to: '',
        cc: '',
        bcc: '',
        replyto: '',
        sms: '',
        spotregstart: '',
    });

    useEffect(() => {
        fetchSetting();
    }, [permissions]);

    // Extract AdminSettingPermissions component
    const AdminSettingPermissions = permissions['Admin Setting'];

    const fetchSetting = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/setting/getAdminSetting`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            if (response.data && response.data.setting) {
                const settings = response.data.setting;
                const eventNameSetting = settings.find(setting => setting.cs_parameter === "Event Name");
                const eventDaysSetting = settings.find(setting => setting.cs_parameter === "Event Days");
                const timezoneSetting = settings.find(setting => setting.cs_parameter === "Time Zone");
                const emailSetting = settings.find(setting => setting.cs_parameter === "AdminEmail");
                const mobileSetting = settings.find(setting => setting.cs_parameter === "mobile");
                const fromSetting = settings.find(setting => setting.cs_parameter === "From");
                const toSetting = settings.find(setting => setting.cs_parameter === "TO");
                const ccSetting = settings.find(setting => setting.cs_parameter === "CC");
                const bccSetting = settings.find(setting => setting.cs_parameter === "BCC");
                const replytoSetting = settings.find(setting => setting.cs_parameter === "Reply-To");
                const smsSetting = settings.find(setting => setting.cs_parameter === "SMS Sending");
                const spotregstartSetting = settings.find(setting => setting.cs_parameter === "Spot Registration Start");

                setInitialValues({
                    eventName: eventNameSetting ? eventNameSetting.cs_value : '',
                    eventDays: eventDaysSetting ? eventDaysSetting.cs_value : '',
                    timezone: timezoneSetting ? timezoneSetting.cs_value : '',
                    email: emailSetting ? emailSetting.cs_value : '',
                    mobile: mobileSetting ? mobileSetting.cs_value : '',
                    from: fromSetting ? fromSetting.cs_value : '',
                    to: toSetting ? toSetting.cs_value : '',
                    cc: ccSetting ? ccSetting.cs_value : '',
                    bcc: bccSetting ? bccSetting.cs_value : '',
                    replyto: replytoSetting ? replytoSetting.cs_value : '',
                    sms: smsSetting ? smsSetting.cs_value : '',
                    spotregstart: spotregstartSetting ? spotregstartSetting.cs_value : '',
                });

                setLoading(false);
            } else {
                console.error('Error: Invalid response format');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
            setLoading(false);
        }
    };

    const handleSubmit = async (values) => {
        // Create a FormData object to store form data including files
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
        });

        try {
            // Send formData to server
            const token = getToken(); 
            const response = await axios.post(`${BackendAPI}/setting/updateAdminSettings`, formData,{
                headers: {
                                Authorization: `Bearer ${token}` // Include the token in the Authorization header
                            }
                        });

            // Handle server response
            if (response.status === 200) {
                // Show success message to the user
                console.log('Settings updated successfully');
            } else {
                // Handle error condition
                console.error('Error updating settings:', response.data.message);
            }

            SweetAlert.fire({
                title: 'Success!',
                text: 'Changes Updated successfully !',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
                }
            });
        } catch (error) {
            // Handle network error or other exceptions
            console.error('Error updating settings:', error.message);
        }
    };

    const handleCancel = () => {
        const URL = '/dashboard/default/';
        // Pass data as state through route props
        navigate(`${process.env.PUBLIC_URL}${URL}${layoutURL}`);
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Super Admin Setting" parent="Setting" title="Super Admin Setting" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div className="mb-2 mb-md-0">
                                    <h5 className="mb-2 text-center">Admin Setting</h5>
                                </div>
                            </CardHeader>

                            <CardBody>
                                <Form
                                    onSubmit={handleSubmit}
                                    initialValues={initialValues}
                                    render={({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>

                                            <Row>
                                                <Col md="4" className="mb-3">
                                                    <Field name="eventName" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="eventname"><strong>Event Name *</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="eventname"
                                                                    type="text"
                                                                    placeholder="Enter event name"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Field name="eventDays" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="eventdays"><strong>Event Days *</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="eventdays"
                                                                    type="number"
                                                                    placeholder="Enter event day"
                                                                    min="1"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Field name="email" validate={validateEmail}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="email"><strong>Admin Email</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="email"
                                                                    type="text"
                                                                    placeholder="Enter email"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>

                                                <Col md="4" className="mb-3">
                                                    <Field name="from" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="from"><strong>From</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="from"
                                                                    type="text"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                // placeholder="Enter From"
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>

                                                {/* <Col md="4" className="mb-3">
                                                    <Field name="to" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="to"><strong>To</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="to"
                                                                    type="text"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                // placeholder="Enter To"
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col> */}
                                                <Col md="4" className="mb-3">
                                                    <Field name="cc" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="cc"><strong>CC</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="cc"
                                                                    type="text"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                // placeholder="Enter Cc"
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Field name="bcc" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="bcc"><strong>BCC</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="bcc"
                                                                    type="text"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                // placeholder="Enter Bcc"
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                                <Col md="4" className="mb-3">
                                                    <Field name="replyto" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for="replyto"><strong>Reply-To</strong></Label>
                                                                <input
                                                                    {...input}
                                                                    className="form-control"
                                                                    id="replyto"
                                                                    type="text"
                                                                    readOnly={AdminSettingPermissions?.validate === 0}
                                                                // placeholder="Enter Reply-To"
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                                <Col md='4 mb-3'>
                                                    <Field name="timezone" validate={required}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <Label className='form-label' for='timezone'><strong> Timezone * </strong></Label>
                                                                <TimezoneSelect
                                                                    value={input.value}
                                                                    onChange={timezone => input.onChange(timezone.value)}
                                                                    isDisabled={AdminSettingPermissions?.validate === 0}
                                                                />
                                                                {meta.error && meta.touched && <FormFeedback className='d-block text-danger'>{meta.error}</FormFeedback>}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>
                                            {AdminSettingPermissions?.validate === 1 && (
                                                <div>
                                                    <Button color='primary' type='submit' className="me-3 mt-3">Save</Button>
                                                    <Button color='warning' onClick={handleCancel} className="mt-3">Cancel</Button>
                                                </div>
                                            )}
                                        </form>
                                    )}
                                />
                            </CardBody>
                        </Card>
                        <AppSetting>

                        </AppSetting>
                    </Col>
                </Row>
            </Container>
            {/* Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)} centered>
                <ModalHeader toggle={() => setModal(!modal)}>Confirm Cancel</ModalHeader>
                <ModalBody>
                    Your changes will be discarded. Are you sure you want to cancel?
                </ModalBody>
                <ModalFooter>
                    <Link to="/manage-facility/Consoft" className="btn btn-warning">Yes</Link>
                    <Button color="primary" onClick={() => setModal(!modal)}>No</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default AdminSetting;
