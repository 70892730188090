import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Breadcrumbs } from "../../../src/AbstractElements";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { BackendAPI } from "../../api"; 
import Papa from "papaparse";
import classnames from "classnames"; // Import classnames
import { PermissionsContext } from '../../contexts/PermissionsContext';
import { getToken } from '../../Auth/Auth';
import useAuth from '../../Auth/protectedAuth';

const ImportUser = () => {
  useAuth();
  const [users, setUsers] = useState([]);
  const [catid, setCatid] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [CorrectData, setCorrectData] = useState([]);
  const [DuplicateData, setDuplicateData] = useState([]);
  const [Wrong, setWrongs] = useState([]);
  const [activeTab, setActiveTab] = useState("corrected");
  const navigate = useNavigate(); // Initialize useHistory
  const { permissions } = useContext(PermissionsContext);


  console.log("Cat data:", catid);
  console.log("Reg data:", users);
  console.log("File data:", fileData);
  console.log("Correct Entries:", CorrectData);
  console.log("Duplicate Entries:", DuplicateData);
  console.log("Wrong Entries:", Wrong);

  useEffect(() => {
    const fetchReg = async () => {
      setLoading(true);
      try {
        const token = getToken(); 
        const response = await axios.get(`${BackendAPI}/field/getRegno`, {
          headers: {
              Authorization: `Bearer ${token}` // Include the token in the Authorization header
          }
      });
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Error fetching users. Please try again later.");
        setLoading(false);
      }
    };

    fetchReg();
  }, [permissions]);

      // Extract With Regnumber component
      const WithRegnumberpermissions = permissions['With Regnumber'];
      // console.log('categoryPermissions', categoryPermissions);

  useEffect(() => {
    const fetchCatId = async () => {
      setLoading(true);
      try {
        const token = getToken(); 
        const response = await axios.get(`${BackendAPI}/field/getCatId`, {
          headers: {
              Authorization: `Bearer ${token}` // Include the token in the Authorization header
          }
      });
        setCatid(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Error fetching users. Please try again later.");
        setLoading(false);
      }
    };

    fetchCatId();
  }, []);

  const handleFileUpload = (files) => {
    const file = files[0];
    if (file.type !== 'text/csv') {
      SweetAlert.fire({
        title: 'Warning!',
        text: 'Select correct format',
        icon: 'warning',
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }
  
    Papa.parse(file, {
      complete: (result) => {
        // Access parsed data in result.data
        setFileData(result.data);
        const fileData = result.data;
  
        // Call compareData directly here
        const fileRegnos = fileData
          .filter((item) => item['Registration Number'] !== undefined)
          .map((item) => item['Registration Number']);
        const fileCatids = fileData
          .filter((item) => item['Registration Category ID'] !== undefined) // Filter out undefined values
          .map((item) => String(item['Registration Category ID']));
  
        const userRegnos = users.map((user) => String(user.cs_regno));
        const activeCatid = catid.map((Cat) => String(Cat.cs_reg_cat_id));
  
        const duplicates = userRegnos.filter((regno) => fileRegnos.includes(regno));
        const wrongs = fileCatids.filter(
          (catid) => !activeCatid.includes(catid)
        );
  
        // Filter out duplicates and wrong entries from fileData
        const correctEntries = fileData.filter(
          (item) =>
            item['Registration Number'] && // Check if cs_regno is not empty
            !duplicates.includes(item['Registration Number']) &&
            !wrongs.includes(item['Registration Category ID'])
        );
  
        setDuplicateData(duplicates);
        setWrongs(wrongs);
        setCorrectData(correctEntries);
  
        SweetAlert.fire({
          title: 'Success!',
          text: 'File Uploaded successfully !',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        });
      },
      header: true, // Assumes first row as header
    });
  };
  


  // const findDuplicates = (data) => {
  //   const duplicatesEntry = [];
  //   const seen = {};
  //   const headers = Object.keys(data[0]); // Assuming headers are in the first row
  
  //   // Find the index of the "Registration Number" column
  //   const registrationNumberIndex = headers.findIndex(header => header === "Registration Number");
  
  //   if (registrationNumberIndex !== -1) {
  //     data.forEach((entry, index) => {
  //       const value = entry[headers[registrationNumberIndex]];
  //       if (seen[value]) {
  //         duplicatesEntry.push(entry);
  //       } else {
  //         seen[value] = true;
  //       }
  //     });
  //   } else {
  //     console.error("Registration Number column not found.");
  //   }
  
  //   console.log("Check:", duplicatesEntry);
  //   setDuplicateData(duplicatesEntry);
  // };

  const handleImportCorrectedData = async () => {
    try {
        // Filter out wrong entries and duplicate entries
        const uniqueCorrectedData = CorrectData.filter(entry => {
            return entry && entry['Registration Number'] && entry['Registration Number'].trim() !== '';
        });

        console.log("Unique Corrected Data:", uniqueCorrectedData);

        const chunkSize = 500; // Define the size of each chunk
        const token = getToken();

        // Function to send data in chunks
        const sendChunk = async (chunk) => {
            try {
                await axios.post(`${BackendAPI}/manageuser/addBulkUser`, chunk, {
                    headers: {
                        Authorization: `Bearer ${token}` // Include the token in the Authorization header
                    }
                });
            } catch (error) {
                console.error('Error sending chunk:', error.response ? error.response.data : error.message);
                throw error; // Rethrow the error to handle it in the outer try-catch block
            }
        };

        // Split the data into chunks and send each chunk
        for (let i = 0; i < uniqueCorrectedData.length; i += chunkSize) {
            const chunk = uniqueCorrectedData.slice(i, i + chunkSize);
            await sendChunk(chunk); // Wait for the request to complete before sending the next one
        }

        SweetAlert.fire({
            title: 'Success!',
            text: 'User correct data imported successfully!',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        }).then((result) => {
            if (result.dismiss === SweetAlert.DismissReason.timer) {
                navigate("/User-listing/Consoft");
            }
        });
    } catch (error) {
        console.error('Error creating application login:', error.response ? error.response.data : error.message);
    }
};


  

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Import User" parent="Users" title="Import User" />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
            <CardHeader className="d-flex justify-content-between align-items-center flex-column flex-md-row">
      <div className="mb-2 mb-md-0">
        <h5 className="mb-2 text-start">Import with Registration Number</h5>
      </div>
      <div className=" mt-md-0">
        <Button color='warning' type='button' className="mr-2 mb-2" onClick={handleImportCorrectedData}>
          Import Data
        </Button>
      </div>
      <div className=" mt-md-0">
        <Input
          type="file"
          accept=".csv"
          onChange={(e) => handleFileUpload(e.target.files)}
          style={{ display: 'none' }}
          id="fileInput"
        />
        <label htmlFor="fileInput" className="btn btn-primary mr-2">Select File</label>
      </div>
      <div className="mb-2 mt-md-0">
        <a href="/Sample.csv" className="btn btn-success " download="sample.csv">
          Sample File
        </a>
      </div>
    </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "corrected",
                      })}
                      onClick={() => setActiveTab("corrected")}
                    >
                      Corrected Entries
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "duplicate",
                      })}
                      onClick={() => setActiveTab("duplicate")}
                    >
                      Duplicate Entries
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "wrong" })}
                      onClick={() => setActiveTab("wrong")}
                    >
                      Wrong Entries
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                <TabPane tabId="corrected">
  <Row>
  <div className="mt-3">
          {CorrectData && CorrectData.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              {Object.keys(CorrectData[0]).map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {CorrectData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(rowData).map((value, colIndex) => (
                  <td key={colIndex}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No correct entries found.</p>
      )}
</div>
  </Row>
</TabPane>

<TabPane tabId="duplicate">
  <Row>
  <div className="mt-3">      
  {fileData && fileData.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              {Object.keys(fileData[0]).map((key, index) => (
                <th key={index}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {DuplicateData.map((regno, index) => {
              const duplicateRow = fileData.find(
                (row) => row['Registration Number'] === regno
              );
              if (duplicateRow) {
                return (
                  <tr key={index}>
                    {Object.values(duplicateRow).map((value, colIndex) => (
                      <td key={colIndex}>{value}</td>
                    ))}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      ) : (
        <p>No duplicate entries found.</p>
      )}
</div>
  </Row>
</TabPane>


  <TabPane tabId="wrong">
  <Row>
  <div className="mt-3">
      {fileData && fileData.length > 0 ? ( // Check if fileData is not empty
        <table className="table">
          <thead>
            <tr>
              {Object.keys(fileData[0]).map((key, index) => (
                <th key={index}>{key}</th> // Generate table headers dynamically
              ))}
            </tr>
          </thead>
          <tbody>
            {fileData
              .filter((row) =>
                Wrong.includes(row['Registration Category ID'])
              ) // Filter rows based on wrong entries
              .map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(rowData).map(
                    (value, colIndex) => (
                      <td key={colIndex}>{value}</td> // Generate table data cells
                    )
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p>No wrong entries found.</p>
      )}
    </div>
  </Row>
</TabPane>

                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ImportUser;



// const Import = () => {
//   const [users, setUsers] = useState([]);
//   const [catid, setCatid] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [uniqueData, setuniqueData] = useState(null);
//   const [CorrectData, setCorrectData] = useState([]);
//   const [DuplicateData, setDuplicateData] = useState([]);
//   const [Wrong, setWrongs] = useState([]);
//   const [activeTab, setActiveTab] = useState("corrected");

//   console.log("File data:", uniqueData);
//   console.log("Correct Entries:", CorrectData);
//   console.log("Duplicate Entries:", DuplicateData);
//   console.log("Wrong Entries:", Wrong);

//   useEffect(() => {
//     const fetchReg = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(`${Backend}/fields/getRegno`);
//         setUsers(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setError("Error fetching users. Please try again later.");
//         setLoading(false);
//       }
//     };

//     fetchReg();
//   }, []);

//   useEffect(() => {
//     const fetchCatId = async () => {
//       setLoading(true);
//       try {
//         const response = await axios.get(`${Backend}/fields/getCatId`);
//         setCatid(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching users:", error);
//         setError("Error fetching users. Please try again later.");
//         setLoading(false);
//       }
//     };

//     fetchCatId();
//   }, []);

//   const handleFileUpload = (files) => {
//     const file = files[0];
//     Papa.parse(file, {
//       complete: (result) => {
//         // Access parsed data in result.data
//         // setuniqueData(result.data);
//         // const uniqueData = result.data;
//         // console.log(uniqueData);
//         const rawData = result.data;
      
//         // Remove duplicates based on cs_regno
//         const uniqueData = rawData.reduce((acc, current) => {
//           const isDuplicate = acc.some(item => item.cs_regno === current.cs_regno);
//           if (!isDuplicate) {
//             acc.push(current);
//           }
//           return acc;
//         }, []);
  
//         setuniqueData(uniqueData);
//         console.log(uniqueData);

//         // Call compareData directly here
//         const fileRegnos = uniqueData
//         .filter((item) => item.cs_regno !== undefined)
//         .map((item) => item.cs_regno);
      
//         const fileCatids = uniqueData
//           .filter((item) => item.cs_reg_cat_id !== undefined) // Filter out undefined values
//           .map((item) => String(item.cs_reg_cat_id));

//         const userRegnos = users.map((user) => String(user.cs_regno));
//         const activeCatid = catid.map((Cat) => String(Cat.cs_reg_cat_id));

//         const duplicates = userRegnos.filter((regno) => fileRegnos.includes(regno));
        
//         const wrongs = fileCatids.filter(
//           (catid) => !activeCatid.includes(catid)
//         );

//         // Filter out duplicates and wrong entries from uniqueData
//         const correctEntries = uniqueData.filter(
//           (item) =>
//             item.cs_regno && // Check if cs_regno is not empty
//             !duplicates.includes(item.cs_regno) &&
//             !wrongs.includes(item.cs_reg_cat_id)
//         );

//         console.log("CorrectEntries:",correctEntries);


//         setDuplicateData(duplicates);
//         setWrongs(wrongs);
//         setCorrectData(correctEntries);
//       },
//       header: true, // Assumes first row as header
//     });
//   };

//   return (
//     <Fragment>
//       <Breadcrumbs mainTitle="Import User" parent="Users" title="Import User" />
//       <Container fluid>
//         <Row>
//           <Col sm={12}>
//             <Card>
//               <CardHeader>
//                 <strong>Import User</strong>
//                 <div className="text-end">
//                   <Button color="primary">
//                     <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
//                       Select File
//                     </label>
//                     <input
//                       id="file-upload"
//                       type="file"
//                       accept=".csv"
//                       style={{ display: "none" }}
//                       onChange={(e) => handleFileUpload(e.target.files)}
//                     />
//                   </Button>
//                 </div>
//               </CardHeader>
//               <CardBody>
//                 <Nav tabs>
//                   <NavItem>
//                     <NavLink
//                       className={classnames({
//                         active: activeTab === "corrected",
//                       })}
//                       onClick={() => setActiveTab("corrected")}
//                     >
//                       Corrected Entries
//                     </NavLink>
//                   </NavItem>
//                   <NavItem>
//                     <NavLink
//                       className={classnames({
//                         active: activeTab === "duplicate",
//                       })}
//                       onClick={() => setActiveTab("duplicate")}
//                     >
//                       Duplicate Entries
//                     </NavLink>
//                   </NavItem>
//                   <NavItem>
//                     <NavLink
//                       className={classnames({ active: activeTab === "wrong" })}
//                       onClick={() => setActiveTab("wrong")}
//                     >
//                       Wrong Entries
//                     </NavLink>
//                   </NavItem>
//                 </Nav>
//                 <TabContent activeTab={activeTab}>
//                   <TabPane tabId="corrected">
//                     <Row>
//                       <Col sm="12">
//                         {uniqueData && uniqueData.length > 0 && (
//                           <table className="table">
//                             <thead>
//                               <tr>
//                                 {Object.keys(uniqueData[0]).map((key, index) => (
//                                   <th key={index}>{key}</th>
//                                 ))}
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {uniqueData
//                                 .filter(
//                                   (row) =>
//                                     row.cs_regno && // Exclude rows with empty cs_regno
//                                     !DuplicateData.includes(row.cs_regno) &&
//                                     !Wrong.includes(row.cs_reg_cat_id)
//                                 )
//                                 .map((rowData, rowIndex) => (
//                                   <tr key={rowIndex}>
//                                     {Object.values(rowData).map(
//                                       (value, colIndex) => (
//                                         <td key={colIndex}>{value}</td>
//                                       )
//                                     )}
//                                   </tr>
//                                 ))}
//                             </tbody>
//                           </table>
//                         )}
//                       </Col>
//                     </Row>
//                   </TabPane>

//                   <TabPane tabId="duplicate">
//                     <Row>
//                       <Col sm="12">
//                         {DuplicateData.length > 0 && (
//                           <table className="table">
//                             <thead>
//                               <tr>
//                                 {Object.keys(uniqueData[0]).map((key, index) => (
//                                   <th key={index}>{key}</th>
//                                 ))}
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {DuplicateData.map((regno, index) => {
//                                 const duplicateRow = uniqueData.find(
//                                   (row) => row.cs_regno === regno
//                                 );
//                                 if (duplicateRow) {
//                                   return (
//                                     <tr key={index}>
//                                       {Object.values(duplicateRow).map(
//                                         (value, colIndex) => (
//                                           <td key={colIndex}>{value}</td>
//                                         )
//                                       )}
//                                     </tr>
//                                   );
//                                 }
//                                 return null;
//                               })}
//                             </tbody>
//                           </table>
//                         )}
//                       </Col>
//                     </Row>
//                   </TabPane>

//                   <TabPane tabId="wrong">
//                     <Row>
//                       <Col sm="12">
//                         {uniqueData &&
//                           uniqueData.length > 0 && ( // Check if uniqueData is not empty
//                             <table className="table">
//                               <thead>
//                                 <tr>
//                                   {Object.keys(uniqueData[0]).map(
//                                     (key, index) => (
//                                       <th key={index}>{key}</th> // Generate table headers dynamically
//                                     )
//                                   )}
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {uniqueData
//                                   .filter((row) =>
//                                     Wrong.includes(row.cs_reg_cat_id)
//                                   ) // Filter rows based on wrong entries
//                                   .map((rowData, rowIndex) => (
//                                     <tr key={rowIndex}>
//                                       {Object.values(rowData).map(
//                                         (value, colIndex) => (
//                                           <td key={colIndex}>{value}</td> // Generate table data cells
//                                         )
//                                       )}
//                                     </tr>
//                                   ))}
//                               </tbody>
//                             </table>
//                           )}
//                       </Col>
//                     </Row>
//                   </TabPane>
//                 </TabContent>
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </Fragment>
//   );
// };

// export default Import;



// router.get('/getRegno', (req, res, next) => {
//     db.getConnection((err, connection) => {
//         if (err) {
//             console.error('Error connecting to MySQL database:', err);
//             return next(err); // Pass the error to the error handling middleware
//         }
//         connection.query('SELECT cs_regno FROM cs_os_users', (err, results) => {
//             connection.release(); // Release the connection
//             if (err) {
//                 console.error('Error fetching users:', err);
//                 return next(err); // Pass the error to the error handling middleware
//             }
//             res.json(results);
//         });
//     });
//   });
  
//   router.get('/getCatId', (req, res, next) => {
//     db.getConnection((err, connection) => {
//         if (err) {
//             console.error('Error connecting to MySQL database:', err);
//             return next(err); // Pass the error to the error handling middleware
//         }
//         connection.query('SELECT cs_reg_cat_id FROM cs_os_category WHERE cs_status = 1', (err, results) => {
//             connection.release(); // Release the connection
//             if (err) {
//                 console.error('Error fetching users:', err);
//                 return next(err); // Pass the error to the error handling middleware
//             }
//             res.json(results);
//         });
//     });
//   });
  
