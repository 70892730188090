import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Input, Label, Button, Card, CardBody, Modal, ModalHeader, FormFeedback, ModalBody, ModalFooter, Media } from 'reactstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { BackendAPI } from '../../api';
import SweetAlert from 'sweetalert2';
// import { Input } from 'antd';
import { Breadcrumbs } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Field, Form } from 'react-final-form'; // Import Field and Form from react-final-form
import { required, email, name, Img, PDF, option } from '../Utils/validationUtils';
import { PermissionsContext } from '../../contexts/PermissionsContext';
import useAuth from '../../Auth/protectedAuth';
import { getToken } from '../../Auth/Auth';



//Utility function used to combine multiple validation functions into a single validation function
const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

const AddUser = () => {
    useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [fieldLabels, setFieldLabels] = useState([]);
    const [fieldType, setFieldType] = useState([]);
    const [requiredfield, setRequiredField] = useState([]); // Define requiredfield state
    const [fieldId, setFieldId] = useState([]);
    const [fieldName, setFieldName] = useState([]);
    const navigate = useNavigate(); // Initialize useHistory
    const [prefixes, setPrefixes] = useState([]);
    const [state, setState] = useState([]);
    const [country, setCountry] = useState([]);
    const [regCat, setRegCat] = useState([]);
    const [workshop, setWorkshop] = useState([]);
    const [dayType, setDayType] = useState([]);
    const [custom, setCustom] = useState([]);
    const { permissions } = useContext(PermissionsContext);


    useEffect(() => {
        fetchFields(); // Corrected function name
    }, [permissions]);

    useEffect(() => {
        fetchDropdown(); // Corrected function name
    }, []);

    // Extract Add User setting Permissions component
    const AddUserPermissions = permissions['Add User'];

    const fetchFields = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${BackendAPI}/manageuser/getField`, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });
            const fieldsData = response.data.Fields;
            const requiredfield = fieldsData.map(field => field.cs_is_required);
            const fieldLabels = fieldsData.map(field => field.cs_field_label);
            const fieldType = fieldsData.map(field => field.field_type_name);
            const fieldId = fieldsData.map(field => field.cs_field_id);
            const fieldName = fieldsData.map(field => field.cs_field_name);

            setData(fieldsData);
            setFieldLabels(fieldLabels);
            setFieldType(fieldType);
            setFieldName(fieldName);
            setRequiredField(requiredfield); // Set requiredfield state
            setFieldId(fieldId);
            setLoading(false);

            // console.log('Id:', fieldName);
        } catch (error) {
            console.error('Error fetching Fields:', error);
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setModal(true);
    };

    const onSubmit = async (formData) => {
        try {
            // Extract only the values from the formData object dynamically
            const values = {};
            for (const key in formData) {
                if (Object.hasOwnProperty.call(formData, key)) {
                    values[key] = formData[key].value || formData[key];
                }
            }

            console.log('Formatted form data to send:', values);
            const token = getToken();

            await axios.post(`${BackendAPI}/manageuser/addUser`, values, {
                headers: {
                    Authorization: `Bearer ${token}` // Include the token in the Authorization header
                }
            });

            SweetAlert.fire({
                title: 'Success!',
                text: 'User created successfully!',
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            }).then((result) => {
                if (result.dismiss === SweetAlert.DismissReason.timer) {
                    navigate("/User-listing/Consoft");
                }
            });
        } catch (error) {
            console.error('Error creating application login:', error.message);
        }
    };


    const fetchDropdown = async () => {
        try {
            const token = getToken(); 
            const response = await axios.get(`${BackendAPI}/manageuser/getDropdownData`,{
                headers: {
                                Authorization: `Bearer ${token}` // Include the token in the Authorization header
                            }
                        });
            setData(response.data);
            console.log(response.data);
            setLoading(false);

            const fetchprefixes = response.data.prefix;
            const fetchstate = response.data.states;
            const fetchcountry = response.data.country;
            const fetchregcat = response.data.regCategory;
            const fetchworkshop = response.data.workshop;
            const fetchdaytype = response.data.dayType;
            const fetchCutomData = response.data.custom;


            setPrefixes(fetchprefixes);
            setState(fetchstate);
            setCountry(fetchcountry);
            setRegCat(fetchregcat);
            setWorkshop(fetchworkshop);
            setDayType(fetchdaytype);
            setCustom(fetchCutomData);


            console.log(fetchprefixes);


        } catch (error) {
            console.error('Error fetching dropdown data:', error);
            setLoading(false);
        }
    };



    return (
        <Fragment>
            <Breadcrumbs mainTitle="Create User" parent="Manage User" title="Create User" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Form onSubmit={onSubmit}>
                                    {({ handleSubmit }) => (
                                        <form className='needs-validation' noValidate='' onSubmit={handleSubmit}>
                                            {/* Render multiple Field components based on the length of fieldLabels array */}
                                            {fieldLabels.map((label, index) => {
                                                const isFieldRequired = requiredfield[index] === 1;
                                                return (
                                                    <Row key={index}>
                                                        <Col md="4" className="mb-3">
                                                            {fieldType[index] === 'Dropdown' && fieldLabels[index] === 'Title' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <Select
                                                                                {...input}
                                                                                options={prefixes.map(pref => ({ value: pref.cs_prefix, label: pref.cs_prefix }))}
                                                                                placeholder={`Select ${label}`}
                                                                                isSearchable={true}
                                                                                onChange={(value) => input.onChange(value)}
                                                                                onBlur={input.onBlur}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Dropdown' && fieldLabels[index] === 'State' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <Select
                                                                                {...input}
                                                                                options={state.map(pref => ({ value: pref.cs_state_name, label: pref.cs_state_name }))}
                                                                                placeholder={`Select ${label}`}
                                                                                isSearchable={true}
                                                                                onChange={(value) => input.onChange(value)}
                                                                                onBlur={input.onBlur}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Dropdown' && fieldLabels[index] === 'Country' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <Select
                                                                                {...input}
                                                                                options={country.map(pref => ({ value: pref.cs_country, label: pref.cs_country }))}
                                                                                placeholder={`Select ${label}`}
                                                                                isSearchable={true}
                                                                                onChange={(value) => input.onChange(value)}
                                                                                onBlur={input.onBlur}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Dropdown' && fieldLabels[index] === 'Workshop Category' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <Select
                                                                                {...input}
                                                                                options={workshop.map(pref => ({ value: pref.cs_workshop_id, label: pref.cs_workshop_name }))}
                                                                                placeholder={`Select ${label}`}
                                                                                isSearchable={true}
                                                                                onChange={(value) => input.onChange(value)}
                                                                                onBlur={input.onBlur}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Dropdown' && fieldLabels[index] === 'Registration Category' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <Select
                                                                                {...input}
                                                                                options={regCat.map(pref => ({
                                                                                    value: pref.cs_reg_cat_id,
                                                                                    label: pref.cs_reg_category
                                                                                }))} placeholder={`Select ${label}`}
                                                                                isSearchable={true}
                                                                                onChange={(value) => input.onChange(value)}
                                                                                onBlur={input.onBlur}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Dropdown' && fieldLabels[index] === 'Registration Type' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <Select
                                                                                {...input}
                                                                                options={dayType.map(pref => ({ value: pref.cs_reg_daytype_id, label: pref.cs_reg_daytype_name }))}
                                                                                placeholder={`Select ${label}`}
                                                                                isSearchable={true}
                                                                                onChange={(value) => input.onChange(value)}
                                                                                onBlur={input.onBlur}
                                                                                classNamePrefix="react-select"
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Dropdown' && (fieldId[index] > 23) && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={requiredfield[index] === '1' ? composeValidators(option) : (value) => composeValidators()(value)}
                                                                >
                                                                    {({ input, meta }) => {
                                                                        // Filter fetchCustomData based on matching cs_field_id with fieldId
                                                                        const matchedOptions = custom.filter(option => option.cs_field_id === fieldId[index]);

                                                                        return (
                                                                            <div>
                                                                                <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                                <Select
                                                                                    {...input}
                                                                                    options={matchedOptions.map(option => ({ value: option.cs_field_option_value, label: option.cs_field_option }))}
                                                                                    placeholder={`Select ${label}`}
                                                                                    isSearchable={true}
                                                                                    onChange={(value) => input.onChange(value)}
                                                                                    onBlur={input.onBlur}
                                                                                    classNamePrefix="react-select"
                                                                                />
                                                                                {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Field>
                                                            )}






                                                            {fieldType[index] === 'Long Text' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={isFieldRequired ? composeValidators(required) : required}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <textarea
                                                                                {...input}
                                                                                className="form-control"
                                                                                id={`displayname${index}`}
                                                                                placeholder={`Enter ${label}`}
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Number' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={isFieldRequired ? composeValidators(required) : required}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <input
                                                                                {...input}
                                                                                className="form-control"
                                                                                id={`displayname${index}`}
                                                                                type="number"
                                                                                placeholder={`Enter ${label}`}
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}

                                                            {fieldType[index] === 'Text' && (
                                                                <Field
                                                                    name={`${fieldName[index]}`} // Use dynamic field name
                                                                    validate={isFieldRequired ? composeValidators(required) : required}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <Label className='form-label' for={`displayname${index}`}><strong>{label}</strong></Label>
                                                                            <input
                                                                                {...input}
                                                                                className="form-control"
                                                                                id={`displayname${index}`}
                                                                                type="text"
                                                                                placeholder={`Enter ${label}`}
                                                                            />
                                                                            {meta.error && meta.touched && <p className='d-block text-danger'>{meta.error}</p>}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                );
                                            })}

                                            {AddUserPermissions?.add === 1 && (
                                                <div>
                                                    <Button color='primary' type='submit' className="mr-2 mt-3">Create User</Button>
                                                    <Button color='warning' onClick={handleCancel} className="mt-3">Cancel</Button>
                                                </div>
                                            )}
                                        </form>
                                    )}
                                </Form>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Modal */}
            <Modal isOpen={modal} toggle={() => setModal(!modal)} centered>
                <ModalHeader toggle={() => setModal(!modal)}>Confirm Cancel</ModalHeader>
                <ModalBody>
                    Your changes will be discarded. Are you sure you want to cancel?
                </ModalBody>
                <ModalFooter>
                    <Link to="/manage-facility/Consoft" className="btn btn-warning">Yes</Link>
                    <Button color="primary" onClick={() => setModal(!modal)}>No</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default AddUser;