import React, { useState, useRef,  } from 'react';
import './BadgeElement.css';
// import Draggable from 'react-draggable';
import { DraggableCore } from 'react-draggable';
import { FaTimes } from 'react-icons/fa';
import QRCode from 'qrcode.react';


// import './BadgeDesigner.css';
const BadgeElement = ({ id, content,textContent, position, size, isSelected, onSelect, onSizeChange, onContentChange, onDelete, onPositionChange, badgeSize, textFontSize,type }) => {

  const [internalContent, setInternalContent] = useState(content);
  const [isEditable, setIsEditable] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const badgeRef = useRef(null);



//   const handleClick = (e) => {
//     e.stopPropagation(); // Prevent click event from propagating to parent elements
//     onSelect(isSelected ? null : id); // Toggle isSelected state
// };

  
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent click event from propagating to parent elements
    if (!isSelected) {
      onSelect(id); // Select if not selected
    }
  };
  
  const handleContentChange = (e) => {
    const newContent = e.target.value;
    setInternalContent(newContent);
    onContentChange(id, newContent);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  const handleRemove = () => {
    onDelete(id);
  };

  const handleDrag = (e, data) => {
    const newX = Math.max(Math.min(data.x, badgeSize.width - size.width), 0);
    const newY = Math.max(Math.min(data.y, badgeSize.height - size.height), 0);
    onPositionChange(id, { top: newY, left: newX });
  };


  // console.log('Position:', position);
  // console.log('Size:', size);
  // console.log('Badge Size:', badgeSize);

  return (
    <DraggableCore
      bounds="parent"
      defaultPosition={{ x: position.left, y: position.top }}
      onStart={() => onSelect(id)}
      onStop={(e, data) => {
        onSizeChange({ width: size.width, height: size.height });
      }}
      onDrag={handleDrag}
    >
      <div
        ref={badgeRef}
        id={id}
        className={`badge-element ${isSelected ? 'selected' : ''} ${isHovered ? 'hovered' : ''}`}
        style={{
          position: 'absolute',
          top: position.top,
          left: position.left,
          width: size.width,
          height: size.height,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        {/* Render different content based on type */}
        {type === 'text' ? (
          <div className="badge-element-content">
            {isEditable ? (
              <textarea
                className="badge-element-textarea editable"
                placeholder="Edit text here"
                value={internalContent}
                onChange={handleContentChange}
                onBlur={handleBlur}
                autoFocus
                style={{ fontSize: textFontSize || 12 }}
              />
            ) : (
              <div className="badge-element-text" style={{ fontSize: textFontSize ? `${textFontSize}px` : '12px' }}>
                {textContent || content}
              </div>
            )}
          </div>
        ) 
        : type === 'image' ? (
          <img src={content} alt="" style={{ width: '100%', height: '100%' }} />
        ) 
        : type === 'backgroundimage' ? (
          <img src={content} alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        ): type === 'qr' ? (
          <QRCode value={content} size={size.width} />
        ) : type === 'customtext' ? (
          <div className="badge-element-content">
          {isEditable ? (
            <textarea
              className="badge-element-textarea editable"
              placeholder="Edit text here"
              value={internalContent}
              onChange={handleContentChange}
              onBlur={handleBlur}
              autoFocus
              style={{ fontSize: textFontSize || 12 }}
            />
          ) : (
            <div className="badge-element-text" style={{ fontSize: textFontSize ? `${textFontSize}px` : '12px' }}>
              {textContent || content}
            </div>
          )}
        </div>
        ) : type === 'fullname' ? (
          <div className="badge-element-content">
          {isEditable ? (
            <textarea
              className="badge-element-textarea editable"
              placeholder="Edit text here"
              value={internalContent}
              onChange={handleContentChange}
              onBlur={handleBlur}
              autoFocus
              style={{ fontSize: textFontSize || 12 }}
            />
          ) : (
            <div className="badge-element-text" style={{ fontSize: textFontSize ? `${textFontSize}px` : '12px' }}>
              {textContent || content}
            </div>
          )}
        </div>
        ): null}
        {/* Render remove button when selected and hovered */}
        {(isSelected && isHovered) && (
          <div className="badge-element-remove" onClick={handleRemove}>
            <FaTimes />
          </div>
        )}
      </div>
    </DraggableCore>
  );
};

 


export default BadgeElement;

